import moment from "moment";
/**
 * tofixtwo().
 *
 * this function is used to do toFixed(2) after every calculation in order and order item calculation.
 *
 * @param {string or number} number Which number's toFixed(2) we have to do that number.
 *
 * @return {type} toFixed(2) value of requested value.
 */
export var twofix = (number) => {
  if (null !== number && "" !== number && !isNaN(number)) {
    return parseFloat(number).toFixed(2);
  }
};
export var twofixs = (number) => {
  if (null !== number && !isNaN(number)) {
    return "$" + parseFloat(number).toFixed(2);
  }
  return "$0.00";
};

export var reportDateFormate = (str) => {
  var date = new Date(str);
  var mnth = ("0" + (date.getMonth() + 1)).slice(-2);
  var day = ("0" + date.getDate()).slice(-2);
  var hours = ("0" + date.getHours()).slice(-2);
  var minutes = ("0" + date.getMinutes()).slice(-2);
  var sec = ("0" + date.getSeconds()).slice(-2);
  return (
    mnth +
    "/" +
    day +
    "/" +
    date.getFullYear() +
    " " +
    hours +
    ":" +
    minutes +
    ":" +
    sec
  );
};

export var startOfTodaysDay = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to 00:00:00:000
  return today;
};

export var endOfTodaysDay = () => {
  const today = new Date();
  today.setHours(23, 59, 59, 0); // Set time to 00:00:00:000
  return today;
};

export var commonDateFormate = (date) => {
  const today = moment(date).format("DD MMM, YYYY hh:mm A");
  return today;
};

export var onlyDateFormate = (date) => {
  const today = moment(date).format("DD MMM, YYYY");
  return today;
};
