import { useLocation, useNavigate } from "react-router-dom";
import { PosTostMessage } from "../../components/index";
import * as Constants from "../../constants/Constants";

const PosErrorHook = (props) => {
  const { addToast } = PosTostMessage();
  const navigate = useNavigate();
  const location = useLocation();

  const error = (data) => {
    if (
      undefined !== data?.actionData?.code &&
      null !== data?.actionData?.code &&
      Constants.NETWORK_ERROR == data?.actionData?.code
    ) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.TOAST_HEADING_SYSTEM_ERROR,
        alertDescription: Constants.SERVER_IS_NOT_REACHABLE,
      });
    } else if (
      undefined !== data?.actionData?.response?.data &&
      null !== data?.actionData?.response?.data &&
      typeof data?.actionData?.response?.data === "object"
    ) {
      let error = data?.actionData?.response?.data;
      if (error?.code === Constants.REQUEST_DENIED_CODE) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.TOKEN_EXPIRTED,
          alertDescription: error.message,
        });
        if (
          location.pathname.includes(
            "/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE
          )
        ) {
          localStorage.removeItem("master-token");
          localStorage.removeItem("ttl");
          navigate("/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE);
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("currentUser");
          navigate(Constants.SIGNIN_PATH);
        }
      } else if (error?.code === Constants.UNAUTHORIZE_CODE) {
        if (error.data.tokenExpired) {
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.TOKEN_EXPIRTED,
            alertDescription: error.message,
          });
          if (
            location.pathname.includes(
              "/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE
            )
          ) {
            localStorage.removeItem("master-token");
            localStorage.removeItem("ttl");
            navigate("/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE);
          } else {
            localStorage.removeItem("token");
            localStorage.removeItem("currentUser");
            navigate(Constants.SIGNIN_PATH);
          }
        } else if (
          location.pathname ==
          "/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE
        ) {
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.TOAST_TYPE_WARNING,
            alertDescription: error.message,
          });
        } else {
          navigate(Constants.UNAUTHORIZE_PAGE);
        }
      } else if (error?.code === Constants.FORBIDDEN_ERROR) {
        navigate(Constants.FORBIDDEN_ERROR_PAGE);
      } else if (error?.validationCheck) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.FIELD_IS_REQUIRED,
          alertDescription: error.message,
        });
      } else {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.TOAST_HEADING_WARNING,
          alertDescription: error.message,
        });
      }
    }
    // else if (
    //   undefined !== data?.actionData?.data &&
    //   null !== data?.actionData?.data &&
    //   typeof data?.actionData?.data === "object"
    // ) {
    //   if (data?.actionData?.data?.code === Constants.REQUEST_DENIED_CODE) {
    //     localStorage.removeItem("token");
    //     localStorage.removeItem("currentUser");
    //     addToast({
    //       alertStatus: Constants.TOAST_TYPE_WARNING,
    //       alertTitle: Constants.TOKEN_EXPIRTED,
    //       alertDescription: data?.actionData?.data.message,
    //     });
    //     navigate(Constants.SIGNIN_PATH);
    //   } else if (data?.actionData?.data?.code === Constants.UNAUTHORIZE_CODE) {
    //     if (data?.actionData?.data.tokenExpired) {
    //       localStorage.removeItem("token");
    //       localStorage.removeItem("currentUser");
    //       addToast({
    //         alertStatus: Constants.TOAST_TYPE_WARNING,
    //         alertTitle: Constants.TOKEN_EXPIRTED,
    //         alertDescription: data?.actionData?.data?.message,
    //       });
    //       navigate(Constants.SIGNIN_PATH);
    //     } else {
    //       navigate(Constants.UNAUTHORIZE_PAGE);
    //     }
    //   } else if (data?.actionData?.data?.code === Constants.FORBIDDEN_ERROR) {
    //     navigate(Constants.FORBIDDEN_ERROR_PAGE);
    //   } else if (data?.actionData?.data?.validationCheck) {
    //     addToast({
    //       alertStatus: Constants.TOAST_TYPE_WARNING,
    //       alertTitle: Constants.FIELD_IS_REQUIRED,
    //       alertDescription: data?.actionData?.data?.message,
    //     });
    //   } else {
    //     addToast({
    //       alertStatus: Constants.TOAST_TYPE_WARNING,
    //       alertTitle: Constants.TOAST_HEADING_WARNING,
    //       alertDescription: data?.actionData?.data?.message,
    //     });
    //   }
    // }
    else {
    }
  };
  return { error };
};

export default PosErrorHook;
