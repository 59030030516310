import {
  Box,
  ButtonGroup,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IconButton from "@mui/material/IconButton";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosTable,
  PosTostMessage,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { SimpleReportLayout } from "../../../../../layouts/index";
import { WithRouter } from "../../../../../navigators/WithRouter";

const EmployeesWorkTime = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [tableData, setTableData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [viewData, setViewData] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [zone, setZone] = useState("");
  const [emp, setEmp] = useState("");
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isExtraLarge] = useMediaQuery("(min-width: 1580px)");
  const [isLarge] = useMediaQuery("(min-width: 1435px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1267px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    // total_cash_discount: false,
  });
  const columnNames = [
    { field: "id", headerName: "#", sortable: false, width: 90 },
    {
      field: "date",
      headerName: "Date",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "totalcount",
      headerName: "Total Hours [H:M:S]",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "order",
      headerName: "View Details",
      sortable: false,
      filterable: false,
      resizable: false,
      ...actionColumnFlexDouble,
      disableExport: true,
      renderCell: (params) => (
        <>
          {params.row.time !== undefined && params.row.time.length > 0 ? (
            <IconButton
              style={{
                backgroundColor: "#F4F4F4",
                color: "#010048",
                transition: "background-color 0.3s, color 0.3s",
              }}
              onClick={() => {
                handleViewDetailsClick(params.row);
              }}
            >
              <RemoveRedEyeIcon />
            </IconButton>
          ) : params.row.time === undefined ? null : (
            <span>N.A.</span>
          )}
        </>
      ),
    },
  ];
  const handleViewDetailsClick = (data) => {
    const rowData = data.time.map((entry, index) => ({
      count: index + 1,
      clock_in: entry.clock_in_time,
      clock_out: entry.clock_out_time || "N.A",
      diff: entry.diff || "N.A",
    }));
    const staticRow = {
      count: "Total Duration", // You can set the count as needed
      clock_in: "--",
      clock_out: "--",
      diff: data.totalcount,
    };
    const modifiedRowData = [...rowData, staticRow];
    setViewData(modifiedRowData);
    setEmp(`${data.name}'s work report on ${data.date}`);
    onOpen();
  };
  const viewColumnNames = [
    {
      columnNames: "#",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Clock-In	",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Clock-Out	",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Duration",
      sortName: "",
      width: "",
    },
  ];
  const [loading, setLoading] = useState(true);
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);
  const sortRef = useRef(sortModel);
  const formik = useFormik({
    initialValues: {
      selectedLoactions: "",
      fromDate: "",
      toDate: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      // selectedLoactions: Yup.array().min(1, Constants.LOCATION_IS_REQUIRED),
      selectedLoactions: Yup.string().required(Constants.LOCATION_IS_REQUIRED),
      fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if (
            undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location &&
            loaderResponse?.data?.data?.location?.length === 1
          ) {
            setSelectedLocation(loaderResponse?.data?.data?.location[0]);
            formik.setFieldValue(
              "selectedLoactions",
              loaderResponse?.data?.data?.location[0].name
            );
          }
          setLocations(loaderResponse?.data?.data?.location);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    getEmployeesWorkTimeData();
  }, [paginationModel, sortModel]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Employee Work Time Report successfully generated."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            Constants.DOWNLOAD,
            Constants.EMP_WORK_TIME_EXCEL_FILENAME
          );
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setTableData(actionResponse.data.data.data);
          setZone(actionResponse.data.data.zone);
          setLoading(false);
          setButtonDisable(false);
          setTotalCount(1);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fromDateFocus, toDateFocus]);
  const handleExportReport = () => {
    if (
      null != formik.values.selectedLoactions &&
      "" != formik.values.selectedLoactions
    ) {
      if (null != formik.values.fromDate && "" != formik.values.fromDate) {
        if (null != formik.values.toDate && "" != formik.values.toDate) {
          const formattedLocation = {
            label: selectedLocation.name,
            value: { id: selectedLocation.id },
          };
          myContext.handleLoading(true);
          let data = {
            getreport: false,
            location: JSON.stringify(formattedLocation),
            dateFrom: reportDateFormate(formik.values.fromDate),
            dateTo: reportDateFormate(formik.values.toDate),
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            type: "xls",
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.EMPLOYEES_WORK_TIME_REPORT_ROUTE,
          });
        } else {
          clearTableData(Constants.TO_DATE_IS_REQUIRED);
        }
      } else {
        clearTableData(Constants.FORM_DATE_IS_REQUIRED);
      }
    } else {
      clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };
  const clearTableData = (message) => {
    // clear table data when remove date and location
    setTableData([]);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };
  const getEmployeesWorkTimeData = () => {
    if (
      null != formik.values.selectedLoactions &&
      "" != formik.values.selectedLoactions
    ) {
      if (null != formik.values.fromDate && "" != formik.values.fromDate) {
        if (null != formik.values.toDate && "" != formik.values.toDate) {
          setButtonDisable(true);
          setLoading(true);
          setShowTable(false);
          const formattedLocation = {
            label: selectedLocation.name,
            value: { id: selectedLocation.id },
          };

          let data = {
            getreport: true,
            location: JSON.stringify(formattedLocation),
            dateFrom: reportDateFormate(formik.values.fromDate),
            dateTo: reportDateFormate(formik.values.toDate),
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            //   query: JSON.stringify({
            //     limit: paginationModel.pageSize,
            //     page: paginationModel.page + 1,
            //     order:
            //       sortModel.length > 0
            //         ? sortModel[0].field
            //         : sortRef.current[0].field,
            //     order_type:
            //       sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
            //   }),
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.EMPLOYEES_WORK_TIME_REPORT_ROUTE,
          });
        } else {
          clearTableData(Constants.TO_DATE_IS_REQUIRED);
        }
      } else {
        clearTableData(Constants.FORM_DATE_IS_REQUIRED);
      }
    } else {
      clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };

  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
      setSelectedLocation(e.value);
      formik.setFieldValue("selectedLoactions", e.value.name);
      //   formik.setFieldValue("selectedLoactions", e.value);
    }
  };
  const handleResetAllData = () => {
    // setSortModel([
    //   {
    //     field: "id",
    //     sort: "asc",
    //   },
    // ]);
    // setPaginationModel({
    //   page: 0,
    //   pageSize: 25,
    // });
    setSelectedLocation([]);
    setTableData([]);
    setShowTable(true);
    setTotalCount(0);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    formik.resetForm();
  };
  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };
  const renderReportLayout = () => {
    return (
      <>
        <Box className="card flex justify-content-center">
          <PosDropDown
            id="selectedLoactions"
            options={locations}
            value={selectedLocation}
            resetFilterOnHide={true}
            onChange={handleOptionSelect}
            onBlur={(e) => {
              formik.setFieldTouched("selectedLoactions");
              formik.handleBlur(e);
            }}
            // multiSelect={true}
            optionLabel="name"
            placeholder="Select Locations*"
            width={
              isExtraLarge
                ? "20rem"
                : isLarge
                ? "17rem"
                : isMeduim1
                ? "13.32rem"
                : "100%"
            }
            height={"2.5rem"}
            className="w-full md:w-20rem"
            // maxSelectedLabels={2}
          />
          <Text color={Colors.errorColor}>
            {formik.touched.selectedLoactions &&
            formik.errors.selectedLoactions ? (
              <span>{formik.errors.selectedLoactions}</span>
            ) : null}
          </Text>
        </Box>
        <Stack
          className="card flex justify-content-center"
          flexDirection={isExtraSmall ? "column" : "row"}
          spacing={4}
          mt={isMeduim ? 4 : 0}
        >
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"fromDate"}
              ref={calendarRef}
              readOnlyInput={true}
              value={formik.values.fromDate}
              label={"From Date*"}
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              onFocus={() => {
                if (
                  null !== toDateCalendarRef.current &&
                  toDateCalendarRef.current
                ) {
                  toDateCalendarRef.current.hide();
                }
                setToDateFocus(false);
                setTimeout(() => {
                  setFromDateFocus(true);
                }, 1000);
                formik.setFieldTouched("fromDate", true);
              }}
              handleSelectData={hadleFromDateSelect}
              yearRange={"2000:2030"}
              maxDate={dateTimeValue}
              viewDate={fromDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.fromDate && formik.errors.fromDate ? (
                <span>{formik.errors.fromDate}</span>
              ) : null}
            </Text>
          </Box>
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"toDate"}
              ref={toDateCalendarRef}
              readOnlyInput={true}
              value={formik.values.toDate}
              label={"To Date*"}
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              onFocus={() => {
                if (null !== calendarRef.current && calendarRef.current) {
                  calendarRef.current.hide();
                }
                setFromDateFocus(false);
                setTimeout(() => {
                  setToDateFocus(true);
                }, 1000);
                formik.setFieldTouched("toDate", true);
              }}
              handleSelectData={(e) => {
                formik.setFieldValue("toDate", e.value);
                setToDateView(e.value);
              }}
              yearRange={"2000:2030"}
              minDate={
                null !== formik.values.fromDate && "" !== formik.values.fromDate
                  ? formik.values.fromDate
                  : dateTimeValue
              }
              maxDate={dateTimeValue}
              viewDate={toDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.toDate && formik.errors.toDate ? (
                <span>{formik.errors.toDate}</span>
              ) : null}
            </Text>
          </Box>
        </Stack>
      </>
    );
  };
  const [columnVisibilityModels, setColumnVisibilityModels] = useState({});
  const handleColumnVisibilityModelChange = (key, newModel) => {
    setColumnVisibilityModels((prevModels) => ({
      ...prevModels,
      [key]: newModel,
    }));
  };
  const modifiedData = Object.keys(tableData).flatMap((key) =>
    tableData[key].map((item, i) => ({
      name: key,
      id: item.date === undefined ? "" : i + 1,
      date: item.date === undefined ? "Total" : item.date,
      totalcount:
        item.totalcount === undefined
          ? `${item.total} (${item.totalWorkedHours} Hours)`
          : `${item.totalcount} (${item.lineWiseWorkedHours} Hours)`,
      time: item.time,
    }))
  );

  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Employees Work-time"]}
          breadCrumTitle={"Employees Work-time"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <PosIconButton
              name={Constants.EXPORT_BUTTON}
              onClick={handleExportReport}
              exportIcon={true}
              width={"7.5rem"}
            />
          )}
        </ButtonGroup>
      </Flex>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={"3xl"}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <PosListHeader header={emp}></PosListHeader>
          </ModalHeader>
          <ModalBody>
            <PosTable
              columnNames={viewColumnNames}
              rawdata={viewData}
              noAction={true}
              footerHide={true}
            />
          </ModalBody>
          <ModalFooter>
            <PosFormButton
              onClick={onClose}
              buttonText={"Close"}
              CancelButton={true}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box
        borderRadius="0.63rem"
        bg="white"
        // p={2}
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
          direction={isMeduim ? "column" : "row"}
          justifyContent={{ base: "center", sm: "space-between" }}
        >
          <SimpleReportLayout>{renderReportLayout()}</SimpleReportLayout>
          {/* <Wrap
            alignItems={{ base: "stretch", md: "center" }}
            spacing={4}
            mt={6}
            mb={4}
            ml={4}
            mr={4}
          >
            <WrapItem flexDirection={"column"}>
              <PosDropDown
                id="selectedLoactions"
                options={locations}
                value={selectedLocation}
                resetFilterOnHide={true}
                onChange={handleOptionSelect}
                onBlur={(e) => {
                  formik.setFieldTouched("selectedLoactions");
                  formik.handleBlur(e);
                }}
                // multiSelect={true}
                optionLabel="name"
                placeholder="Select Locations*"
                width={"20rem"}
                height={"2.5rem"}
                className="w-full md:w-20rem"
                // maxSelectedLabels={2}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.selectedLoactions &&
                formik.errors.selectedLoactions ? (
                  <span>{formik.errors.selectedLoactions}</span>
                ) : null}
              </Text>
            </WrapItem>
            <WrapItem flexDirection={"column"}>
              <PosDatePicker
                id={"fromDate"}
                ref={calendarRef}
                value={formik.values.fromDate}
                readOnlyInput={true}
                label={"From Date*"}
                onFocus={() => {
                  if (
                    null !== toDateCalendarRef.current &&
                    toDateCalendarRef.current
                  ) {
                    toDateCalendarRef.current.hide();
                  }
                  setToDateFocus(false);
                  setTimeout(() => {
                    setFromDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("fromDate", true);
                }}
                handleSelectData={hadleFromDateSelect}
                yearRange={"2000:2030"}
                maxDate={dateTimeValue}
                viewDate={fromDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.fromDate && formik.errors.fromDate ? (
                  <span>{formik.errors.fromDate}</span>
                ) : null}
              </Text>
            </WrapItem>
            <WrapItem flexDirection={"column"}>
              <PosDatePicker
                id={"toDate"}
                ref={toDateCalendarRef}
                value={formik.values.toDate}
                readOnlyInput={true}
                label={"To Date*"}
                onFocus={() => {
                  if (null !== calendarRef.current && calendarRef.current) {
                    calendarRef.current.hide();
                  }
                  setFromDateFocus(false);
                  setTimeout(() => {
                    setToDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("toDate", true);
                }}
                handleSelectData={(e) => {
                  formik.setFieldValue("toDate", e.value);
                  setToDateView(e.value);
                }}
                yearRange={"2000:2030"}
                minDate={
                  null !== formik.values.fromDate &&
                  "" !== formik.values.fromDate
                    ? formik.values.fromDate
                    : dateTimeValue
                }
                maxDate={dateTimeValue}
                viewDate={toDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.toDate && formik.errors.toDate ? (
                  <span>{formik.errors.toDate}</span>
                ) : null}
              </Text>
            </WrapItem>
          </Wrap> */}
          {/* <Spacer /> */}
          <Stack
            direction={"row"}
            justifyContent={isMeduim ? "flex-start" : "flex-end"}
            alignItems={"flex-start"}
            pl={isMeduim ? 4 : 0}
            pt={isMeduim ? 0 : 4}
          >
            <PosFormButton
              onClick={handleResetAllData}
              buttonText={"Reset"}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={
                formik.values.selectedLoactions?.length === 0 ||
                formik.values.fromDate === "" ||
                formik.values.toDate === "" ||
                formik.errors.selectedLoactions ||
                formik.errors.fromDate ||
                formik.errors.toDate ||
                buttonDisable
              }
              onClick={getEmployeesWorkTimeData}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          {showTable ? null : loading ? (
            <PosProgress />
          ) : totalcount > 0 ? (
            Object.keys(tableData).map((key) => {
              let tableDetails = modifiedData.filter(
                (data) => data.name === key && data.date != "Total"
              );
              return (
                <Box
                  mb={{ base: 4, md: 4 }}
                  key={key}
                  borderRadius="0.63rem"
                  bg={Colors.posPageDataBackground}
                  p="2"
                  boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
                >
                  <PosListHeader
                    header={`Employee Name : ${key}`}
                  ></PosListHeader>
                  <Box mt={8} maxHeight="31rem" overflowY="auto">
                    <PosDataGridTable
                      columns={columnNames}
                      rows={tableDetails}
                      columnVisibilityModel={columnVisibilityModels[key] || {}}
                      onColumnVisibilityModelChange={(newModel) =>
                        handleColumnVisibilityModelChange(key, newModel)
                      }
                      hideFooter={true}
                      autoHeight={true}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      fileName={Constants.EMPLOYEES_WORK_TIME_REPORT_EXPORT_CSV}
                    />
                  </Box>
                  <Box>
                    <PosDataGridTable
                      columns={columnNames.map((column) => ({
                        ...column,
                        renderCell: (params) => (
                          <div style={{ fontWeight: 500 }}>{params.value}</div>
                        ),
                      }))}
                      rows={modifiedData.filter(
                        (data) => data.name === key && data.date == "Total"
                      )}
                      columnHeaderHeight={0}
                      hideFooter={true}
                    />
                  </Box>
                </Box>
              );
            })
          ) : (
            <PosNoDataFound title={"There are no Employees Work Time."} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(EmployeesWorkTime);
