import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useMediaQuery,
  useRadioGroup,
  Image,
  useBreakpointValue,
  ChakraProvider,
} from "@chakra-ui/react";
import { GridToolbar } from "@mui/x-data-grid";
import { Mtheme, theme } from "../../../theme/index";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider } from "@mui/material/styles";

import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useParams,
  useLocation,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import * as Constants from "../../../constants/Constants";
import { WithRouter } from "../../../navigators/WithRouter";

import CreateIcon from "@mui/icons-material/Create";
import PersonIcon from "@mui/icons-material/Person";
import { Checkbox, IconButton } from "@mui/material";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosImageCropper,
  PosInput,
  PosLable,
  PosSearch,
  PosSwitchButton,
  PosTable,
  PosProgress,
  PosTostMessage,
  PosNoDataFound,
} from "../../../components/index";
import { twofix } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import {
  getAllLocationOsSelectedProduct,
  getProductsForSelectedLocation,
} from "./CouponService";
import moment from "moment";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // Optional for default styling
import toolTip from "../../../assets/images/tool_tip_hover_icon.svg";
import PercentageIcon from "../../../assets/images/icon_percentage.svg";
import ProductIcon from "../../../assets/images/coupon_product.svg";
import AmountIcon from "../../../assets/images/coupon_amount.svg";

const CardRadio = ({ children, isChecked, ...props }) => {
  return (
    <Box as="label">
      <input type="radio" {...props} style={{ display: "none" }} />
      <Box
        cursor="pointer"
        borderWidth="2px"
        borderRadius="0.625rem"
        // h={"7.25rem"}
        // w={"22.125rem"}
        maxWidth={"22.125rem"}
        boxShadow="md"
        // bg={isChecked ? "teal.600" : "white"}
        color={isChecked ? "white" : "black"}
        borderColor={isChecked ? "messenger.300" : "gray.300"}
        _hover={{
          bg: isChecked ? "messenger" : "gray.100",
        }}
        _checked={{
          bg: "teal.600",
          color: "white",
          borderColor: "#5881FE",
        }}
        px={5}
        py={3}
      >
        {children}
      </Box>
    </Box>
  );
};
const CouponEdit = (props) => {
  const myContext = useContext(AppContext);
  const { id } = useParams();
  const stateData = useLocation();
  const [paramData, setParamData] = useState(stateData.state);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [selectedDiscountType, setSelectedDiscountType] = useState("");
  const [selectedAudienceType, setSelectedAudienceType] = useState("");
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const effectRun = useRef(true);
  const [location, setLocation] = useState([]);
  const [allProductId, setAllProductId] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [imageUploadedFlag, setImageUploadedFlag] = useState(0);
  const [base64OfUploadedImg, setBase64OfUploadedImg] = useState("");
  const [croppedImgDetails, setCroppedImgDetails] = useState({
    filename: "",
    filetype: "",
    filesize: "",
    base64: "",
  });
  const [oldImageBase64, setOldImageBase64] = useState("");
  const [oldImageName, setOldImageName] = useState("");
  const [newImageUploadedFlag, setNewImageUploadedFlag] = useState(0);
  const [viewSelectedProducts, setViewSelectedProducts] = React.useState([]);
  const [checkedItems, setCheckedItems] = React.useState([]);
  const [productLocation, setProductLocation] = React.useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchQuery, setSearchQuery] = useState("");
  const [allProduct, setAllProduct] = useState([]);
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const handleSearchList = () => {
    paginationModel.page = 0;
    handleProductDetails();
  };
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setLocation(loaderResponse.data.data.location);
          const LocNameForInitialProd =
            loaderResponse.data.data.coupon.products.map(
              (loc) => loc.location.name
            );
          const uniqueLocNames = [...new Set(LocNameForInitialProd)];
          setProductLocation(uniqueLocNames);
          setSelectedCouponType(loaderResponse.data.data.coupon.coupon_type);
          setSelectedDiscountType(
            loaderResponse.data.data.coupon.discount_type
          );
          setSelectedAudienceType(
            loaderResponse.data.data.coupon.audiance_type
          );
          if (loaderResponse.data.data.coupon.coupon_type == "prod") {
            const locations = loaderResponse.data.data.location;
            const couponLocationId =
              loaderResponse.data.data.coupon.location_id;
            const matchingLocation = locations.find(
              (location) => location.id === couponLocationId
            );
            setSelectedLocation(matchingLocation);
            formik.setFieldValue("selectedLoactions", matchingLocation.name);
            const locationId = loaderResponse.data.data.coupon.location_id;
            const selectedProducts =
              loaderResponse.data.data.coupon.products.filter(
                (product) => product.location_id === locationId
              ).map((product) => ({
                ...product,
                categories: product.categories.map((cat) => cat.name).join(', ')
              }));
            // const selectedProducts = loaderResponse.data.data.coupon.products
            // .map(product => ({
            //  loaderResponse.data.data.coupon.location_id = product.location_id

            // }));
            setCheckedProducts(selectedProducts);
            setViewSelectedProducts(selectedProducts);
            const productIdsForChecked = selectedProducts.map(
              (product) => product.id
            );
            setCheckedItems(productIdsForChecked);

            const productDetails = loaderResponse.data.data.coupon.products;
            const productIds = productDetails.map((product) => product.id);
            setAllProductId(productIds);
          }
          if (
            null != loaderResponse.data?.data?.coupon.image_base64 &&
            "" != loaderResponse.data?.data?.coupon.image_base64
          ) {
            var imageName = loaderResponse.data?.data?.coupon.image;
            var explodedImageName =
              loaderResponse.data?.data?.coupon.image.split(".");
            var imageType = explodedImageName[explodedImageName.length - 1];
            var actualBase64 =
              "data:" +
              imageType +
              ";base64," +
              loaderResponse.data?.data?.coupon.image_base64;
            setOldImageBase64(actualBase64);
            setOldImageName(imageName);
            setBase64OfUploadedImg(actualBase64);
          }
          if (
            undefined !== loaderResponse.data?.data?.coupon.start_date &&
            null !== loaderResponse.data?.data?.coupon.start_date &&
            "" !== loaderResponse.data?.data?.coupon.start_date
          ) {
            let start_Date = new Date(
              moment(loaderResponse.data?.data?.coupon.start_date + "Z")
            );
            formik.setFieldValue("start_date", start_Date);
          }
          if (
            undefined !== loaderResponse.data?.data?.coupon.end_date &&
            null !== loaderResponse.data?.data?.coupon.end_date &&
            "" !== loaderResponse.data?.data?.coupon.end_date
          ) {
            let end_Date = new Date(
              moment(loaderResponse.data?.data?.coupon.end_date + "Z")
            );
            formik.setFieldValue("end_date", end_Date);
          }
          myContext.handleLoading(false);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        props.navigate(Constants.COUPON_LIST, {
          state: paramData,
        });
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.COUPON_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      } else {
        let actionData = actionResponse;
        error({ actionData });
      }
    }
  }, [actionResponse, loaderResponse]);
  const formik = useFormik({
    initialValues: {
      name:
        undefined != loaderResponse?.data?.data?.coupon.title &&
        null != loaderResponse?.data?.data?.coupon.title
          ? loaderResponse?.data?.data?.coupon.title
          : "",
      selectedLoactions: "",
      time_used: loaderResponse?.data?.data?.coupon?.time_used,
      discount_value: loaderResponse?.data?.data?.coupon?.discount_value,
      min_amount: loaderResponse?.data?.data?.coupon?.min_amount ?? "",
      discount_type: loaderResponse?.data?.data?.coupon?.discount_type,
      coupon_type: loaderResponse?.data?.data?.coupon?.coupon_type,
      audiance_type: loaderResponse?.data?.data?.coupon?.audiance_type ?? "",
      max_qty_per_coupon:
        loaderResponse?.data?.data?.coupon?.max_qty_per_coupon,
      time_used: loaderResponse?.data?.data?.coupon?.time_used,
      is_order_dropdown:
        loaderResponse?.data?.data?.coupon?.is_order_dropdown === 1,
      apply_on_all_prods:
        loaderResponse?.data?.data?.coupon?.apply_on_all_prods === 1,
      // location: selectedLocation,
      start_date: "",
      end_date: "",
      is_print_on_ord_rcpt:
        loaderResponse?.data?.data?.coupon?.is_print_on_ord_rcpt === 1,
    },

    validationSchema: Yup.object({
      // selectedLoactions: Yup.string().required(Constants.LOCATION_IS_REQUIRED),
      selectedLoactions: Yup.string().when("coupon_type", {
        is: "prod",
        then: (schema) => schema.required(Constants.LOCATION_IS_REQUIRED),
      }),
      name: Yup.string()
        .required(Constants.TAX_NAME_REQUIRED)
        .max(191, Constants.TAX_NAME_LENGTH_GREATE_THEN_191),
      discount_type: Yup.string().required(Constants.DISCOUNT_TYPE_REQUIRED),
      coupon_type: Yup.string().required(Constants.COUPON_TYPE_REQUIRED),
      // audiance_type: Yup.string().when("coupon_type", {
      //   is: "prod",
      //   then: (schema) => schema.required(Constants.AUDIENCE_TYPE__REQUIRED),
      // }),
      // audiance_type: Yup.string().when(["coupon_type", "is_order_dropdown"], {
      //   is: (coupon_type, is_order_dropdown) => coupon_type === "prod" && is_order_dropdown === false,
      //   then: (schema) => schema.required(Constants.AUDIENCE_TYPE__REQUIRED),
      // }),
      discount_value: Yup.number()
        .required(Constants.DISCOUNT_VALUE_REQUIRED)
        .typeError(Constants.DISCOUNT_VALUE_NOT_VALID)
        .when("discount_type", {
          is: "amou",
          then: (schema) =>
            schema
              .min(0.01, Constants.DISCOUNT_VALUE_MIN_VALUE)
              .max(999999.99, Constants.DISCOUNT_VALUE_MAX_VALUE),
          otherwise: (schema) =>
            schema
              .min(0.01, Constants.DISCOUNT_VALUE_MIN_VALUE)
              .max(100, Constants.DISCOUNT_VALUE_MORE_THEN_100),
        }),
      max_qty_per_coupon: Yup.number().when("coupon_type", {
        is: "prod",
        then: (schema) =>
          schema
            .required(Constants.MAX_QTY_PER_COP_REQ)
            .integer(Constants.MAX_QTY_PER_COP_IS_INT)
            .typeError(Constants.MAX_QTY_PER_COP_IS_INVALID )
            .min(1, Constants.MIN_MAX_QTY_PER_COP)
            .max(999999, Constants.MAX_MAX_QTY_PER_COP),
      }),
      min_amount: Yup.number().when("coupon_type", {
        is: "ord",
        then: (schema) =>
          schema
            .required(Constants.MIN_AMOUNT_REQ)
            .typeError(Constants.MIN_AMOUNT_MUST_BE_NUMBER)
            .min(0.01, Constants.MIN_VAL_OF_MIN_AMOU)
            .max(999999.99, Constants.MAX_VSL_OF_MIN_AMOU),
      }),
    }),
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      setButtonDisable(true);
      let path = Constants.COUPON_EDIT + id;
      const inValidProduct = viewSelectedProducts.filter(
        (product) => parseFloat(product.sale_price) < values.discount_value
      );
      if (
        formik.values.coupon_type == "prod" &&
        formik.values.discount_type == "amou" &&
        inValidProduct.length > 0
      ) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.COUPON_MODULE,
          alertDescription:
            Constants.DISC_VAL_GREATER_THAN_PROD_SALE_PRICE_TOAST,
        });
        myContext.handleLoading(false);
        setButtonDisable(false);
      } else {
        let data = {};
        data.name = values.name;
        data.coupon_type = values.coupon_type;
        data.discount_type = values.discount_type;
        data.audiance_type = values.audiance_type;
        data.discount_value = values.discount_value;
        data.time_used = values.time_used;
        // if (values.coupon_type== "prod"){
        data.max_qty_per_coupon =
          values.max_qty_per_coupon == 0 ? null : values.max_qty_per_coupon;
        // }else{
        // data.max_qty_per_coupon = null
        // }

        data.min_amount = values.min_amount;
        const selectedProducts = checkedProducts.filter((product) =>
          checkedItems.includes(product.id)
        );
        data.start_date = values.start_date
          ? values.start_date instanceof Date
            ? values.start_date.toISOString()
            : values.start_date
          : "";
        data.image = oldImageName;
        data.image_base64 = "";
        data.end_date = values.end_date
          ? values.end_date instanceof Date
            ? values.end_date.toISOString()
            : values.end_date
          : "";
        data.is_print_on_ord_rcpt = values.is_print_on_ord_rcpt ? 1 : 0;
        data.apply_on_all_prods = values.apply_on_all_prods ? 1 : 0;
        data.is_order_dropdown = values.is_order_dropdown ? 1 : 0;
        data.location = selectedLocation.id;
        if (values.coupon_type === "prod") {
          data.location_id = parseInt(selectedLocation.id, 10);
        } else {
          data.location_id = "";
        }
        data.products = JSON.stringify(selectedProducts);
        data.allProductBarcodes = JSON.stringify(allProductId);

        // var imageDetails = {
        //   profilepic: croppedImgDetails,
        // };
        // data.couponImage = JSON.stringify(imageDetails);
        if (imageUploadedFlag == 1) {
          if (newImageUploadedFlag == 1) {
            var imageDetails = {
              profilepic: croppedImgDetails,
            };
            data.couponImage = JSON.stringify(imageDetails);
          } else {
            data.couponImage = JSON.stringify({});
            data.image_base64 = JSON.stringify(croppedImgDetails.base64);
          }
        } else {
          var imageDetails = {
            profilepic: {},
          };
          data.couponImage = JSON.stringify(imageDetails);
          data.image_base64 = JSON.stringify(null);
        }
        submit(data, {
          method: Constants.PUT,
          path: path,
        });
      }
    },
  });
  const [selectedCouponType, setSelectedCouponType] = useState(
    formik.initialValues.coupon_type
  );

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(
        formik.values.name.length <= 0 ||
        formik.errors.name ||
        formik.errors.discount_type ||
        formik.errors.discount_value ||
        buttonDisable
      )
    ) {
      formik.handleSubmit();
    }
  };
  // const handleCallback = (base64Details, fileDetails) => {
  //   if (
  //     null != base64Details &&
  //     "" != base64Details &&
  //     null != fileDetails &&
  //     "" != fileDetails
  //   ) {
  //     setCroppedImgDetails((previousState) => {
  //       return {
  //         ...previousState,
  //         filename: fileDetails["name"],
  //         filetype: fileDetails["type"],
  //         filesize: fileDetails["size"],
  //         base64: base64Details.toDataURL().split(",")[1],
  //       };
  //     });
  //     setBase64OfUploadedImg(base64Details.toDataURL());
  //     setImageUploadedFlag(1);
  //   } else {
  //     setCroppedImgDetails((previousState) => {
  //       return {
  //         ...previousState,
  //         filename: "",
  //         filetype: "",
  //         filesize: "",
  //         base64: "",
  //       };
  //     });
  //     setBase64OfUploadedImg("");
  //     setImageUploadedFlag(0);
  //   }
  // };
  const handleCallback = (base64Details, fileDetails, imgUploadStatus) => {
    if (
      (null == base64Details || "" == base64Details) &&
      (null == fileDetails || "" == fileDetails) &&
      (null == imgUploadStatus || "" == imgUploadStatus)
    ) {
      setCroppedImgDetails((previousState) => {
        return {
          ...previousState,
          filename: "",
          filetype: "",
          filesize: "",
          base64: "",
        };
      });
      setOldImageName("");
      setBase64OfUploadedImg("");
      setImageUploadedFlag(0);
    } else {
      if (imgUploadStatus.newImgUploadFlag == 1) {
        setCroppedImgDetails((previousState) => {
          return {
            ...previousState,
            filename: fileDetails["name"],
            filetype: fileDetails["type"],
            filesize: fileDetails["size"],
            base64: base64Details.toDataURL().split(",")[1],
          };
        });
        setBase64OfUploadedImg(base64Details.toDataURL());
        setImageUploadedFlag(1);
        setNewImageUploadedFlag(1);
      } else {
        setCroppedImgDetails((previousState) => {
          return {
            ...previousState,
            filename: null,
            filetype: null,
            filesize: null,
            base64: base64Details.toDataURL().split(",")[1],
          };
        });
        setBase64OfUploadedImg(base64Details.toDataURL());
        setImageUploadedFlag(1);
        setNewImageUploadedFlag(0);
      }
    }
  };
  const [isScreenSize480] = useMediaQuery("(max-width: 480px)");
  const tabStyle = {
    fontWeight: "500",
    fontSize: "0.94rem",
    lineHeight: "1.17rem",
    letterSpacing: "-1.5%",
    color: Colors.posTextInfo,
    // width: "17.94rem",
  };
  const [curruntTab, setCurruntTab] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [totalcount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleProductDetails = async () => {
    if (Object.keys(selectedLocation).length > 0) {
      setLoading(true);
      let data = {
        location: selectedLocation.id,
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: searchQuery,
      };
      try {
        getProductsForSelectedLocation(data)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              setLoading(false);
              setAllProduct(response.data.data.data);
              setTotalCount(response.data.data.totalItems);
              myContext.handleLoading(false);
            } else {
              setLoading(false);
              let actionData = response?.data;
              setTotalCount(0);
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            let actionData = err?.response?.data;
            setTotalCount(0);
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setLoading(false);
        setTotalCount(0);
        myContext.handleLoading(false);
      }
    }
  };
  const handleProductDetailsAfterClearSearch = async () => {
    if (Object.keys(selectedLocation).length > 0) {
      setLoading(true);
      let data = {
        location: selectedLocation.id,
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: "",
      };
      try {
        getProductsForSelectedLocation(data)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              setLoading(false);
              setAllProduct(response.data.data.data);
              setTotalCount(response.data.data.totalItems);
              myContext.handleLoading(false);
            } else {
              setLoading(false);
              let actionData = response?.data;
              error({ actionData });
              setTotalCount(0);
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            let actionData = err?.response?.data;
            error({ actionData });
            setTotalCount(0);
            myContext.handleLoading(false);
          });
      } catch (error) {
        setLoading(false);
        setTotalCount(0);
        myContext.handleLoading(false);
      }
    }
  };
  const handleTabChange = (index) => {
    setCurruntTab(index);
    setTabIndex(index);
    if (index == 0) {
      effectRun.current = true;
      // setLoading(true);
    } else {
      effectRun.current = true;
      // setLoading(true);
      if (selectedCouponType === "prod" && viewSelectedProducts.length > 0) {
        handleProductDetails();
        // fetchProductLocation();
      }
    }
  };
  const couponTypeOptions = [
    {
      title: "Product",
      body: "This coupon can only be applied to selected products",
      coupon_type: "prod",
      // icon: <ShoppingCartIcon style={{ fill: Colors.primeTaxInfo }} />,
      icon: <Image src={ProductIcon} />,
    },
    {
      title: "Order",
      body: "This coupon can only be applied to the entire order",
      coupon_type: "ord",
      icon: <ShoppingCartIcon style={{ fill: Colors.primeTaxInfo }} />,
    },
  ];
  const discountTypeOptions = [
    {
      title: "Amount",
      body: "The coupon discount is calculated based on specific amounts.",
      discount_type: "amou",
      // icon: <MonetizationOnIcon style={{ fill: Colors.primeTaxInfo }} />,
      icon: <Image src={AmountIcon} />,
    },
    {
      title: "Percentage",
      body: "The coupon discount is calculated based on specific percentages",
      discount_type: "per",
      icon: <Image src={PercentageIcon} />,
    },
  ];
  const audienceTypeOptions = [
    {
      title: "Generic",
      body: "The coupon discount can be applied by all customers",
      audiance_type: "GEN",
      icon: <CreateIcon style={{ fill: Colors.primeTaxInfo }} />,
    },
    {
      title: "Targeted",
      body: "The coupon discount can be applied by specific customers",
      audiance_type: "TAR",
      icon: <PersonIcon style={{ fill: Colors.primeTaxInfo }} />,
    },
  ];
  // value: "TAR" value: "GEN"
  const handleCouponTypeChange = (value) => {
    setSelectedCouponType(value);
    if (formik.values.coupon_type !== value) {
      setSelectedLocation([]);
      formik.setFieldValue("selectedLoactions", "");
      formik.setFieldTouched("selectedLoactions", "");
      formik.setFieldValue("min_amount", "");
      formik.setFieldTouched("min_amount", "");
      formik.setFieldValue("max_qty_per_coupon", "");
      formik.setFieldTouched("max_qty_per_coupon", "");
      formik.setFieldValue("coupon_type", value);
      setViewSelectedProducts([]);
      setProductLocation([]);
      setCheckedItems([]);
    }
  };
  const handleDiscountTypeChange = (value) => {
    setSelectedDiscountType(value);
    formik.setFieldValue("discount_type", value);
  };
  const handleAudienceTypeChange = (value) => {
    setSelectedAudienceType(value);
    formik.values.is_print_on_ord_rcpt = false;
    formik.setFieldValue("audiance_type", value);
    if (value == "GEN") {
      formik.setFieldValue("start_date", dateTimeValue);
      formik.setFieldValue("end_date", "");
    } else {
      formik.setFieldValue("start_date", "");
      formik.setFieldValue("end_date", "");
    }
  };
  const {
    getRootProps: getCouponTypeRootProps,
    getRadioProps: getCouponTypeRadioProps,
  } = useRadioGroup({
    name: "coupon_type",
    defaultValue: "",
    onChange: handleCouponTypeChange,
  });
  const {
    getRootProps: getDiscountTypeRootProps,
    getRadioProps: getDiscountTypeRadioProps,
  } = useRadioGroup({
    name: "discount_type",
    defaultValue: "",
    onChange: handleDiscountTypeChange,
  });
  const {
    getRootProps: getAudienceTypeRootProps,
    getRadioProps: getAudienceTypeRadioProps,
    setValue: setAudienceValue,
  } = useRadioGroup({
    name: "audiance_type",
    defaultValue: "",
    onChange: handleAudienceTypeChange,
  });
  // const group = getRootProps();
  const couponTypeGroup = getCouponTypeRootProps();
  const discountTypeGroup = getDiscountTypeRootProps();
  const audienceTypeGroup = getAudienceTypeRootProps();

  const columnProductViewNames = [
    {
      columnNames: "Id",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Product name",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Categories",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Primary Barcode",
      sortName: "",
      width: "",
    },
    {
      columnNames: "SKU",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Sale Price",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Action",
      sortName: "",
      width: "",
    },
  ];
  const modifiedViewData = viewSelectedProducts.map((products) => ({
    id: products.id,
    name: products.name,
    categories : products.categories,
    barcode: products.barcode,
    sku: products.sku || "N.A.",
    sale_price: products.sale_price,
  }));
  const handleSingleDelete = (id) => {
    const updatedViewItems = viewSelectedProducts.filter(
      (itemId) => itemId.id !== id
    );
    const updatedCheckedItems = checkedItems.filter((itemId) => itemId !== id);
    setCheckedItems(updatedCheckedItems);
    setViewSelectedProducts(updatedViewItems);
    const updatedCheckedProducts = checkedProducts.filter((p) => p.id !== id);
    setCheckedProducts(updatedCheckedProducts);
    fetchProductLocationAfterLocationChange(updatedCheckedProducts);
  };
  const handleCheckedItemsChange = (checkedItems) => {
    setCheckedItems(checkedItems);
  };
  const [checkedProducts, setCheckedProducts] = useState([]);
  function handleCheckboxChange(params) {
    const rowId = params.row.id;
    const checkedRow = params.row;
    let productData = [];
    let data = [];
    if (checkedItems.includes(rowId)) {
      let index = checkedItems.findIndex((x) => x === rowId);
      if (index > -1) {
        data = [
          ...checkedItems.slice(0, index),
          ...checkedItems.slice(index + 1),
        ];
        setCheckedItems(data);
        productData = checkedProducts.filter((product) => product.id !== rowId);
        setCheckedProducts(productData);
      }
    } else {
      data = [...checkedItems, rowId];
      setCheckedItems(data);
      productData = [...checkedProducts, checkedRow];
      setCheckedProducts(productData);
    }
  }
  const fetchProductLocation = async () => {
    if (viewSelectedProducts.length > 0) {
      try {
        getAllLocationOsSelectedProduct(viewSelectedProducts)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              const data = response.data.data.data;
              const locationNames = data.map((item) => item.location.name);
              const allProductId = data.map((item) => item.id);
              setAllProductId(allProductId);
              const uniqueLocationNames = [...new Set(locationNames)];
              setProductLocation(uniqueLocationNames);
              myContext.handleLoading(false);
            } else {
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        myContext.handleLoading(false);
      }
    }
  };
  const fetchProductLocationAfterLocationChange = async (selectedProducts) => {
    if (selectedProducts.length > 0) {
      try {
        getAllLocationOsSelectedProduct(selectedProducts)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              const data = response.data.data.data;
              const allProductId = data.map((item) => item.id);
              setAllProductId(allProductId);
              const locationNames = data.map((item) => item.location.name);
              const uniqueLocationNames = [...new Set(locationNames)];
              setProductLocation(uniqueLocationNames);
              myContext.handleLoading(false);
            } else {
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        myContext.handleLoading(false);
      }
    }
  };
  const handleSelectedViewProduct = () => {
    onClose();

    const selectedProducts = checkedProducts.filter((product) =>
      checkedItems.includes(product.id)
    );
    setViewSelectedProducts(selectedProducts);
    fetchProductLocationAfterLocationChange(selectedProducts);
    setSearchQuery("");
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setSortModel([
      {
        field: "name",
        sort: "asc",
      },
    ]);
  };
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const productColumnNames = [
    {
      field: "selection",
      headerName: "",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      disableColumnMenu: true,
      width: 90,
      renderCell: (params) => (
        <IconButton
          aria-label="select"
          onClick={() => handleCheckboxChange(params)}
          sx={{ color: "#5881FE" }}
        >
          <Checkbox
            checked={checkedItems.includes(params.row.id)}
            onChange={() => handleCheckboxChange(params)}
          />
        </IconButton>
      ),
    },
    // { field: "id", headerName: "Id", sortable: true, flex: 1 },
    {
      field: "name",
      headerName: "Product name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "categories",
      headerName: "Categories",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "barcode",
      headerName: "Primary Barcode",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "sku",
      headerName: "SKU",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "sale_price",
      headerName: "Sales Price",
      sortable: true,
      ...actionColumn,
    },
  ];
  const modifiedDeactiveData = allProduct.map((data) => ({
    id: data.id,
    name: data.name,
    barcode: data.barcode,
    sale_price: data.sale_price,
    sku:
      data.sku === null || data.sku === "" || data.sku === undefined
        ? "N.A."
        : data.sku,
    location: data.location,
    location_id: data.location_id,
    productbarcode: data.productbarcode,
    categories : data.categories.map((cat)=>cat.name).join(', ')
  }));
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    // total_cash_discount: false,
  });
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "name",
      sort: "asc",
    },
  ]);
  useEffect(() => {
    handleProductDetails();
  }, [paginationModel, sortModel]);
  const sortRef = useRef(sortModel);
  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
      setSelectedLocation(e.value);
      formik.setFieldValue("selectedLoactions", e.value.name);
      if (
        e.value.name !== undefined &&
        e.value.name !== null &&
        e.value.name !== ""
      ) {
        formik.setFieldTouched("selectedLoactions", false);
      }
      setViewSelectedProducts([]);
      setCheckedItems([]);
      formik.setFieldValue("apply_on_all_prods", false);
      setProductLocation([]);
    } //   formik.setFieldValue("selectedLoactions", e.value);
  };
  const handleCloseModal = () => {
    onClose();
    const selectedProductIds = viewSelectedProducts.map(
      (product) => product.id
    );
    setCheckedItems(selectedProductIds);
    const selectedProducts = viewSelectedProducts.map((p) => p);
    setCheckedProducts(selectedProducts);
    setSortModel([
      {
        field: "name",
        sort: "asc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setSearchQuery("");
  };
  const handleShowProduct = (event) => {
    onOpen();
    handleProductDetails();
  };
  const handleClear = () => {
    setSearchQuery("");
    handleProductDetailsAfterClearSearch();
  };
  const modalSizes = useBreakpointValue({
    base: "half",
    sm: "2md",
    md: "2lg",
    lg: "md",
    xl: "lg",
  });     
  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1" w={isMobile ? "100vw" : "calc(100vw - 263.2px)"}>
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={2}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              props.navigate(Constants.COUPON_LIST, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["Coupons", "Edit Coupons"]}
            breadCrumTitle={"Edit Coupons"}
          />
        </Flex>
        <Tabs
          mt={{ base: 4, lg: 8 }}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          mb={{ base: 4, lg: 14 }}
          index={tabIndex}
          onChange={(index) => handleTabChange(index)}
        >
          <TabList
            sx={{
              scrollbarWidth: "none",
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
            overflow={isScreenSize480 ? "scroll" : "none"}
          >
            <Tab
              w={{ base: "100%", md: "100%", lg: "100%", xl: "17.34rem" }}
              style={tabStyle}
              // _selected={{ color: Colors.taxInfo }}
            >
              Coupon Identity
            </Tab>
            <Tab
              w={{ base: "100%", md: "100%", lg: "100%", xl: "17.34rem" }}
              style={tabStyle}
              isDisabled={
                selectedCouponType === "ord" ||
                !selectedCouponType ||
                formik.values.name.length <= 0 ||
                formik.errors.name ||
                formik.errors.is_order_dropdown ||
                // formik.values.audiance_type == "" ||
                formik.errors.apply_on_all_prods ||
                // formik.errors.audiance_type ||
                formik.errors.min_amount ||
                formik.errors.discount_type ||
                formik.errors.discount_value ||
                formik.errors.coupon_type ||
                formik.errors.max_qty_per_coupon ||
                (formik.values.coupon_type == "prod" &&
                  (null == formik.values.selectedLoactions ||
                    "" == formik.values.selectedLoactions)) ||
                formik.errors.is_print_on_ord_rcpt ||
                buttonDisable ||
                (formik.values.is_order_dropdown == false &&
                  (undefined == formik.values.audiance_type ||
                    null == formik.values.audiance_type ||
                    "" == formik.values.audiance_type)) ||
                (formik.values.coupon_type == "prod" &&
                  (null == formik.values.max_qty_per_coupon ||
                    "" == formik.values.max_qty_per_coupon ||
                    null == formik.values.selectedLoactions ||
                    "" == formik.values.selectedLoactions)) ||
                (formik.values.coupon_type == "ord" &&
                  (null == formik.values.min_amount ||
                    "" == formik.values.min_amount))
                // selectedLocation.length === 0
              }
              // _selected={{ color: Colors.taxInfo }}
            >
              Coupon Attributes
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Box
                borderRadius="0.63rem"
                bg={Colors.posPageDataBackground}
                boxShadow={
                  "0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow
                }
                p="5"
              >
                <Flex flexDirection={"row"}>
                  <PosLable
                    label={true}
                    color={Colors.posTextInfo}
                    name={"Is this a Predefined Coupon?*"}
                  />
                  <Tippy
                    content="By saving it in Pre-Defined Discounts, it can easily be accessed from the POS tablet whenever necessary."
                    interactive={true}
                    maxWidth={350}
                    placement="top"
                    animation="fade"
                    inertia={true}
                    moveTransition="transform 0.2s ease-out"
                    theme="tomato"
                  >
                    <Image
                      src={toolTip}
                      alt="Tooltip"
                      mt={"2rem"}
                      ml={{ base: "0rem", md: "0.3rem" }}
                    />
                  </Tippy>
                </Flex>
                <PosSwitchButton
                  id="is_order_dropdown"
                  switchValue={formik.values.is_order_dropdown}
                  // onChange={formik.handleChange}
                  onChange={(value) => {
                    formik.handleChange(value);
                    setAudienceValue("");
                    formik.setFieldValue("start_date", "");
                    formik.setFieldValue("end_date", "");
                    formik.setFieldValue("is_print_on_ord_rcpt", false);
                    formik.setFieldValue("audiance_type", "");
                  }}
                />
                {/* <Text
                  mt={"0.88rem"}
                  h={"1.44rem"}
                  color={Colors.taxCreateExemptEbt}
                  style={{
                    flexShrink: "0",
                    fontSize: "0.75rem",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                  }}
                >
                  Turn on, to select as a predefined coupon. Predefined Coupons
                  can be accessed from the POS tablet when necessary
                </Text> */}
                <Stack spacing={1}>
                  <PosLable
                    label={true}
                    color={Colors.posTextInfo}
                    name={"Coupon name*"}
                  />
                  <PosInput
                    id="name"
                    width={{
                      base: "100%",
                      md: "100%",
                      lg: "100%",
                      xl: "46.31rem",
                    }}
                    placeholder={"$5 OFF on $10 order"}
                    handleInputChange={formik.handleChange}
                    inputValue={formik.values.name}
                    handleBlur={(e) => {
                      formik.setFieldValue("name", formik.values.name.trim());
                      formik.handleBlur(e);
                    }}
                    onKeyDown={handleKeyDown}
                    posInputGroup={true}
                    background={Colors.posviewbgcolor}
                    inputType={"text"}
                    inputError={formik.touched.name && formik.errors.name}
                  >
                    <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
                  </PosInput>
                  <Text color={Colors.errorColor}>
                    {formik.touched.name && formik.errors.name ? (
                      <span>{formik.errors.name}</span>
                    ) : null}
                  </Text>
                </Stack>
                <Box mt={{ base: "2rem", md: "2rem" }}>
                  {!oldImageBase64 ? (
                    <PosImageCropper
                      parentCallback={handleCallback}
                      imgUploadLabel="Upload Image"
                      canvasWidth="14.75rem"
                      canvasHeight="14.75rem"
                      cropperWidth={236}
                          cropperHeight={193}
                      cropperMinWidth={96} // Minimum width of the crop area Cropper hide na thay ena mate
                      cropperMaxWidth={236} // Maximum width of the crop area when edit open at time full cropper display
                      cropperMinHeight={79} // Minimum height of the crop area Cropper hide na thay ena mate
                      cropperMaxHeight={193}
                    />
                  ) : (
                    <PosImageCropper
                      parentCallback={handleCallback}
                      oldImageBase64={oldImageBase64}
                      imgUploadLabel="Upload Image"
                      canvasWidth="14.75rem"
                      canvasHeight="14.75rem"
                      cropperWidth={236}
                          cropperHeight={193}
                      cropperMinWidth={96} // Minimum width of the crop area Cropper hide na thay ena mate
                      cropperMaxWidth={236} // Maximum width of the crop area when edit open at time full cropper display
                      cropperMinHeight={79} // Minimum height of the crop area Cropper hide na thay ena mate
                      cropperMaxHeight={193}
                    />
                  )}
                </Box>
                <Divider mt={8} mb={8} />
                <Heading fontWeight="500" fontSize="0.94rem" color="#010048">
                  Coupon Type
                </Heading>
                <Text color={Colors.posViewTextColor} fontSize="0.94rem">
                  Determines if coupon is made for an entire order or for a
                  specific product
                </Text>

                <Flex
                  justifyContent={{ base: "center", md: "revert-layer" }}
                  alignItems={{ base: "flex-start" }}
                  flexDirection={{ base: "column", md: "row" }}
                  mt={4}
                  spacing={"8.13rem"}
                  mb={8}
                  gap={"2.12rem"}
                  {...couponTypeGroup}
                  // w={{ md: "44.12rem" }}
                >
                  {couponTypeOptions.map((option, index) => {
                    const radio = getCouponTypeRadioProps({
                      value: option.coupon_type,
                    });
                    return (
                      <CardRadio
                        key={index}
                        {...radio}
                        isChecked={selectedCouponType === option.coupon_type}
                      >
                        <Flex flexDirection="row" alignItems="center" gap={2}>
                          {option.icon}
                          <Text
                            fontWeight="500"
                            color={Colors.posTextInfo}
                            textAlign="center"
                            fontSize={"0.94rem"}
                          >
                            {option.title}
                          </Text>
                        </Flex>
                        <Text
                          fontWeight={300}
                          mt={"0.63rem"}
                          fontSize={"0.94rem"}
                          color={Colors.posViewTextColor}
                        >
                          {option.body}
                        </Text>
                      </CardRadio>
                    );
                  })}
                </Flex>
                {selectedCouponType === "prod" && (
                  <>
                    <PosLable
                      label={true}
                      color={Colors.posTextInfo}
                      name={"Location*"}
                    />
                    <PosDropDown
                      value={selectedLocation}
                      // onChange={(e) => setSelectedLocation(e.value)}
                      onChange={handleOptionSelect}
                      onBlur={(e) => {
                        formik.setFieldTouched("selectedLoactions");
                        formik.handleBlur(e);
                      }}
                      options={location}
                      optionLabel={"name"}
                      placeholder={"Select a Location"}
                      width={"100%"}
                      maxWidth={"46.31rem"}
                      height={"2.5rem"}
                      className="w-full md:w-20rem"
                      filter
                    ></PosDropDown>
                    <Text color={Colors.errorColor}>
                      {formik.touched.selectedLoactions &&
                      formik.errors.selectedLoactions ? (
                        <span>{formik.errors.selectedLoactions}</span>
                      ) : null}
                    </Text>
                    <Stack spacing={1}>
                      <Flex flexDirection={"row"}>
                        <PosLable
                          label={true}
                          color={Colors.posTextInfo}
                          name={
                            "Maximum number of products on which this discount coupon can be used*"
                          }
                        />
                        <Tippy
                          content="With this, you can set a limit on the number of uses for that specific coupon by a user."
                          interactive={true}
                          maxWidth={350}
                          placement="top"
                          animation="fade"
                          inertia={true}
                          moveTransition="transform 0.2s ease-out"
                          theme="tomato"
                        >
                          <Image
                            src={toolTip}
                            alt="Tooltip"
                            mt={"2rem"}
                            ml={{ base: "0rem", md: "0.3rem" }}
                          />
                        </Tippy>
                      </Flex>
                      <PosInput
                        id="max_qty_per_coupon"
                        placeholder={
                          "Enter a Max Quantity of Product Per  Coupon"
                        }
                        background={Colors.posviewbgcolor}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.max_qty_per_coupon || ""}
                        handleBlur={(e) => {
                          formik.setFieldValue(
                            "max_qty_per_coupon",
                            twofix(formik.values.max_qty_per_coupon)
                          );
                          formik.handleBlur(e);
                        }}
                        width={{
                          base: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "46.17rem",
                        }}
                        onKeyDown={handleKeyDown}
                        posNumberInput={true}
                        inputType={"text"}
                        inputError={
                          formik.touched.max_qty_per_coupon &&
                          formik.errors.max_qty_per_coupon
                        }
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.max_qty_per_coupon &&
                        formik.errors.max_qty_per_coupon ? (
                          <span>{formik.errors.max_qty_per_coupon}</span>
                        ) : null}
                      </Text>
                    </Stack>
                  </>
                )}
                {selectedCouponType === "ord" && (
                  <>
                    <Stack spacing={1}>
                      <PosLable
                        label={true}
                        color={Colors.posTextInfo}
                        name={"Minimum Amount*"}
                      />
                      <PosInput
                        id="min_amount"
                        placeholder={"Enter a Minimum Amount"}
                        handleInputChange={formik.handleChange}
                        // handleInputChange={(e) => {
                        //   formik.setFieldValue(
                        //     "min_amount",
                        //     parseFloat(e.target.value) || ""
                        //   );
                        // }}
                        inputValue={formik.values.min_amount}
                        handleBlur={(e) => {
                          formik.handleBlur(e);
                          formik.setFieldValue(
                            "min_amount",
                            twofix(formik.values.min_amount)
                          );
                        }}
                        onKeyDown={handleKeyDown}
                        width={{
                          base: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "46.17rem",
                        }}
                        posNumberInput={true}
                        background={Colors.posviewbgcolor}
                        inputType={"number"}
                        inputError={
                          formik.touched.min_amount && formik.errors.min_amount
                        }
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.min_amount &&
                        formik.errors.min_amount ? (
                          <span>{formik.errors.min_amount}</span>
                        ) : null}
                      </Text>
                    </Stack>
                  </>
                )}
                <Divider mt={8} mb={8} />
                <Heading fontWeight="500" fontSize="0.94rem" color="#010048">
                  Discount Type
                </Heading>
                <Text color={Colors.posViewTextColor} fontSize="0.94rem">
                  Determines the type of coupon discount value applied
                </Text>
                <Flex
                  justifyContent={{ base: "center", md: "revert-layer" }}
                  alignItems={{ base: "flex-start" }}
                  flexDirection={{ base: "column", md: "row" }}
                  mt={4}
                  spacing={"8.13rem"}
                  mb={8}
                  gap={"2.12rem"}
                  {...discountTypeGroup}
                  // w={{ md: "44.12rem" }}
                >
                  {discountTypeOptions.map((option, index) => {
                    const radio = getDiscountTypeRadioProps({
                      value: option.discount_type,
                    });
                    return (
                      <CardRadio
                        key={index}
                        {...radio}
                        isChecked={
                          selectedDiscountType === option.discount_type
                        }
                      >
                        <Flex flexDirection="row" alignItems="center" gap={2}>
                          {option.icon}
                          <Text
                            fontWeight="500"
                            color={Colors.posTextInfo}
                            textAlign="center"
                            fontSize={"0.94rem"}
                          >
                            {option.title}
                          </Text>
                        </Flex>
                        <Text
                          fontWeight={300}
                          mt={"0.63rem"}
                          fontSize={"0.94rem"}
                          color={Colors.posViewTextColor}
                        >
                          {option.body}
                        </Text>
                      </CardRadio>
                    );
                  })}
                </Flex>
                <Stack spacing={1}>
                  <PosLable
                    label={true}
                    color={Colors.posTextInfo}
                    name={"Discount Value*"}
                  />
                  <PosInput
                    id="discount_value"
                    placeholder={"Enter discount amount"}
                    handleInputChange={formik.handleChange}
                    inputValue={formik.values.discount_value}
                    handleBlur={(e) => {
                      formik.handleBlur(e);
                      formik.setFieldValue(
                        "discount_value",
                        twofix(formik.values.discount_value)
                      );
                    }}
                    background={Colors.posviewbgcolor}
                    onKeyDown={handleKeyDown}
                    posInput={true}
                    width={{
                      base: "100%",
                      md: "100%",
                      lg: "100%",
                      xl: "46.17rem",
                    }}
                    inputType={"text"}
                    inputError={
                      formik.touched.discount_value &&
                      formik.errors.discount_value
                    }
                  />
                  <Text color={Colors.errorColor}>
                    {formik.touched.discount_value &&
                    formik.errors.discount_value ? (
                      <span>{formik.errors.discount_value}</span>
                    ) : null}
                  </Text>
                </Stack>
                {(selectedCouponType === "prod" ||
                  selectedCouponType === "ord") &&
                  !formik.values.is_order_dropdown && (
                    <>
                      <Divider mt={8} mb={8} />
                      <Heading
                        fontWeight="500"
                        fontSize="0.94rem"
                        color="#010048"
                      >
                        Audience Type
                      </Heading>
                      <Text color={Colors.posViewTextColor} fontSize="0.94rem">
                        Determines who can apply the coupon discount
                      </Text>
                      <Flex
                        justifyContent={{ base: "center", md: "revert-layer" }}
                        alignItems={{ base: "flex-start" }}
                        flexDirection={{ base: "column", md: "row" }}
                        mt={4}
                        spacing={"8.13rem"}
                        mb={8}
                        gap={"2.12rem"}
                        {...audienceTypeGroup}
                        // w={{ md: "44.12rem" }}
                      >
                        {audienceTypeOptions.map((option, index) => {
                          const radio = getAudienceTypeRadioProps({
                            value: option.audiance_type,
                          });
                          return (
                            <CardRadio
                              key={index}
                              {...radio}
                              isChecked={
                                formik.values.audiance_type ===
                                option.audiance_type
                              }
                            >
                              <Flex
                                flexDirection="row"
                                alignItems="center"
                                gap={2}
                              >
                                {option.icon}
                                <Text
                                  fontWeight="500"
                                  color={Colors.posTextInfo}
                                  textAlign="center"
                                  fontSize={"0.94rem"}
                                >
                                  {option.title}
                                </Text>
                              </Flex>
                              <Text
                                fontWeight={300}
                                mt={"0.63rem"}
                                fontSize={"0.94rem"}
                                color={Colors.posViewTextColor}
                              >
                                {option.body}
                              </Text>
                            </CardRadio>
                          );
                        })}
                      </Flex>
                    </>
                  )}
                {formik.values.audiance_type === "GEN" &&
                  !formik.values.is_order_dropdown && (
                    <>
                      <PosLable
                        label={true}
                        color={Colors.posTextInfo}
                        name={"Generic Coupon Duration"}
                      />
                      <Flex
                        gap={{ base: 3, md: 6 }}
                        mt={4}
                        flexDirection={{ base: "column", md: "row" }}
                      >
                        <PosDatePicker
                          showTopLable={true}
                          labelColor={Colors.posShowFields}
                          inputPlaceholder={"Select Start Date"}
                          value={formik.values.start_date}
                          label={"Start Date"}
                          selectOtherMonths={true}
                          minDate={dateTimeValue}
                          showButtonBar={true}
                          readOnlyInput={true}
                          monthNavigator={true}
                          yearNavigator={true}
                          yearRange={"2000:2030"}
                          showTime={true}
                          handleSelectData={(e) => {
                            effectRun.current = true;
                            if (e.value !== null && e.value !== "") {
                              const selectedStartDate = moment(e.value);
                              const currentEndDate = formik.values.end_date
                                ? moment(formik.values.end_date)
                                : null;
                              formik.setFieldValue(
                                "start_date",
                                selectedStartDate.toDate()
                              );
                              if (
                                currentEndDate &&
                                selectedStartDate.isAfter(currentEndDate)
                              ) {
                                formik.setFieldValue(
                                  "end_date",
                                  selectedStartDate.toDate()
                                );
                              } else if (!currentEndDate) {
                                formik.setFieldValue(
                                  "end_date",
                                  selectedStartDate.toDate()
                                );
                              }
                            } else {
                              formik.setFieldValue("start_date", null);
                            }
                          }}
                          onFocus={() => {
                            formik.setFieldTouched("start_date", true);
                          }}
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.start_date &&
                          formik.errors.start_date ? (
                            <span>{formik.errors.start_date}</span>
                          ) : null}
                        </Text>
                        <PosDatePicker
                          showTopLable={true}
                          labelColor={Colors.posShowFields}
                          inputPlaceholder={"Select End Date"}
                          value={formik.values.end_date}
                          label={"End Date"}
                          selectOtherMonths={true}
                          minDate={
                            null != formik.values.start_date &&
                            "" != formik.values.start_date
                              ? formik.values.start_date
                              : dateTimeValue
                          }
                          showButtonBar={true}
                          readOnlyInput={true}
                          monthNavigator={true}
                          yearNavigator={true}
                          yearRange={"2000:2030"}
                          showTime={true}
                          handleSelectData={(e) => {
                            effectRun.current = true;
                            formik.setFieldValue("end_date", e.value);
                          }}
                          onFocus={() => {
                            formik.setFieldTouched("end_date", true);
                          }}
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.end_date && formik.errors.end_date ? (
                            <span>{formik.errors.end_date}</span>
                          ) : null}
                        </Text>
                      </Flex>
                    </>
                  )}
                {
                  // formik.values.coupon_type === "ord" &&
                  formik.values.audiance_type === "GEN" &&
                    !formik.values.is_order_dropdown && (
                      <>
                        <PosLable
                          label={true}
                          color={Colors.posTextInfo}
                          name={"* Add on Every Transaction Receipts"}
                        />
                        <PosSwitchButton
                          id="is_print_on_ord_rcpt"
                          switchValue={formik.values.is_print_on_ord_rcpt}
                          onChange={formik.handleChange}
                        />
                      </>
                    )
                }
              </Box>
              <Flex
                minWidth="max-content"
                gap="2"
                mt={{ base: "2.15rem", md: "4.15rem" }}
              >
                <Spacer />
                <ButtonGroup>
                  <PosFormButton
                    onClick={() => {
                      props.navigate(Constants.COUPON_LIST, {
                        state: stateData.state,
                      });
                    }}
                    buttonText={"Cancel"}
                    isDisabled={buttonDisable}
                    CancelButton={true}
                  />
                  <PosFormButton
                    isDisabled={
                      formik.values.name.length <= 0 ||
                      formik.errors.name ||
                      formik.errors.is_order_dropdown ||
                      // formik.values.audiance_type == "" ||
                      formik.errors.apply_on_all_prods ||
                      // formik.errors.audiance_type ||
                      formik.errors.min_amount ||
                      formik.errors.discount_type ||
                      formik.errors.discount_value ||
                      formik.errors.coupon_type ||
                      formik.errors.max_qty_per_coupon ||
                      // formik.errors.selectedLoactions ||
                      (formik.values.coupon_type == "prod" &&
                        (null == formik.values.selectedLoactions ||
                          "" == formik.values.selectedLoactions)) ||
                      formik.errors.is_print_on_ord_rcpt ||
                      buttonDisable ||
                      (formik.values.is_order_dropdown == false &&
                        (undefined == formik.values.audiance_type ||
                          null == formik.values.audiance_type ||
                          "" == formik.values.audiance_type)) ||
                      (formik.values.coupon_type == "prod" &&
                        (null == formik.values.max_qty_per_coupon ||
                          "" == formik.values.max_qty_per_coupon ||
                          null == formik.values.selectedLoactions ||
                          "" == formik.values.selectedLoactions)) ||
                      (formik.values.coupon_type == "ord" &&
                        (null == formik.values.min_amount ||
                          "" == formik.values.min_amount))
                        ? true
                        : false
                    }
                    buttonsubmit={
                      formik.values.coupon_type === "prod" ? "Next" : "Save"
                    }
                    // SubmitButton={true}
                    width={"7.37rem"}
                    onClick={() => {
                      if (formik.values.coupon_type === "prod") {
                        setTabIndex(1);
                        // fetchProductLocation();
                        handleProductDetails();
                      } else {
                        if (
                          Number(formik.values.min_amount) <
                            Number(formik.values.discount_value) &&
                          formik.values.discount_type == "amou"
                        ) {
                          addToast({
                            alertStatus: Constants.TOAST_TYPE_WARNING,
                            alertTitle: Constants.COUPON_MODULE,
                            alertDescription: Constants.MIN_MAX_VAL_TOAST,
                          });
                        } else {
                          formik.handleSubmit();
                        }
                      }
                    }}
                  />
                </ButtonGroup>
              </Flex>
            </TabPanel>
            <TabPanel>
              <Box
                borderRadius="0.63rem"
                bg={Colors.posPageDataBackground}
                boxShadow={
                  "0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow
                }
                p="5"
              >
                <PosLable
                  label={true}
                  color={Colors.posTextInfo}
                  name={"Apply on all products*"}
                />
                <Text
                  h={"1.44rem"}
                  color={Colors.taxCreateExemptEbt}
                  style={{
                    flexShrink: "0",
                    fontSize: "0.75rem",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                  }}
                >
                  {formik.values.apply_on_all_prods
                    ? "Turn off, to apply coupon on selected products"
                    : "Turn on, to apply coupon on all products"}
                </Text>
                <PosSwitchButton
                  mb={3.5}
                  id="apply_on_all_prods"
                  switchValue={formik.values.apply_on_all_prods}
                  onChange={(value) => {
                    formik.handleChange(value);
                    setProductLocation([]);
                    setViewSelectedProducts([]);
                    setCheckedItems([]);
                  }}
                />
                {!formik.values.apply_on_all_prods &&
                  viewSelectedProducts.length > 0 && (
                    <Text mb={8} color={Colors.taxInfo}>
                      Location: {productLocation.join(", ")}
                    </Text>
                  )}
                {!formik.values.apply_on_all_prods && (
                  <PosFormButton
                    // colorScheme={Colors.posTextInfo}
                    // variant="outline"
                    buttonsubmit={
                      viewSelectedProducts.length === 0
                        ? "Add Products to Coupon"
                        : "Add More Products"
                    }
                    width={"13.37rem"}
                    onClick={handleShowProduct}
                  />
                )}
              </Box>
              {/* <Modal
                size={"4xl"}
                closeOnOverlayClick={false}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
                scrollBehavior="inside"
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader
                    fontSize={"1.5rem"}
                    fontWeight={600}
                    color={Colors.posTextInfo}
                  >
                    Select Products
                  </ModalHeader>
                  <ModalBody>
                    <PosSearch
                      mb={3.5}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onSearch={handleSearchList}
                      handleClear={handleClear}
                    />
                    {loading ? (
                      <PosProgress />
                    ) : totalcount > 0 ? (
                      <Box maxHeight={"31.25rem"} overflow={"auto"}>
                        <PosDataGridTable
                          columns={productColumnNames}
                          rows={modifiedDeactiveData}
                          totalcount={totalcount}
                          columnVisibilityModel={olColumnVisibilityModel}
                          onColumnVisibilityModelChange={(newModel) =>
                            setOlColumnVisibilityModel(newModel)
                          }
                          paginationModel={paginationModel}
                          paginationMode="server"
                          onPaginationModelChange={setPaginationModel}
                          sortModel={sortModel}
                          onSortModelChange={(newSortModel) => {
                            sortRef.current = sortModel;
                            setSortModel(newSortModel);
                          }}
                        />
                      </Box>
                    ) : (
                      <PosNoDataFound title={"There are no Products Data."} />
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <ButtonGroup>
                      <PosFormButton
                        onClick={handleCloseModal}
                        buttonText={"Cancel"}
                        CancelButton={true}
                      />
                      <PosFormButton
                        isDisabled={checkedItems.length == 0}
                        buttonsubmit={"Add Products"}
                        SubmitButton={true}
                        onClick={handleSelectedViewProduct}
                      />
                    </ButtonGroup>
                  </ModalFooter>
                </ModalContent>
              </Modal> */}
              <ThemeProvider theme={Mtheme}>
                <Dialog
                  open={isOpen}
                  // onClose={onClose}
                  fullWidth={true}
                  maxWidth={modalSizes}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                      marginTop: "2.37rem",
                      fontSize: "1.5rem",
                      fontWeight: 600,
                      color: Colors.posTextInfo,
                    }}
                  >
                    {"Select Products"}
                    <ChakraProvider theme={theme}>
                      <Box mt={3.5}>
                        <PosSearch
                          mb={3.5}
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          onSearch={handleSearchList}
                          handleClear={handleClear}
                        />
                      </Box>
                    </ChakraProvider>
                  </DialogTitle>
                  <DialogContent>
                    {loading ? (
                      <PosProgress />
                    ) : totalcount > 0 ? (
                      <PosDataGridTable
                        columns={productColumnNames}
                        rows={modifiedDeactiveData}
                        totalcount={totalcount}
                        columnVisibilityModel={olColumnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                          setOlColumnVisibilityModel(newModel)
                        }
                        sortingMode={Constants.SORTING_AND_PAGINATION_MODE}
                        paginationModel={paginationModel}
                        paginationMode="server"
                        onPaginationModelChange={setPaginationModel}
                        sortModel={sortModel}
                        onSortModelChange={(newSortModel) => {
                          sortRef.current = sortModel;
                          setSortModel(newSortModel);
                        }}
                        slots={{
                          toolbar: GridToolbar,
                        }}
                        fileName={Constants.PRODUCT_CSV_EXPORT}
                      />
                    ) : (
                      <PosNoDataFound
                        title={Constants.COUPON_PRODUCT_NOT_FOUND}
                      />
                    )}
                  </DialogContent>
                  <DialogActions>
                    <ChakraProvider theme={theme}>
                      <Flex minWidth="max-content" gap="2" mt={4} mb={4}>
                        <Spacer />
                        <ButtonGroup>
                          <PosFormButton
                            onClick={handleCloseModal}
                            buttonText={"Cancel"}
                            CancelButton={true}
                          />
                          <PosFormButton
                            isDisabled={checkedItems.length == 0}
                            buttonsubmit={"Add Products"}
                            SubmitButton={true}
                            onClick={handleSelectedViewProduct}
                          />
                        </ButtonGroup>
                      </Flex>
                    </ChakraProvider>
                  </DialogActions>
                </Dialog>
              </ThemeProvider>
              {!formik.values.apply_on_all_prods &&
                viewSelectedProducts.length > 0 && (
                  <Box
                    borderRadius="0.63rem"
                    mt={3}
                    bg={Colors.posPageDataBackground}
                    boxShadow={
                      "0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow
                    }
                    width="100%"
                    maxHeight={"31.25rem"}
                    overflow={"auto"}
                  >
                    <PosTable
                      columnNames={columnProductViewNames}
                      rawdata={modifiedViewData}
                      footerHide={true}
                      singleDeleteButton={true}
                      goSingleDelete={(id) => handleSingleDelete(id)}
                      handleCheckboxChange={handleCheckedItemsChange}
                    />
                  </Box>
                )}
              <Flex
                minWidth="max-content"
                gap="2"
                mt={{ base: "2.15rem", md: "4.15rem" }}
              >
                <Spacer />
                <ButtonGroup>
                  <PosFormButton
                    onClick={() => {
                      props.navigate(Constants.COUPON_LIST, {
                        state: stateData.state,
                      });
                    }}
                    buttonText={"Cancel"}
                    CancelButton={true}
                    isDisabled={buttonDisable}
                  />
                  <PosFormButton
                    isDisabled={
                      // !formik.isValid || !formik.dirty ||
                      formik.values.name.length <= 0 ||
                      formik.errors.name ||
                      formik.errors.is_order_dropdown ||
                      // formik.values.audiance_type == "" ||
                      formik.errors.apply_on_all_prods ||
                      // formik.errors.audiance_type ||
                      formik.errors.min_amount ||
                      formik.errors.discount_type ||
                      formik.errors.discount_value ||
                      formik.errors.coupon_type ||
                      formik.errors.max_qty_per_coupon ||
                      (formik.values.coupon_type == "prod" &&
                        (null == formik.values.selectedLoactions ||
                          "" == formik.values.selectedLoactions)) ||
                      formik.errors.is_print_on_ord_rcpt ||
                      (formik.values.is_order_dropdown == false &&
                        (undefined == formik.values.audiance_type ||
                          null == formik.values.audiance_type ||
                          "" == formik.values.audiance_type)) ||
                      (formik.values.coupon_type == "prod" &&
                        (null == formik.values.max_qty_per_coupon ||
                          "" == formik.values.max_qty_per_coupon ||
                          null == formik.values.selectedLoactions ||
                          "" == formik.values.selectedLoactions)) ||
                      (formik.values.coupon_type == "ord" &&
                        (null == formik.values.min_amount ||
                          "" == formik.values.min_amount)) ||
                      buttonDisable ||
                      (!formik.values.apply_on_all_prods &&
                        viewSelectedProducts.length <= 0)
                        ? true
                        : false
                    }
                    buttonsubmit={"Save"}
                    // SubmitButton={true}
                    width={"7.37rem"}
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                  />
                </ButtonGroup>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
};
export default WithRouter(CouponEdit);
