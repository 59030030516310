import { Box, Flex, Img, Link, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useParams,
} from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import PublicGiftCard from "../../../assets/images/public_gift.png";
import { AppContext, PosMainProgress } from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks/index";
import { RouteWithLayout } from "../../../navigators/RouteWithLayout";
import { getGiftCardData } from "../AuthService";
import Ellipse from "../../../assets/images/Ellipse.svg";
import octoposLogo from "../../../assets/images/cropped_logo.png";
import voriLogo from "../../../assets/images/Black_LOGO_with_Name copy.png";
import QRCode from "react-qr-code";

const PublicGiftCardSms = (props) => {
  const myContext = useContext(AppContext);
  const [giftCardData, setGiftCardData] = useState([]);
  const [giftCardCode, setGiftCardCode] = useState("");
  const [locImgNotFound, setLocImgNotFound] = useState(1);
  const [businessNameNotFound, setBusinessNameNotFound] = useState(1);
  const [businessHref, setBusinessHref] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessImage, setBusinessImage] = useState("");
  const loaderResponse = useLoaderData();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const effectRun = useRef(true);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const queryParams = new URLSearchParams(location.search);
  let obj = useParams();
  obj.key1 = queryParams.key1;
  obj.key2 = queryParams.key2;
  obj.count = 0;

  const handleImgClick = () => {
    myContext.handleLoading(true);
    const subdomain = window.location.host.split(".");

    if (subdomain[1] !== Constants.SANE_POS_DOMAIN) {
      window.open(Constants.VORI_URL, "_blank");
    } else {
      window.open(Constants.SANE_POS_URL, "_blank");
    }
  };

  const getYear = (dateString) => {
    const date = new Date(dateString);
    return date.getFullYear();
  };

  useEffect(() => {
    if (effectRun.current === true) {
      myContext.handleLoading(true);
      fetchData();
      effectRun.current = false;
    }
    myContext.handleLoading(false);
  }, []);

  useEffect(() => {
    myContext.handleLoading(true);
    if (giftCardData) {
      setGiftCardCode(giftCardData?.giftCard?.code);

      if (giftCardData.businessLogo) {
        setLocImgNotFound(0);
      }

      if (giftCardData.businessName) {
        setBusinessNameNotFound(0);
      }
      myContext.handleLoading(false);
    }
  }, [giftCardData]);

  useEffect(() => {
    myContext.handleLoading(true);
    const subdomain = window.location.host.split(".");
    if (subdomain[1] !== Constants.SANE_POS_DOMAIN) {
      setBusinessHref(Constants.VORI_URL);
      setBusinessName(Constants.VORI_FOOTER);
      setBusinessImage(voriLogo);
    } else {
      setBusinessHref(Constants.SANE_POS_URL);
      setBusinessName(Constants.SANE_POS);
      setBusinessImage(octoposLogo);
    }
  }, []);

  const fetchData = async () => {
    myContext.handleLoading(true);
    let data = {
      key1: queryParams.get("key1"),
      key2: queryParams.get("key2"),
      count: 0,
    };
    setLoading(true);
    try {
      getGiftCardData(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setGiftCardData(response.data.data);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setGiftCardData([]);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setGiftCardData([]);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setGiftCardData([]);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  return (
    <Flex
      bg={Colors.loginAuthBackground}
      minHeight={"100vh"}
      flexDirection={"column"}
    >
      <Box
        w={"100%"}
        sx={{
          position: "-webkit-sticky",
          /* Safari */ position: "sticky",
          top: "0",
          zIndex: 999,
        }}
      >
        <Box
          w={"100%"}
          sx={{
            /* Safari */ position: "absolute",
            top: "0",
            zIndex: 99999999,
          }}
        >
          {myContext.loading ? <PosMainProgress /> : null}
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          minH="100vh"
          alignItems="center"
          flexDirection="column"
          background={Colors.loginAuthBackground}
          pb={{ base: "1rem", md: "3rem" }}
          mt="1rem"
        >
          {locImgNotFound === 0 && (
            <Img
              src={giftCardData.businessLogo}
              alt="Logo"
              pt={{ base: "1rem", md: "1rem" }}
              style={{
                cursor: "pointer",
                maxHeight: "11.25rem",
                objectFit: "contain",
                marginBottom: "0.63rem",
                width: "100%",
              }}
            />
          )}
          {locImgNotFound === 1 && businessNameNotFound === 1 && (
            <Img
              src={businessImage}
              alt="No logo"
              style={{
                cursor: "pointer",
                maxHeight: "11.25rem",
                objectFit: "contain",
                marginBottom: "0.63rem",
                width: "15.63rem",
              }}
            />
          )}
          <Text
            fontSize="1.5rem"
            fontStyle="normal"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.01rem"
            mb="5rem"
            pb="4"
          >
            {businessNameNotFound === 0 && locImgNotFound === 0 && (
              <Text>{giftCardData.businessName}</Text>
            )}
            {businessNameNotFound === 0 && locImgNotFound === 1 && (
              <Text>{giftCardData.businessName}</Text>
            )}
          </Text>
          <Box
            borderRadius="1.88rem"
            background={Colors.modifierTable}
            boxShadow="0px 0.25rem 0.25rem 0px #c5c5c540"
            width={{ base: "88%", md: "48.88rem" }}
            height={"auto"}
            flexShrink={0}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            position="relative"
            p={{ base: "2rem", md: "4rem" }}
          >
            <Img
              src={Ellipse}
              alt="Ellipse"
              style={{
                width: "3rem",
                position: "absolute",
                top: "19rem",
                left: "0",
                transform: "translateX(-50%)",
              }}
            />
            <Img
              src={PublicGiftCard}
              alt="Shopping Cart"
              style={{
                width: "7.5rem",
                position: "absolute",
                top: "-5.15rem",
              }}
            />
            <Img
              src={Ellipse}
              alt="Ellipse"
              style={{
                width: "3rem",
                position: "absolute",
                top: "19rem",
                right: "0",
                transform: "translateX(50%)",
              }}
            />
            <Box>
              <Text
                p={{ base: "4rem", md: "4rem" }}
                style={{
                  color: "#D42266",
                  textAlign: "center",
                  fontSize: "1.5rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                }}
              >
                Here'e your eGift card!
              </Text>
              <Text
                style={{
                  color: Colors.couponDropDownLabelColor,
                  textAlign: "center",
                  fontSize: "0.94rem",
                  fontStyle: "normal",
                  fontWeight: 300,
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                }}
              >
                {giftCardData.customGiftCardMessage}
              </Text>
              <Text
                fontSize="1.5rem"
                fontStyle="normal"
                fontWeight={500}
                lineHeight="normal"
                letterSpacing="-0.01rem"
              >
                ${giftCardData?.giftCard?.balance}
              </Text>
              <Text
                mt="0.31rem"
                style={{
                  color: "#010048b3",
                  textAlign: "center",
                  fontSize: "0.94rem",
                  fontStyle: "normal",
                  fontWeight: 300,
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                }}
              >
                Current Balance
              </Text>
              <Text
                mt="1.56rem"
                fontSize="1.5rem"
                fontStyle="normal"
                fontWeight={500}
                lineHeight="normal"
                letterSpacing="-0.01rem"
              >
                Gift card code
              </Text>
              <Text
                mt="0.31rem"
                mb="1.56rem"
                fontSize="0.94rem"
                fontStyle="normal"
                fontWeight={300}
                lineHeight="normal"
                letterSpacing="-0.01rem"
              >
                Provide the gift card code at checkout to redeem the gift card.
              </Text>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
              >
                {giftCardCode && (
                  <QRCode
                    value={giftCardCode}
                    size={130}
                    ecLevel="H"
                    logoImage=""
                    logoWidth={50}
                    logoHeight={50}
                  />
                )}
                {/* <Img
              src={giftCardData?.giftCard?.gift_card_image_url}
              alt="Barcode"
              style={{ width: "7.5rem", height: "7.5rem" }}
            /> */}
                <Flex alignItems="center" justifyContent="center" mt="1.56rem">
                  <Text placeholder="code" w={300} textAlign="center">
                    {giftCardCode}
                  </Text>
                </Flex>
              </Box>
              <Text
                mt="1.56rem"
                style={{
                  fontSize: "0.94rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                }}
              >
                For use at any BusinessName location. No cash value, Gift card
                value does not expire.
              </Text>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "1.5rem",
                }}
              >
                <Img
                  src={giftCardData.socialImage}
                  alt="Linked"
                  onClick={handleImgClick}
                  style={{ width: "1.5rem", height: "1.5rem" }}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "1rem",
                  fontSize: "0.75rem",
                  fontStyle: "normal",
                  fontWeight: "300",
                  lineHeight: "normal",
                }}
              >
                <Text
                  mb="1.5rem"
                  style={{
                    fontSize: "0.81rem",
                    fontStyle: "normal",
                    fontWeight: 300,
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                  }}
                >
                  Copyright © {getYear(giftCardData?.giftCard?.updated_at)}{" "}
                  <Link
                    target="_blank"
                    color={Colors.loginFooter}
                    href={businessHref}
                  >
                    {businessName},{" "}
                  </Link>
                  All rights reserved
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default RouteWithLayout(PublicGiftCardSms);
