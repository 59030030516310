import { Box, Flex, Select, Spacer, Stack, Text } from "@chakra-ui/react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit,useLocation } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosFormButton,
  PosInput,
  PosLable,
  PosTostMessage,
  PosDropDown
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";

const MasterAdminPosStationDuplicate = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const effectRun = useRef(true);
  const actionRun = useRef(false);
  const [locations, setLocations] = useState([]);
  const [allValue, setSettings] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedPosName, setSelectedPosName] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [tipsArray, setTipsData] = useState(true);
  const stateData = useLocation(); // get param data
  const [paramData, setParamData] = useState(stateData.state); // store in state

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          // set success and error part as per all screen refer tax module
          myContext.handleLoading(false);
          let locationList = [];
          loaderResponse.data?.data?.location.map((data) => {
            locationList.push(data);
          });
          locationList.map((dt) => {
            dt.pos_station.map((pos) => {
              if (pos.id == loaderResponse.data.data?.pos_station_id) {
                setSelectedLocation(dt);
                setSelectedPosName(pos.name);
              }
            });
          });

          const tipsArray = [];
          Object.keys(loaderResponse.data.data?.settings).map((key) => {
            loaderResponse.data.data?.settings[key].map((f) => {
              f.setting_key = f.key;
              if (f.key == "tip_perc_1" && ("" == f.value || null == f.value)) {
                f.value = "15";
              }
              if (f.key == "tip_perc_2" && ("" == f.value || null == f.value)) {
                f.value = "20";
              }
              if (f.key == "tip_perc_3" && ("" == f.value || null == f.value)) {
                f.value = "25";
              }
              if (
                f.key == "tip_perc_1" ||
                f.key == "tip_perc_2" ||
                f.key == "tip_perc_3"
              ) {
                tipsArray.push({
                  tip: f.value,
                });
              }
            });
          });

          setTipsData(tipsArray);

          const arraySettings = Object.keys(
            loaderResponse.data.data?.settings
          ).map((key) => ({
            category: key,
            category_data: loaderResponse.data.data?.settings[key],
          }));
          setLocations(locationList);
          setSettings(arraySettings);
        }
      }
      // return () => {
        effectRun.current = false;
      // };
    }
    if (actionRun.current === true) {
      if (
        undefined != actionResponse &&
        null != actionResponse &&
        {} != actionResponse
      ) {
        myContext.handleLoading(false);
        setButtonDisable(false);
        if (
          undefined != actionResponse?.error &&
          null != actionResponse?.error &&
          actionResponse?.error
        ) {
          let actionData = actionResponse;
          error({ actionData });
        } else if (
          actionResponse.data[Constants.FLAGE] !== null &&
          actionResponse.data[Constants.FLAGE] === true
        ) {
          props.navigate("/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_POS_STATIONS_PATH,{
            state: paramData,
          });
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.POS_MODULE,
            alertDescription: actionResponse.data.message,
          });
        } else {
          let actionData = actionResponse;
          error({ actionData });
          myContext.handleLoading(false);
        }
      }
      // return () => {
        actionRun.current = false;
      // };
    }
  }, [loaderResponse, actionResponse]);

  const validateField = (value, key, field) => {
    if (field.required == 1 && !value && key == field.key) {
      return `${field.setting_display_name} is required`;
    }
    if (
      field.input_type == 0 &&
      value &&
      value.length > 191 &&
      key == field.key
    ) {
      return `${field.setting_display_name} cannot exceed 191 characters`;
    }
    if (
      field.input_type == 0 &&
      value &&
      (key == "kitchen_printer_ip" || key == "printer_ip")
    ) {
      const re = new RegExp(Constants.IP_REGEX);
      const testResult = re.test(value.trim().toLowerCase());
      if (!testResult) {
        return `Please enter a valid ${field.setting_display_name}.`;
      }
    }
    if (
      field.input_type == 1 &&
      value &&
      (key == "tip_perc_1" || key == "tip_perc_2" || key == "tip_perc_3")
    ) {
      let flag = true;
      let error = "";
      if ("tip_perc_1" in validationErrors) {
        validationErrors["tip_perc_1"] = "";
      }
      if ("tip_perc_2" in validationErrors) {
        validationErrors["tip_perc_2"] = "";
      }
      if ("tip_perc_3" in validationErrors) {
        validationErrors["tip_perc_3"] = "";
      }
      // Function to check if any tip value exists more than one times
      const isValueRepeatedMoreThanTwice = (data) => {
        const valueCounts = {};
        data.forEach((item) => {
          const { tip } = item;
          if (valueCounts[tip]) {
            valueCounts[tip]++;
          } else {
            valueCounts[tip] = 1;
          }
        });

        if (Object.values(valueCounts).some((count) => count > 1)) {
          const findKeyWithValueMoreThanTwo = (obj) => {
            for (const key in obj) {
              if (Object.hasOwnProperty.call(obj, key)) {
                if (obj[key] >= 2) {
                  return key;
                }
              }
            }
            return null;
          };
          const keyWithValueMoreThanTwo =
            findKeyWithValueMoreThanTwo(valueCounts);
          error =
            keyWithValueMoreThanTwo +
            ` Percentage Tip is already selected. Please select different Tip Percentage.`;
          return true;
        }
      };

      const isRepeatedMoreThanTwice = isValueRepeatedMoreThanTwice(tipsArray);
      const failed = allValue.map((fl) => {
        let data = fl.category_data.map((vl) => {
          if (
            (vl.key == "tip_perc_2" || vl.key == "tip_perc_3") &&
            key == "tip_perc_1" &&
            value == vl.value &&
            flag
          ) {
            flag = false;
            error =
              value +
              ` Percentage Tip is already selected. Please select different Tip Percentage.`;
            return true;
          }
          if (
            (vl.key == "tip_perc_1" || vl.key == "tip_perc_3") &&
            key == "tip_perc_2" &&
            value == vl.value &&
            flag
          ) {
            flag = false;
            error =
              value +
              ` Percentage Tip is already selected. Please select different Tip Percentage.`;
            return true;
          }
          if (
            (vl.key == "tip_perc_1" || vl.key == "tip_perc_2") &&
            key == "tip_perc_3" &&
            value == vl.value &&
            flag
          ) {
            flag = false;
            error =
              value +
              ` Percentage Tip is already selected. Please select different Tip Percentage.`;
            return true;
          }
        });
        return data;
      });
      return error;
    }
    return "";
  };

  const handleSelectedLocation = (value) => {
    setSelectedLocation(value);
    formik.setFieldValue("location_id",value);
  };
  const handleNameChange = (value) => {
    setSelectedPosName(value);
  };

  const handleSettingChange = (i, key, value) => {
    const allValueUpdated = [...allValue];
    const tipsArrayUpdated = [];
    allValue.map((fl) => {
      fl.category_data.map((vl, ind) => {
        if (i == ind && key == vl.key) {
          vl.value = value;
        }
        if (
          vl.key == "tip_perc_1" ||
          vl.key == "tip_perc_2" ||
          vl.key == "tip_perc_3"
        ) {
          tipsArrayUpdated.push({
            tip: vl.value,
          });
        }
      });
    });
    setSettings(allValueUpdated);
    setTipsData(tipsArrayUpdated);
  };

  const handleSettingBlur = (key, field) => {
    const error = validateField(field.value, key, field);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [key]: error,
    }));
  };

  const formik = useFormik({
    initialValues: {
      name:
        undefined != selectedPosName && null != selectedPosName
          ? selectedPosName
          : "",
      location_id:
        selectedLocation && null != selectedLocation ? selectedLocation : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .required(Constants.POS_NAME_REQUIRED)
        .max(191, Constants.POS_NAME_LESS_OR_EQUAL_191_CHARACTER),
      // location_id: Yup.string()
      //   .required(Constants.POS_LOCATION_REQUIRED),
    }),
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      const payload = {
        ...values,
        location_id: selectedLocation.id,
        allValue: JSON.stringify(allValue),
      };
      setButtonDisable(true);
      actionRun.current = true;
      submit(payload, {
        method: Constants.POST,
        path: Constants.POS_SETTING_DUPLICATE_PERMISSION,
      });
    },
  });
  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(
        selectedPosName <= 0 ||
        selectedLocation == null ||
        selectedLocation == "" ||
        selectedLocation.length <= 0 ||
        Object.values(validationErrors).some((error) => !!error) ||
        buttonDisable
      )
    ) {
      formik.handleSubmit();
    }
  };
  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1" onKeyDown={handleKeyDown}>
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          /* pl={"1.88rem"} */
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={2}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              props.navigate("/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_POS_STATIONS_PATH,{
                state: stateData.state,
              });
            }}
            breadCrumNames={["POS Stations", "Duplicate POS Station"]}
            breadCrumTitle={"Duplicate POS Station"}
          />
          <Spacer />
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              onClick={() => {
                props.navigate("/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_POS_STATIONS_PATH,{
                  state: stateData.state,
                });
              }}
              buttonText={"Cancel"}
              CancelButton={true}
              isDisabled={buttonDisable}
            />
            <PosFormButton
              isDisabled={
                formik.values.name.length <= 0 ||
                formik.errors.name ||                
                selectedLocation == null ||
                selectedLocation == "" ||
                Object.values(validationErrors).some((error) => !!error) ||
                buttonDisable
                  ? true
                  : false
              }
              buttonsubmit={"Submit"}
              SubmitButton={true}
              onClick={formik.handleSubmit}
            />
          </Stack>
        </Flex>
        <Box
          mt={{ base: 4, lg: 12 }}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          p="8"
        >
          <Text
            fontSize="1.5rem"
            color={Colors.taxShow}
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "-0.27px",
            }}
          >
            Basic Details
          </Text>
          <Stack spacing={3} mt="0.31rem">
            <PosLable fontWeight={"500"} requiredLabel={true} label={true} name={"POS Name"} mt="0.31rem" />
            <PosInput
              posInputGroup={true}
              id={"name"}
              name={`name`}
              inputType={"text"}
              placeholder={"Enter POS Station Name"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.name}
              handleBlur={formik.handleBlur}
              inputError={formik.touched.name && formik.errors.name}
            >
              <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
            </PosInput>
            <Text color={Colors.errorColor}>
              {formik.touched.name && formik.errors.name ? (
                <span>{formik.errors.name}</span>
              ) : null}
            </Text>
          </Stack>
          <Stack spacing={3}>
            <Box className="card flex justify-content-center">
              <PosLable requiredLabel={true} fontWeight={"500"} name={"Location"} label={true}></PosLable>
              <PosDropDown
                id="location_id" 
                options={locations}
                value={selectedLocation}
                onChange={(e) => {
                  handleSelectedLocation(e.target.value);
                }}                
                  onBlur={(e) => {
                  formik.setFieldTouched("location_id", true);
                  formik.handleBlur(e);
                }}
                //   multiSelect={true}
                optionLabel="name"
                placeholder="Select Location"
                width={"100%"}
                //   height={"2.5rem"}
                className="w-full "
                maxSelectedLabels={2}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.location_id && formik.errors.location_id ? (
                  <span>{formik.errors.location_id}</span>
                ) : null}
              </Text>
              {/* {locations.length > 0 ? (
                <Select
                  value={formik.values.location_id}
                  color="#010048"
                  id="location_id"
                  onChange={(e) => {
                    handleSelectedLocation(e.target.value);
                  }}
                >
                  <option selected disabled value="">
                    --Select a Location--
                  </option>
                  {locations.map((data) => (
                    <option value={data.id}>{data.name}</option>
                  ))}
                </Select>
              ) : (
                <Select id="state">
                  <option selected hidden disabled value={"N.A."}>
                    {"N.A."}
                  </option>
                </Select>
              )} */}
            </Box>
          </Stack>
        </Box>

        {allValue.map((data, index1) => (
          <Box
            key={index1} // Ensure each `data` object has a unique `id` property
            mt={{ base: 4, lg: 12 }}
            ml={{ base: 4, lg: 14 }}
            mr={{ base: 4, lg: 14 }}
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
            p="8"
          >
            <Text
              fontSize="1.5rem"
              color={Colors.taxShow}
              style={{
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.27px",
              }}
            >
              {data.category}
            </Text>
            {data.category_data.map((fl, index) => (
              <React.Fragment key={index}>
                {fl.input_type === 0 ? (
                  <Stack key={index} spacing={3} mt="0.31rem">
                    <PosLable
                      fontWeight={"500"}
                      label={true}
                      name={fl.setting_display_name}
                      mt="0.31rem"
                    />
                    <PosInput
                      id={fl.key}
                      placeholder={fl.setting_display_name}
                      handleInputChange={(e) => {
                        handleSettingChange(index, fl.key, e.target.value);
                      }}
                      handleBlur={() => handleSettingBlur(fl.key, fl)}
                      inputValue={fl.value}
                      posInput={true}
                      inputType={"text"}
                      // inputError={formik.touched.name && formik.errors.name}
                    />
                    <Text color={Colors.errorColor}>
                      {validationErrors[fl.key]}
                    </Text>
                  </Stack>
                ) : (
                  <Stack key={index} spacing={3}>
                    <Box className="card flex justify-content-center">
                      <PosLable
                        fontWeight={"500"}
                        name={fl.setting_display_name}
                        label={true}
                      ></PosLable>
                      {fl.options.length > 0 ? (
                        <Select
                          id={fl.key}
                          color="#010048"
                          value={fl.value}
                          onChange={(e) => {
                            handleSettingChange(index, fl.key, e.target.value);
                          }}
                          onClick={() => {
                            handleSettingBlur(fl.key, fl);
                          }}
                        >
                          <option hidden disabled value="">
                            Select an Option
                          </option>
                          {fl.options.map((data, i) => (
                            <option key={i} value={data.value}>
                              {data.label}
                            </option>
                          ))}
                        </Select>
                      ) : (
                        <Select id={fl.key}>
                          <option hidden disabled value="">
                            {"N.A."}
                          </option>
                        </Select>
                      )}
                    </Box>
                    <Text color={Colors.errorColor}>
                      {validationErrors[fl.key]}
                    </Text>
                  </Stack>
                )}
              </React.Fragment>
            ))}
          </Box>
        ))}
      </Box>
    </Flex>
  );
};

export default WithRouter(MasterAdminPosStationDuplicate);
