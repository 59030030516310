import React, { useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider, createHashRouter } from "react-router-dom";
import "./App.css";
import { AppContext } from "./components/index";
import * as Constants from "./constants/Constants";
import { MainLayout, MasterAdminLayout } from "./layouts";
import {
  forgotPassword,
  getUserAllData,
  masterAdminLoginIn,
  resetPassword,
  userSignIn,getUserToken,getMasterAdminToken,
  publicRewardCardUpdateData,publicUpdateRewardCardData,
  publicRewardCardCreateData, publicCreateRewardCardData
} from "./pages/auth/AuthService";
import {
  ForgotPassword,
  Logout,
  MasterAdminSignIn,
  PublicCampaign,
  PublicGiftCardSms,
  ResetPassword,
  SignIn,
  // DountimeDetector,
  PublicRewardCardCreate,
  PublicRewardCardUpdate,
  PublicSms,
  DailyLowInventoryProducts,
  LowInventoryProducts,
  PublicCampaignUnsubscribe,
  PublicRewardCardMobileSmsPrivacyPolicy,
  PublicRewardCardTermsServices
} from "./pages/auth/index";
import {
  getCurrentLoginUser,
  updateBillingSettings
} from "./pages/core/billing/BillingService";
import {
  campaignCreate,
  campaignDeactiveReactive,
  checkCampaignEdit,
  getCampaignEditData,
  getCampaignRelatedDetails
} from "./pages/core/campaign/CampaignService";
import {
  categoryCreate,
  categoryDeactiveReactive,
  categoryUpdate,
  getSingleCategory,
  getGlobleRoutePermissionCategory
} from "./pages/core/category/CategoryService";
import {
  couponCreate,
  couponDeactiveReactive,
  couponUpdate,
  getSingleCoupon,
  getSingleCouponForEdit,
  getAllLocationsForCreate
} from "./pages/core/coupon/CouponService";
import {
  addCustomerCredit,
  customerCreate,
  CustomerDeactiveReactive,
  customerEdit,
  getCustomerDetailsForView,
  getSingleCustomer
} from "./pages/core/customer/CustomerService";
import { getAllLocationForDashboard } from "./pages/core/dashboard/DashboardService";
import {
  employeesClockInOut,
  employeesCreate,
  employeesDeactiveReactive,
  employeesUpdate,
  getSingleEmployees,
  getSingleEmployeesForView,
  getAllRoles
} from "./pages/core/employees/EmployeesService";
import { getAllLocationsForRecountReport,getRecountReport } from "./pages/core/report/inventory/recount/RecountService";
import { getAllLocationsForVoidReport,getVoidReport } from "./pages/core/report/operations/void/VoidService";
import {
  getAllAddGiftCardBalance,
  getSingleGiftCrads,
  GiftCardDeactiveReactive,
} from "./pages/core/gift-cards/GiftCardService";
import {
  BillingList,
  BillingView,
  BottleDepositRefund,
  CampaignCreate,
  CampaignDashboard,
  CampaignEdit,
  CampaignList,
  CashbackReport,
  CategoryCreate,
  CategoryEdit,
  CategoryList,
  CouponCreate,
  CouponEdit,
  CouponList,
  CouponView,
  CustomerCreate,
  CustomerCreditAccount,
  CustomerCreditLedger,
  CustomerEdit,
  CustomerList,
  CustomerUnusedCredit,
  CustomerView,
  DailyInventoryMovement,
  Dashboard,
  ItemsBySalesReport,
  ItemsByVolumeReport,
  EmployeesCreate,
  EmployeesEdit,
  EmployeesList,
  EmployeesView,
  EmployeesWorkTime,
  EmployeeTipAmount,
  ExtraItem,
  GiftCardList,
  GiftCardView,
  InventorySummary,
  InventoryTrail,
  Shrinkage,
  KdsCreate,
  KdsList,
  LocationCreate,
  LocationEdit,
  LocationList,
  LocationSettingOptions,
  ModifierSetCreate,
  ModifierSetEdit,
  ModifierSetList,
  Recount,
  Void,
  OnlineGiftCardSales,
  OrderList,
  OrderView,
  OutstandingGiftCardLiability,
  PageNotFound,
  PayInCreate, PayInEdit,
  PayInList,
  PayoutCreate,
  PayoutEdit,
  PayoutList,
  PosSettings,
  PosSettingsView,
  PosStationCreate,
  PosStationDuplicate,
  PosStationEdit,
  PosStationList,
  ProductImportExport,
  ProductList,
  ProductNotFoundBarcodes,
  ProfileEdit,
  PromotionCreate,
  PromotionEdit,
  PromotionList,
  PriceBookCreate,
  PriceBookList,
  PriceBookEdit,
  PurchaseOrderCreate, PurchaseOrderEdit,
  PurchaseOrderList,
  RefundWithOrder,
  RefundWithoutOrder,
  OrderDiscount,
  Report,
  RewardCardList,
  RewardCardsCreate,
  RewardCardsEdit,
  RewardCardView,
  RewardCreate,
  RewardEdit,
  RewardList,
  RoleCreate,
  RoleEdit,
  RoleList,
  SalesSummaryForAllLocations,
  SalesSummaryForSingleLocation,
  SegmentCreate,
  SegmentEdit,
  SegmentList,
  SegmentView,
  TareContainerCreate,
  TareContainerEdit,
  TareContainerList,
  Taxcreate,
  Taxedit,
  TaxList,
  TaxReport,
  TillCreate,
  TillEdit,
  TillsList,
  TillView,
  // ProfileEditPassword,
  TotalCategorySales,
  TotalDailySales,
  TotalSalesByDay,
  TotalSalesByPaymentType,
  TotalSalesbyPosStation,
  UnAuthorize,
  UserApiAuthDetails,
  VariableItem,
  VendorCreate,
  VendorEdit,
  VendorList,
  VendorView,
  VerifyEmail,
  WebHookList,
  MarketingDashboard,
  IntegrationList
} from "./pages/core/index";
import { kdsCreate,getAllLocationsForKDS } from "./pages/core/kds/KdsService";
import {
  getAllLocationsCreateData,
  getLocationEditData,
  locationBasicCreate,
  locationBasicUpdate,
  locationDeactiveReactive,
} from "./pages/core/locations/LocationService";
import {
  getSinglemodifier,
  modifierSetCreate,
  modifierSetDeactiveReactive,
  modifierSetEdit,
  getGlobleRoutePermissions
} from "./pages/core/modifier-set/ModifierSetService";
import { getSingleOrder } from "./pages/core/order/OrderService";
import {
  duplicatePosSettings,
  getAllLocationsForPOS,
  getPosSettings,
  getPosSettingsView,
  getPosStation,
  posCreate,
  posDeactiveReactive,
  posUpdate,
  updatePosSettings,
  getAllLocationsForPOSList,
} from "./pages/core/pos-station/PosService";
import {
  getAllLocation,
  getLocationsAndCategories,
  productDeactiveReactive,
} from "./pages/core/product/ProductService";
import {
  getProfileDetails,
  profileEdit,
} from "./pages/core/profile/ProfileService";
import {
  getAllLocationsForPromotion,
  getSinglePromotion,
  promoCreate,
  promotionDeactiveReactive,
  promotionUpdate,
} from "./pages/core/promotions/PromotionService";
import {
  getAllLocationsForPriceBook,
  priceBookCreate,
  priceBookUpdate,
  getGloblesRoutePermissions,
  priceBookDeactiveReactive,
  getAllPriceBookDetailsForEdit
} from "./pages/core/price-book/PriceBookService";
import { customerPurchaseOrder, getSinglePurchaseOrder, getVendorLocationList, getVendors, purchaseOrderDeactiveReactive, purchaseOrderEdit } from "./pages/core/purchase-order/PurchaseOrderService";
import { getCashbackData,getAllLocationsForCashbackReport } from "./pages/core/report/cashback/CashbackReportService";
import {
  getAllLocationsAndAllCustomers,
  getCustomerCreditAndExportReport,
} from "./pages/core/report/customer/customer-credit-account/CustomerCreditAccountService";
import {
  getCommonDatas,
  getCustomerUnusedCreditDetail,
} from "./pages/core/report/customer/customer-credit-ledger/CustomerCreditLedgerService";
import {
  getCommonData,
  getCustomerUnusedCreditDetails,
} from "./pages/core/report/customer/customer-unused-credit/CustomerUnusedCreditService";
import {
  getExportEmployeesTipsReportData,
  getLocationEmployeeData,
} from "./pages/core/report/employee/employee-tip-amount/EmployeeTipAmountService";
import { getEmployeesWorkTimeData,getAllLocationsForEmpWorkTimeReport } from "./pages/core/report/employee/employees-work-time/EmployeesWorkTimeService";
import { dailyInventoryReportAndExport,getAllLocationsForDailyInventoryMoveReport } from "./pages/core/report/inventory/daily-inventory-movement/DailyInventoryMovementService";
import {
  getAllLocationsAndVendors,
  inventorySummaryReportAndExport,
} from "./pages/core/report/inventory/inventory-summary/InventorySummaryService";
import { getLocationsAndCategoriesForInventoryTrail,getInventoryTrailData } from "./pages/core/report/inventory/inventory-trail/InventoryTrailService";
import { getLocationsForShrinkageReport,getShrinkageReportData } from "./pages/core/report/inventory/shrinkage/ShrinkageService";
import {
  getLocationes,
  getProductNotFoundBarcodeDetails,
} from "./pages/core/report/inventory/product-not-found-barcodes/ProductNotFoundBarcodesService";
import {
  getBottleDepositRefund,
  getLocation,
} from "./pages/core/report/operations/bottle-deposit-refund/BottleDepositRefundService";
import { extraItemReportAndExport,getAllLocationsForExtraItemReport } from "./pages/core/report/operations/extraitem/ExtraItemService";
import { createPayIn, getAllPosStations, getData, getEditPayInById, PayInDeactiveReactive, updatePayIn } from "./pages/core/report/operations/pay-in/PayInService";
import {
  createPayout,
  getDataAllpos,
  getdatapos,
  getPayoutById,
  payoutDeactiveReactive,
  payoutEdit
} from "./pages/core/report/operations/pay-out/PayoutService";
import { refundWithOrderReportAndExport,getAllLocationsRefundWithOrderReport } from "./pages/core/report/operations/refund-with-order/RefundWithOrderService";
import {
  getLocationses,
  getRefundWithoutOrder,
} from "./pages/core/report/operations/refund-without-order/RefundWithoutOrderService";
import {
  getLocationsForOrderDiscount,
  orderDiscountReportAndExport
} from "./pages/core/report/operations/order-discount/OrderDiscountService";
import {
  createTill,
  getAllCreatePageData,
  getAllPosStation,
  getTillsById,
  showTillsById,
  tillDeactiveReactive,
  tillEdit
} from "./pages/core/report/operations/tills/TillsService";
import { variableItemReportAndExport,getAllLocationsVariableItemReport } from "./pages/core/report/operations/variableitem/VariableItemService";
import {
  checkPermissionGiftCards,
  onlineGiftCardSalesService,
} from "./pages/core/report/sales/online-gift-card-sales/OnlineGiftCardSalesService";
import {
  checkPermissionGiftCard,
  outstandingGiftCardLiability,
} from "./pages/core/report/sales/outstanding-gift-card-liability/OutstandingGiftCardLiabilityService";
import { SalesSummaryForAllLocationsData,getSalesSummaryForAllLocationPermission } from "./pages/core/report/sales/sales-summary-for-all-locations/SalesSummaryForAllLocationService";
import { SalesSummaryForSingleLocationsData,getAllLocationsForSingleLocationSummaryReport } from "./pages/core/report/sales/sales-summary-for-single-location/SalesSummaryForSingleLocationService";
import { getTaxesReportData,getAllLocationsForTaxReport } from "./pages/core/report/sales/tax-report/TaxReportService";
import {
  getLocationsAndCategoryes,
  totalCategorySales,
} from "./pages/core/report/sales/total-category-sales/TotalCategorySalesService";
import {
  getAllLocationsForTotalDailySales,
  totalDailySales,
} from "./pages/core/report/sales/total-daily-sales/TotalDailySalesService";
import {totalSalesByDay, getAllLocationsForTotalSalesByDay } from "./pages/core/report/sales/total-sales-by-day/TotalSalesByDayService"
import {
  getAllLocationsAndEmp,
  getTotalSalesByPaymentTypeData,
} from "./pages/core/report/sales/total-sales-by-payment-type/TotalSalesByPaymentTypeService";
import {
  getLocations,
  totalSalesByPosStation,
} from "./pages/core/report/sales/total-sales-by-pos-station/TotalSalesbyPosStationService";
import {
  getSingleRewardCrads,
  getSingleRewardCradsForView,
  rewardCardDeactiveReactive,
  rewardCardsCreate,
  rewardCardUpdate,
  getRewardCardCreatePermission
} from "./pages/core/reward-cards/RewardCardService";
import {
  getReward,
  getSingleReward,
  rewardCreate,
  rewardDeactiveReactive,
  rewardEdit,
  getGlobleRoutePermissiones
} from "./pages/core/rewards/RewardService";
import {
  getAllPermissions,
  getEditRoleById,
  roleCreate,
  roleDeactiveReactive,
  roleUpdate,
} from "./pages/core/role/RoleService";
import {
  getSegmentTypes,
  segmentDeactiveReactive,
  showSegmentForEdit,
  showSegmentForView
} from "./pages/core/segment/SegmentService";
import {
  getSingleTareContainer,
  tareContainerCreate,
  tareContainerDeactiveReactive, 
  tareContainerUpdate,
  getTareContainerCreatePermission
} from "./pages/core/tare-container/TareContainerService";
import {
  getGlobleRoutePermission,
  getSingleTax,
  taxCreate,
  taxDeactiveReactive,
  taxUpdate,
} from "./pages/core/tax/TaxService";
import {
  apiTokenGenerateRevoke,
  getApiData,
} from "./pages/core/user-api-auth-details/UserApiAuthService";
import {
  getVendor,
  vendorCreate,
  vendorDeactiveReactive,
  vendorUpdate,
  getVendorForView
} from "./pages/core/vendor/VendorService";
import {
  getVerifyEmailUser,
  verifyEmailUpdateSubmit,
} from "./pages/core/verify-email/VerifyEmailService";
import { webHookDelete } from "./pages/core/webhooks/WebHookService";
import {
  MasterAdminGlobalSettingCreate,
  MasterAdminGlobalSettingEdit,
  MasterAdminGlobalSettingList,
  MasterAdminHomePage,
  MasterAdminLocationCreate,
  MasterAdminLocationEdit,
  MasterAdminLocationList,
  MasterAdminLocationSettingOptions,
  MasterAdminPosSettingsView,
  MasterAdminPosStationCreate,
  MasterAdminPosStationDuplicate,
  MasterAdminPosStationEdit,
  MasterAdminPosStationList,
  MasterAdminRoleCreate,
  MasterAdminRoleEdit,
  MasterAdminRoleList
} from "./pages/master-admin/index";
import {
  getGlobalSettingsById,
  getGlobalSettingsCategory,
  globalSettingCreateMasterAdmin,
  globalSettingDeactiveReactiveMasterAdmin,
  globalSettingUpdateMasterAdmin
} from "./pages/master-admin/master-admin-global-settings/MasterAdminGlobalSettingService";
import {
  getAllLocationsCreateDataMasterAdmin,
  getLocationEditDataMasterAdmin,
  locationBasicCreateMasterAdmin,
  locationBasicUpdateMasterAdmin,
  locationDeactiveReactiveMasterAdmin,
} from "./pages/master-admin/master-admin-locations/MasterAdminLocationService";
import {
  duplicatePosSettingsMasterAdmin,
  getAllLocationsForPOSMasterAdmin,
  getAllLocationsForPOSMasterAdminList,
  getPosSettingsMasterAdmin,
  getPosSettingsViewMasterAdmin,
  getPosStationMasterAdmin,
  posCreateMasterAdmin,
  posDeactiveReactiveMasterAdmin,
  posUpdateMasterAdmin,
  updatePosSettingsMasterAdmin,
} from "./pages/master-admin/master-admin-pos-station/MasterAdminPosService";
import {
  getAllPermissionsMasterAdmin,
  getEditRoleByIdMasterAdmin,
  roleCreateMasterAdmin,
  roleDeactiveReactiveMasterAdmin,
  roleUpdateMasterAdmin,
} from "./pages/master-admin/master-admin-role/MasterAdminRoleService";
import Welcome from "./pages/core/dashboard/Welcome";
import { getAllLocationsForOrderList } from "./pages/core/order/OrderService";
const router = createHashRouter([
  {
    element: <SignIn />,
    path: Constants.SIGNIN_PATH,
    loader: getUserToken,
    shouldRevalidate: () => false,
    action: userSignIn,
  },
  // Backdoor Login Super Admin Path
  {
    element: <SignIn />,
    path: Constants.LOGIN_PATH,
    loader: getUserToken,
    shouldRevalidate: () => false,
    action: userSignIn,
  },
  {
    element: <PublicGiftCardSms />,
    path: Constants.PUBLIC_GIFT_CARD_PAGE_PATH,
  },
  {
    element: <PublicSms />,
    path: Constants.PUBLIC_SMS_RECEIPT,
  },
  // {
  //   element: <DountimeDetector />,
  //   path: Constants.PUBLIC_DOUNTIME_DETECTOR_PAGE_PATH,
  // },
  {
    element: <DailyLowInventoryProducts />,
    path: Constants.PUBLIC_DOWNLOAD_DAILY_LOW_INVENTORY_PRODUCTS_ROUTE,
  },
  {
    element: <LowInventoryProducts />,
    path: Constants.PUBLIC_DOWNLOAD_LOW_INVENTORY_PRODUCTS_ROUTE,
  },
  {
    element: <PublicRewardCardCreate />,
    path: Constants.PUBLIC_REWARD_CARD_CREATE_PAGE_PATH,
    loader: publicRewardCardCreateData,
    action: publicCreateRewardCardData,
  },
  {
    element: <PublicRewardCardUpdate />,
    path: Constants.PUBLIC_REWARD_CARD_UPDATE_PAGE_PATH,
    action: publicUpdateRewardCardData,
  },
  {
    element: <PublicCampaignUnsubscribe />,
    path: Constants.CAMPAIGN_UNSUBSCRIBE_PUBLIC_PAGE,
  },
  {
    element: <PublicRewardCardMobileSmsPrivacyPolicy />,
    path: Constants.PUBLIC_REWARD_CARD_MOBILE_SMS_PRIVACY_POLICY,
    loader: publicRewardCardCreateData,
  },
  {
    element: <  PublicRewardCardTermsServices />,
    path: Constants.PUBLIC_REWARD_CARD_TERMS_SERVICES,
    loader: publicRewardCardCreateData,
  },
  {
    element: <Logout />,
    path: "/logout",
  },
  {
    element: <MasterAdminSignIn />,
    path: "/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE,
    loader: getMasterAdminToken,
    shouldRevalidate: () => false,
    action: masterAdminLoginIn,
  },
  {
    path: "/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE,
    element: <MasterAdminLayout />,
    children: [
      {
        element: <MasterAdminHomePage />,
        path: Constants.MASTER_ADMIN_WELCOME_PATH.slice(1),
      },
      {
        element: <MasterAdminLocationList />,
        path: Constants.MASTER_ADMIN_LOCATIONS_PATH.slice(1),
        shouldRevalidate: () => false,
        action: locationDeactiveReactiveMasterAdmin, // change as per location
      },
      {
        element: <MasterAdminLocationCreate />,
        path: Constants.MASTER_ADMIN_LOCATION_CREATE.slice(1),
        loader: getAllLocationsCreateDataMasterAdmin,
        shouldRevalidate: () => false,
        action: locationBasicCreateMasterAdmin,
      },
      {
        element: <MasterAdminLocationSettingOptions />,
        path: Constants.MASTER_ADMIN_LOCATION_SETTING_OPTIONS_PAGE.slice(1) + Constants.API_ID,
        action: MasterAdminLocationSettingOptions,
      },
      {
        element: <MasterAdminLocationEdit />,
        path: Constants.MASTER_ADMIN_LOCATION_EDIT.slice(1) + Constants.API_ID,
        loader: getLocationEditDataMasterAdmin,
        shouldRevalidate: () => false,
        action: locationBasicUpdateMasterAdmin,
      },
      {
        element: <MasterAdminPosStationList />,
        path: Constants.MASTER_ADMIN_POS_STATIONS_PATH.slice(1),
        loader: getAllLocationsForPOSMasterAdminList,
        shouldRevalidate: () => false,
        action: posDeactiveReactiveMasterAdmin,
      },
      {
        element: <MasterAdminPosStationCreate />,
        path: Constants.MASTER_ADMIN_POS_CREATE.slice(1),
        loader: getAllLocationsForPOSMasterAdmin,
        shouldRevalidate: () => false,
        action: posCreateMasterAdmin,
      },
      {
        element: <MasterAdminPosStationEdit />,
        path: Constants.MASTER_ADMIN_POS_EDIT.slice(1) + Constants.API_ID,
        loader: getPosStationMasterAdmin,
        shouldRevalidate: () => false,
        action: posUpdateMasterAdmin,
      },
      {
        element: <MasterAdminPosSettingsView />,
        path: Constants.MASTER_ADMIN_POS_VIEW_SETTING.slice(1) + Constants.API_ID,
        loader: getPosSettingsViewMasterAdmin,
        shouldRevalidate: () => false,
        action: updatePosSettingsMasterAdmin,
      },
      {
        element: <MasterAdminPosStationDuplicate />,
        path: Constants.MASTER_ADMIN_POS_SETTING_DUPLICATE.slice(1) + Constants.API_ID,
        loader: getPosSettingsMasterAdmin,
        shouldRevalidate: () => false,
        action: duplicatePosSettingsMasterAdmin,
      },
      {
        element: <MasterAdminRoleList />,
        path: Constants.MASTER_ADMIN_ROLES_PATH.slice(1),
        action: roleDeactiveReactiveMasterAdmin,
      },
      {
        element: <MasterAdminRoleCreate />,
        path: Constants.MASTER_ADMIN_ROLE_CREATE.slice(1),
        loader: getAllPermissionsMasterAdmin,
        shouldRevalidate: () => false,
        action: roleCreateMasterAdmin,
      },
      {
        element: <MasterAdminRoleEdit />,
        path: Constants.MASTER_ADMIN_ROLE_EDIT.slice(1) + Constants.API_ID,
        loader: getEditRoleByIdMasterAdmin,
        shouldRevalidate: () => false,
        action: roleUpdateMasterAdmin,
      },
      {
        element: <MasterAdminGlobalSettingList />,
        path: Constants.MASTER_ADMIN_GLOBLE_SETTINGS_PATH.slice(1),
        action: globalSettingDeactiveReactiveMasterAdmin,
      },
      {
        element: <MasterAdminGlobalSettingCreate />,
        path: Constants.MASTER_ADMIN_GLOBLE_SETTINGS_CREATE_PATH.slice(1),
        loader: getGlobalSettingsCategory,
        shouldRevalidate: () => false,
        action: globalSettingCreateMasterAdmin,
      },
      {
        element: <MasterAdminGlobalSettingEdit />,
        path: Constants.MASTER_ADMIN_GLOBLE_SETTINGS_EDIT_PATH.slice(1) + Constants.API_ID,
        loader: getGlobalSettingsById,
        shouldRevalidate: () => false,
        action: globalSettingUpdateMasterAdmin,
      },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    loader: getUserAllData,
    // errorElement: <UnAuthorize />,
    shouldRevalidate: () => false,
    children: [
      {
        element: <Dashboard />,
        path: Constants.DASHBOARD_PATH.slice(1),
        loader: getAllLocationForDashboard,
      },
      {
        element: <Welcome />,
        path: Constants.WELCOME_PATH.slice(1)
      },
      {
        element: <ItemsBySalesReport />,
        path: Constants.TOP_SALE_REPORT.slice(1),
        loader: getAllLocationForDashboard,
      },
      {
        element: <ItemsByVolumeReport />,
        path: Constants.TOP_VOLUME_REPORT.slice(1),
        loader: getAllLocationForDashboard,
      },
      {
        element: <TaxList />,
        path: Constants.TAX_LIST.slice(1),
        action: taxDeactiveReactive,
      },
      {
        element: <Taxcreate />,
        path: Constants.TAX_CREATE.slice(1),
        loader: getGlobleRoutePermission,
        shouldRevalidate: () => false,
        action: taxCreate,
      },
      {
        element: <Taxedit />,
        path: Constants.TAX_EDIT.slice(1) + Constants.API_ID,
        loader: getSingleTax,
        shouldRevalidate: () => false,
        action: taxUpdate,
      },
      {
        element: <ProductList />,
        path: Constants.PRODUCT_LIST_PATH.slice(1),
        loader: getLocationsAndCategories,
        // loader: getLocationsCategoriesAndTaxs,
        shouldRevalidate: () => false,
        action: productDeactiveReactive,
        // action: createProduct
      },
      {
        element: <ProductImportExport />,
        path: Constants.PRODUCT_IMPORT_EXPORT_PATH.slice(1),
        loader: getAllLocation,
      },
      // {
      //   element: <ProductCreate />,
      //   path: Constants.PRODUCT_CREATE.slice(1),
      //   loader: getLocationsCategoriesAndTaxs,
      //   shouldRevalidate: () => false,
      //   action: createProduct
      // },
      // {
      //   element: <ProductEdit />,
      //   path: Constants.PRODUCT_EDIT.slice(1) + Constants.API_ID,
      //   loader: showProductData,
      //   shouldRevalidate: () => false,
      //   action: updateProduct
      // },
      {
        element: <PriceBookCreate />,
        path: Constants.PRICE_BOOK_CREATE.slice(1),
        loader: getAllLocationsForPriceBook,
        shouldRevalidate: () => false,
        action: priceBookCreate,
      },
      {
        element: <PriceBookList />,
        path: Constants.PRICE_BOOK_LIST_PATH.slice(1),
        loader: getGloblesRoutePermissions,
        shouldRevalidate: () => false,
        action: priceBookDeactiveReactive,
      },
      {
        element: <PriceBookEdit />,
        path: Constants.PRICE_BOOK_EDIT.slice(1) + Constants.API_ID,
        loader: getAllPriceBookDetailsForEdit,
        shouldRevalidate: () => false,
        action: priceBookUpdate,
      },
      {
        element: <RoleList />,
        path: Constants.ROLE_LIST.slice(1),
        action: roleDeactiveReactive,
      },
      {
        element: <RoleCreate />,
        path: Constants.ROLE_CREATE.slice(1),
        loader: getAllPermissions,
        shouldRevalidate: () => false,
        action: roleCreate,
      },
      {
        element: <RoleEdit />,
        path: Constants.ROLE_EDIT.slice(1) + Constants.API_ID,
        loader: getEditRoleById,
        shouldRevalidate: () => false,
        action: roleUpdate,
      },
      {
        element: <EmployeesList />,
        path: Constants.EMPOYEES_LIST_ROUTE,
        action: employeesDeactiveReactive,
      },
      {
        element: <EmployeesEdit />,
        path: Constants.EMPLOYEES_EDIT.slice(1) + Constants.API_ID,
        loader: getSingleEmployees,
        shouldRevalidate: () => false,
        action: employeesUpdate,
      },
      {
        element: <EmployeesView />,
        path: Constants.EMPLOYEES_VIEW.slice(1) + Constants.API_ID,
        loader: getSingleEmployeesForView,
        shouldRevalidate: () => true,
        action: employeesClockInOut,
      },
      {
        element: <EmployeesCreate />,
        path: Constants.EMPLOYEES_CREATE,
        loader: getAllRoles,
        shouldRevalidate: () => false,
        action: employeesCreate,
      },
      {
        element: <RewardCardList />,
        path: Constants.REWARD_CARDS_LIST.slice(1),
        action: rewardCardDeactiveReactive,
      },
      {
        element: <RewardCardsCreate />,
        path: Constants.REWARD_CARDS_CREATE.slice(1),
        loader: getRewardCardCreatePermission,
        shouldRevalidate: () => false,
        action: rewardCardsCreate,
      },
      {
        element: <RewardCardsEdit />,
        path: Constants.REWARD_CARDS_EDIT.slice(1) + Constants.API_ID,
        loader: getSingleRewardCrads,
        shouldRevalidate: () => false,
        action: rewardCardUpdate,
      },
      {
        element: <RewardCardView />,
        path: Constants.REWARD_CARDS_VIEW.slice(1) + Constants.API_ID,
        loader: getSingleRewardCradsForView,
      },
      {
        element: <CouponList />,
        path: Constants.COUPON_LIST.slice(1),
        action: couponDeactiveReactive,
      },
      {
        element: <CouponCreate />,
        path: Constants.COUPON_CREATE.slice(1),
        loader: getAllLocationsForCreate,
        shouldRevalidate: () => false,
        action: couponCreate,
      },
      {
        element: <CouponEdit />,
        path: Constants.COUPON_EDIT.slice(1) + Constants.API_ID,
        loader: getSingleCouponForEdit,
        shouldRevalidate: () => false,
        action: couponUpdate,
      },
      {
        element: <CouponView />,
        path: Constants.COUPON_VIEW.slice(1) + Constants.API_ID,
        loader: getSingleCoupon,
      },
      {
        element: <TareContainerList />,
        path: Constants.TARE_CONTAINER_LIST.slice(1),
        action: tareContainerDeactiveReactive,
      },
      {
        element: <TareContainerCreate />,
        path: Constants.TARE_CONTAINER_CREATE.slice(1),
        loader: getTareContainerCreatePermission,
        shouldRevalidate: () => false,
        action: tareContainerCreate,
      },
      {
        element: <TareContainerEdit />,
        path: Constants.TARE_CONTAINER_EDIT.slice(1) + Constants.API_ID,
        loader: getSingleTareContainer,
        shouldRevalidate: () => false,
        action: tareContainerUpdate,
      },
      {
        element: <CategoryList />,
        path: Constants.CATEGORY_LIST.slice(1),
        action: categoryDeactiveReactive,
      },
      {
        element: <CategoryEdit />,
        path: Constants.CATEGORY_EDIT.slice(1) + Constants.API_ID,
        loader: getSingleCategory,
        shouldRevalidate: () => false,
        action: categoryUpdate,
      },
      {
        element: <CategoryCreate />,
        path: Constants.CATEGORY_CREATE.slice(1),
        loader: getGlobleRoutePermissionCategory,
        shouldRevalidate: () => false,
        action: categoryCreate,
      },
      {
        element: <Report />,
        path: Constants.REPORT_PATH.slice(1),
      },
      {
        element: <OrderList />,
        path: Constants.ORDER_LIST_PATH.slice(1),
        loader: getAllLocationsForOrderList,
      },
      {
        element: <OrderView />,
        path: Constants.ORDER_VIEW_PATH.slice(1) + Constants.API_ID,
        loader: getSingleOrder,
      },
      {
        element: <VendorList />,
        path: Constants.VENDOR_LIST,
        action: vendorDeactiveReactive,
      },
      {
        element: <VendorCreate />,
        path: Constants.VENDOR_CREATE,
        shouldRevalidate: () => false,
        action: vendorCreate,
      },
      {
        element: <VendorEdit />,
        path: Constants.VENDOR_EDIT + Constants.API_ID,
        loader: getVendor,
        shouldRevalidate: () => false,
        action: vendorUpdate,
      },
      {
        element: <VendorView />,
        path: Constants.VENDOR_VIEW + Constants.API_ID,
        loader: getVendorForView,
        shouldRevalidate: () => false,
        action: vendorUpdate,
      },
      {
        element: <OnlineGiftCardSales />,
        path: Constants.ONLINE_GIFT_CARD_SALE.slice(1),
        loader: checkPermissionGiftCards,
        shouldRevalidate: () => false,
        action: onlineGiftCardSalesService,
      },
      {
        element: <ProductNotFoundBarcodes />,
        path: Constants.PRODUCT_NOT_FOUND_BARCODE.slice(1),
        loader: getLocationes,
        shouldRevalidate: () => false,
        action: getProductNotFoundBarcodeDetails,
      },
      {
        element: <RefundWithoutOrder />,
        path: Constants.REFUND_WITHOUT_ORDER.slice(1),
        loader: getLocationses,
        shouldRevalidate: () => false,
        action: getRefundWithoutOrder,
      },
      {
        element: <OrderDiscount />,
        path: Constants.DISCOUNT_REPORT_ROUTE.slice(1),
        loader: getLocationsForOrderDiscount,
        shouldRevalidate: () => false,
        action: orderDiscountReportAndExport,
      },
      {
        element: <BottleDepositRefund />,
        path: Constants.BOTTLE_DEPOSIT_REFUND.slice(1),
        loader: getLocation,
        shouldRevalidate: () => false,
        action: getBottleDepositRefund,
      },
      {
        element: <CustomerUnusedCredit />,
        path: Constants.CUSTOMER_UNUSED_CREDIT_ROUTE.slice(1),
        loader: getCommonData,
        shouldRevalidate: () => false,
        action: getCustomerUnusedCreditDetails,
      },
      {
        element: <CustomerCreditLedger />,
        path: Constants.CUSTOMER_CREDIT_LEDGER_ROUTE.slice(1),
        loader: getCommonDatas,
        shouldRevalidate: () => false,
        action: getCustomerUnusedCreditDetail,
      },
      {
        element: <EmployeeTipAmount />,
        path: Constants.EMPLOYEE_TIPS_AMOUNT_ROUTE.slice(1),
        loader: getLocationEmployeeData,
        shouldRevalidate: () => false,
        action: getExportEmployeesTipsReportData,
      },
      {
        element: <PurchaseOrderList />,
        path: Constants.PURCHASING_ORDER_LIST,
        loader: getVendors,
        shouldRevalidate: () => false,
        action: purchaseOrderDeactiveReactive,
      },
      {
        element: <PurchaseOrderCreate />,
        path: Constants.PURCHASING_ORDER_CREATE,
        action: customerPurchaseOrder,
        shouldRevalidate: () => false,
        loader: getVendorLocationList,
      },
      {
        element: <PurchaseOrderEdit />,
        path: Constants.PURCHASING_ORDER_EDIT + Constants.API_ID,
        loader: getSinglePurchaseOrder,
        shouldRevalidate: () => false,
        action: purchaseOrderEdit,
      },
      {
        element: <ModifierSetCreate />,
        path: Constants.MODIFIER_SET_CREATE,
        loader: getGlobleRoutePermissions,
        shouldRevalidate: () => false,
        action: modifierSetCreate,
      },
      {
        element: <ModifierSetEdit />,
        path: Constants.MODIFIER_SET_EDIT + Constants.API_ID,
        loader: getSinglemodifier,
        shouldRevalidate: () => false,
        action: modifierSetEdit,
      },
      {
        element: <ModifierSetList />,
        path: Constants.MODIFIER_SET_LIST,
        action: modifierSetDeactiveReactive,
      },
      {
        element: <TotalDailySales />,
        path: Constants.TOTAL_DAILY_SALE.slice(1),
        loader: getAllLocationsForTotalDailySales,
        shouldRevalidate: () => false,
        action: totalDailySales,
      },
      {
        element: <TotalSalesByDay />,
        path: Constants.TOTAL_SALE_BY_DAY.slice(1),
        loader: getAllLocationsForTotalSalesByDay,
        shouldRevalidate: () => false,
        action: totalSalesByDay,
      },
      {
        element: <PosStationList />,
        path: Constants.POS_LIST,
        loader: getAllLocationsForPOSList,
        shouldRevalidate: () => false,
        action: posDeactiveReactive,
      },
      {
        element: <PosStationCreate />,
        path: Constants.POS_CREATE,
        loader: getAllLocationsForPOS,
        shouldRevalidate: () => false,
        action: posCreate,
      },
      {
        element: <PosStationEdit />,
        path: Constants.POS_EDIT + Constants.API_ID,
        loader: getPosStation,
        shouldRevalidate: () => false,
        action: posUpdate,
      },
      {
        element: <PosSettings />,
        path: Constants.POS_EDIT_SETTING + Constants.API_ID,
        loader: getPosSettings,
        shouldRevalidate: () => false,
        action: updatePosSettings,
      },
      {
        element: <PosSettingsView />,
        path: Constants.POS_VIEW_SETTING + Constants.API_ID,
        loader: getPosSettingsView,
        shouldRevalidate: () => false,
        action: updatePosSettings,
      },
      {
        element: <PosStationDuplicate />,
        path: Constants.POS_SETTING_DUPLICATE + Constants.API_ID,
        loader: getPosSettings,
        shouldRevalidate: () => false,
        action: duplicatePosSettings,
      },
      {
        element: <MarketingDashboard />,
        path: Constants.MARKETING_DASHBOARD_PATH.slice(1),
      },
      {
        element: <CampaignList />,
        path: Constants.MARKETING_CAMPAIGN_PATH.slice(1),
        action: campaignDeactiveReactive,
      },
      {
        element: <CampaignCreate />,
        path: Constants.CAMPAIGN_CREATE.slice(1),
        loader: getCampaignRelatedDetails,
        shouldRevalidate: () => false,
        action: campaignCreate,
      },
      {
        element: <CampaignEdit />,
        path: Constants.CAMPAIGN_EDIT.slice(1) + Constants.API_ID,
        loader: getCampaignEditData,
        shouldRevalidate: () => false,
      },
      {
        element: <CampaignDashboard />,
        path: Constants.CAMPAIGN_DASHBOARD.slice(1) + Constants.API_ID,
        loader: checkCampaignEdit,
        shouldRevalidate: () => false,
      },
      {
        element: <CustomerList />,
        path: Constants.CUSTOMERS_PATH,
        action: CustomerDeactiveReactive,
      },
      {
        element: <CustomerCreate />,
        path: Constants.CUSTOMERS_CREATE,
        action: customerCreate,
      },
      {
        element: <CustomerEdit />,
        path: Constants.CUSTOMERS_EDIT + Constants.API_ID,
        loader: getSingleCustomer,
        shouldRevalidate: () => false,
        action: customerEdit,
      },
      {
        element: <CustomerView />,
        path: Constants.CUSTOMER_VIEW_PATH.slice(1) + Constants.API_ID,
        action: addCustomerCredit
      },
      {
        element: <GiftCardList />,
        path: Constants.GIFT_CARD_LIST,
        action: GiftCardDeactiveReactive,
      },
      {
        element: <GiftCardView />,
        path: Constants.GIFT_CARD_VIEW.slice(1) + Constants.API_ID,
        loader: getSingleGiftCrads,
        shouldRevalidate: () => true,
        action: getAllAddGiftCardBalance,
      },
      {
        element: <RewardList />,
        path: Constants.REWARD_PATH,
        loader: getGlobleRoutePermissiones,
        shouldRevalidate: () => false,
        action: rewardDeactiveReactive,
      },
      {
        element: <RewardCreate />,
        path: Constants.REWARD_CREATE,
        action: rewardCreate,
        shouldRevalidate: () => false,
        loader: getReward,
      },
      {
        element: <RewardEdit />,
        path: Constants.REWARD_EDIT.slice(1) + Constants.API_ID,
        loader: getSingleReward,
        shouldRevalidate: () => false,
        action: rewardEdit,
      },
      {
        element: <LocationList />,
        path: Constants.ESTABLISHMENT_LOCATION_PATH,
        // loader: getAllLocationsForPOS,
        shouldRevalidate: () => false,
        action: locationDeactiveReactive, // change as per location
      },
      {
        element: <LocationCreate />,
        path: Constants.LOCATION_CREATE,
        loader: getAllLocationsCreateData,
        shouldRevalidate: () => false,
        action: locationBasicCreate,
      },
      {
        element: <LocationSettingOptions />,
        path: Constants.LOCATION_SETTING_OPTIONS_PAGE + Constants.API_ID,
        action: LocationSettingOptions,
      },
      {
        element: <LocationEdit />,
        path: Constants.LOCATION_EDIT + Constants.API_ID,
        loader: getLocationEditData,
        shouldRevalidate: () => false,
        action: locationBasicUpdate,
      },
      {
        element: <ProfileEdit />,
        path: Constants.PROFILE_EDIT.slice(1),
        loader: getProfileDetails,
        shouldRevalidate: () => false,
        action: profileEdit,
      },
      {
        element: <UserApiAuthDetails />,
        path: Constants.INTEGRATION_API_PATH,
        loader: getApiData,
        shouldRevalidate: () => true,
        // shouldRevalidate: () => false,
        action: apiTokenGenerateRevoke, // change as per location
      },
      {
        element: <VerifyEmail />,
        path: Constants.MARKETING_VERIFY_EMAIL_PATH,
        loader: getVerifyEmailUser,
        shouldRevalidate: () => false,
        action: verifyEmailUpdateSubmit,
      },
      {
        element: <SegmentList />,
        path: Constants.SEGMENT_LIST.slice(1),
        // loader: getGlobleRoutePermission,
        // shouldRevalidate: () => false,
        action: segmentDeactiveReactive,
      },
      {
        element: <SegmentCreate />,
        path: Constants.SEGMENT_CREATE.slice(1),
        loader: getSegmentTypes,
        shouldRevalidate: () => false,
        // action: createSegmentRewardCard,
      },
      {
        element: <SegmentEdit />,
        path: Constants.SEGMENT_EDIT + Constants.API_ID,
        loader: showSegmentForEdit,
        shouldRevalidate: () => false,
        // action: updateSegment,
      },
      {
        element: <SegmentView />,
        path: Constants.SEGMENT_VIEW.slice(1) + Constants.API_ID,
        loader: showSegmentForView,
      },
      {
        element: <WebHookList />,
        path: Constants.INTEGRATION_WEBHOOK_PATH,
        action: webHookDelete,
      },
      {
        element: <TotalCategorySales />,
        path: Constants.TOTAL_CATEGORY_SALE.slice(1),
        loader: getLocationsAndCategoryes,
        shouldRevalidate: () => false,
        action: totalCategorySales,
      },
      {
        element: <TotalSalesbyPosStation />,
        path: Constants.TOTAL_SALE_BY_POS_STATION.slice(1),
        loader: getLocations,
        shouldRevalidate: () => false,
        action: totalSalesByPosStation,
      },
      {
        element: <OutstandingGiftCardLiability />,
        path: Constants.OUTSTATING_GIFT_CARD_LIABILITY.slice(1),
        loader: checkPermissionGiftCard,
        shouldRevalidate: () => false,
        action: outstandingGiftCardLiability,
      },
      {
        element: <Recount />,
        path: Constants.RECOUNT_REPORT_ROUTE.slice(1),
        loader: getAllLocationsForRecountReport,
        shouldRevalidate: () => false,
        action: getRecountReport,
      },
      {
        element: <Void />,
        path: Constants.VOID_REPORT_ROUTE.slice(1),
        loader: getAllLocationsForVoidReport,
        shouldRevalidate: () => false,
        action: getVoidReport,
      }, 
      {
        element: <ExtraItem />,
        path: Constants.EXTRA_ITEM_ROUTE.slice(1),
        loader: getAllLocationsForExtraItemReport,
        shouldRevalidate: () => false,
        action: extraItemReportAndExport,
      },
      {
        element: <TillsList />,
        path: Constants.TILL_REPORT_ROUTE.slice(1),
        loader: getAllPosStation,
        shouldRevalidate: () => false,
        action: tillDeactiveReactive,
      },
      {
        element: <TillCreate />,
        path: Constants.TILL_CREATE.slice(1),
        loader: getAllCreatePageData,
        shouldRevalidate: () => false,
        action: createTill,
      },
      {
        element: <TillEdit />,
        path: Constants.TILL_EDIT + Constants.API_ID,
        loader: getTillsById,
        shouldRevalidate: () => false,
        action: tillEdit,
      },
      {
        element: <TillView />,
        path: Constants.TILL_VIEW.slice(1) + Constants.API_ID,
        loader: showTillsById,
        // shouldRevalidate: () => false,
        // action: createTill,
      },
      {
        element: <PayInList />,
        loader: getAllPosStations,
        path: Constants.PAY_IN_REPORT_ROUTE.slice(1),
        action: PayInDeactiveReactive,
      },
      {
        element: <PayInCreate />,
        loader: getData,
        path: Constants.PAY_IN_CREATE_ROUTE.slice(1),
        action: createPayIn,
      },
      {
        element: <PayInEdit />,
        loader: getEditPayInById,
        path: Constants.PAY_IN_EDIT_ROUTE.slice(1) + Constants.API_ID,
        action: updatePayIn,
      },
      {
        element: <VariableItem />,
        path: Constants.VARIABLE_ITEM_ROUTE.slice(1),
        loader: getAllLocationsVariableItemReport,
        shouldRevalidate: () => false,
        action: variableItemReportAndExport,
      },
      {
        element: <SalesSummaryForAllLocations />,
        path: Constants.SALES_SUMMARY_FOR_ALL_LOCATIONS_ROUTE.slice(1),
        loader: getSalesSummaryForAllLocationPermission,
        shouldRevalidate: () => false,
        action: SalesSummaryForAllLocationsData,
      },
      {
        element: <SalesSummaryForSingleLocation />,
        path: Constants.SALES_SUMMARY_FOR_SINGLE_LOCATIONS_ROUTE.slice(1),
        loader: getAllLocationsForSingleLocationSummaryReport,
        shouldRevalidate: () => false,
        action: SalesSummaryForSingleLocationsData,
      },
      {
        element: <RefundWithOrder />,
        path: Constants.REFUND_WITH_ORDER_ROUTE.slice(1),
        loader: getAllLocationsRefundWithOrderReport,
        shouldRevalidate: () => false,
        action: refundWithOrderReportAndExport,
      },
      {
        element: <CustomerCreditAccount />,
        path: Constants.CUSTOMER_CREDIT_ACCOUNT_ROUTE.slice(1),
        loader: getAllLocationsAndAllCustomers,
        shouldRevalidate: () => false,
        action: getCustomerCreditAndExportReport,
      },
      {
        element: <TaxReport />,
        path: Constants.TAXES_REPORT_ROUTE.slice(1),
        loader: getAllLocationsForTaxReport,
        shouldRevalidate: () => false,
        action: getTaxesReportData,
      },
      {
        element: <CashbackReport />,
        path: Constants.CASHBACK_REPORT_ROUTE.slice(1),
        loader: getAllLocationsForCashbackReport,
        shouldRevalidate: () => false,
        action: getCashbackData,
      },
      {
        element: <DailyInventoryMovement />,
        path: Constants.INVENTORY_MOVEMENT_BY_DAY_REPORT_ROUTE.slice(1),
        loader: getAllLocationsForDailyInventoryMoveReport,
        shouldRevalidate: () => false,
        action: dailyInventoryReportAndExport,
      },
      {
        element: <InventorySummary />,
        path: Constants.INVENTORY_SUMMARY_REPORT_ROUTE.slice(1),
        loader: getAllLocationsAndVendors,
        shouldRevalidate: () => false,
        action: inventorySummaryReportAndExport,
      },
      {
        element: <InventoryTrail />,
        path: Constants.INVENTORY_TRAIL_REPORT_ROUTE.slice(1),
        loader: getLocationsAndCategoriesForInventoryTrail,
        shouldRevalidate: () => false,
        action: getInventoryTrailData,
      },
      {
        element: <Shrinkage />,
        path: Constants.SHRINKAGE_REPORT_ROUTE.slice(1),
        loader: getLocationsForShrinkageReport,
        shouldRevalidate: () => false,
        action: getShrinkageReportData,
      },
      {
        element: <TotalSalesByPaymentType />,
        path: Constants.TOTAL_SALES_BY_PAYMENT_TYPE_REPORT_ROUTE.slice(1),
        loader: getAllLocationsAndEmp,
        shouldRevalidate: () => false,
        action: getTotalSalesByPaymentTypeData,
      },
      {
        element: <EmployeesWorkTime />,
        path: Constants.EMPLOYEES_WORK_TIME_REPORT_ROUTE.slice(1),
        loader: getAllLocationsForEmpWorkTimeReport,
        shouldRevalidate: () => false,
        action: getEmployeesWorkTimeData,
      },
      {
        element: <PromotionList />,
        path: Constants.PRODUCT_PROMOTION_PATH.slice(1),
        action: promotionDeactiveReactive,
      },
      {
        element: <PromotionCreate />,
        path: Constants.PROMOTION_CREATE.slice(1),
        loader: getAllLocationsForPromotion,
        shouldRevalidate: () => false,
        action: promoCreate,
      },
      {
        element: <PromotionEdit />,
        path: Constants.PROMOTION_EDIT.slice(1) + Constants.API_ID,
        loader: getSinglePromotion,
        shouldRevalidate: () => false,
        action: promotionUpdate,
      },
      {
        element: <BillingList />,
        path: Constants.BILLINGS_VIEW_DETAILS,
        loader: getCurrentLoginUser,
        action: updateBillingSettings,
        shouldRevalidate: () => false,
      },
      {
        element: <BillingView />,
        path: Constants.VIEW,
        // loader: viewBillingDetails,
        // shouldRevalidate: () => true,
        // loader:getCurrentLoginUser,
        // action: updateBillingSettings,
        // shouldRevalidate: () => false,
      },
      {
        element: <KdsList />,
        path: Constants.KDS_LIST,
      },
      {
        element: <PayoutList />,
        path: Constants.PAY_OUT_REPORT_ROUTE.slice(1),
        loader: getdatapos,
        shouldRevalidate: () => false,
        action: payoutDeactiveReactive,
      },
      {
        element: <PayoutCreate />,
        path: Constants.PAYOUT_CREATE.slice(1),
        loader: getDataAllpos,
        shouldRevalidate: () => false,
        action: createPayout,
      },
      // {
      //   element: <PayoutView />,
      //   path: Constants.PAYOUT_VIEW.slice(1) + Constants.API_ID,
      //   loader:showPayoutById,
      //   // action: createPayout, 
      // },
      {
        element: <PayoutEdit />,
        path: Constants.PAYOUT_EDIT_ROUTE.slice(1) + Constants.API_ID,
        loader: getPayoutById,
        shouldRevalidate: () => false,
        action: payoutEdit,
      },
      {
        element: <KdsCreate />,
        path: Constants.KDS_CREATE,
        loader: getAllLocationsForKDS,
        shouldRevalidate: () => false,
        action: kdsCreate,
      },
      {
        element: <IntegrationList />,
        path: Constants.INTEGRATION_LIST_PATH,
      },
      {
        element: <UnAuthorize />,
        path: "/401",
      },
      {
        element: <PageNotFound />,
        path: "*",
      },
    ],
  },
  {
    element: <ForgotPassword />,
    path: Constants.FORGOT_PASSWORD_PATH,
    action: forgotPassword,
  },
  {
    element: <PublicCampaign />,
    path: Constants.CAMPAIGN_PUBLIC_PAGE_PATH,
  },
  {
    element: <ResetPassword />,
    path: Constants.RESET_PASSWORD_PATH + "/:key",
    // search: '?key=key',
    action: resetPassword,
  },
  {
    element: <PageNotFound />,
    path: "*",
  },
]);

const App = () => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    userName: "",
    userRoles: ""
  });

  const handleLoading = (item) => {
    setLoading(item);
  };

  const handleUserData = (data) => {
    if(data.hasOwnProperty("userName") && data.hasOwnProperty("userRoles")){
      setUserData(data)
    } else if(data.hasOwnProperty("userName")){
      let obj = {
        userName: data.userName,
        userRoles: userData.userRoles
      }
      setUserData(obj)
    } else if(data.hasOwnProperty("userRoles")){
      let obj = {
        userName: userData.userName,
        userRoles: data.userRoles
      }
      setUserData(obj)
    }
  }

  const posLoading = {
    loading: loading,
    userData: userData,
    handleLoading,
    handleUserData
  };
  useEffect(() => {
    const s = document.createElement("script");
    const url = window.location.href;
    const exclusions = [
      'sms',
      'giftCardSms',
      'create-reward-card',
      'update-reward-card',
      'campaign',
      '404',
      '401',
      'login',
      'forgot-password',
      'reset',
      'lowInventoryProductsReport',
      'dailyLowInventoryProductsReport',
      'terms-services',
      'privacy-policy',
    ];
    const shouldIncludeScript = exclusions.every(exclusion => !url.includes(exclusion));
    if (shouldIncludeScript) {
      const existingScriptAlready = document.getElementById("helpkit-widget");
      if(!existingScriptAlready){
        s.type = "text/javascript";
        s.id = "helpkit-widget";
        s.setAttribute('data-project-id', "octopos");
        s.src = "https://www.helpkit.so/widget/script.js";
        document.head.appendChild(s);
      }
    } else {
      const existingScript = document.getElementById("helpkit-widget");
      if (existingScript) {
        existingScript.parentNode.removeChild(existingScript);
      }
    }
  }, [router,loading]);

  return (
    <AppContext.Provider value={posLoading}>
      <RouterProvider router={router} />
    </AppContext.Provider>
  );
};

export default App;
