import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Grid,
  GridItem,
  Heading,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import { ThemeProvider } from "@mui/material/styles";
import { GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Colors from "../../assets/colors/Colors";
import "../../assets/styles/App.css";
import { PosDataGridTable } from "../../components/index";
import * as Constants from "../../constants/Constants";
import { commonDateFormate } from "../../helpers/utils/Utils";
import { PosErrorHook } from "../../hooks";
import { WithRouter } from "../../navigators/WithRouter";
import { getSingleOrder } from "../../pages/core/order/OrderService";
import { Mtheme } from "../../theme/index";
import {
  AppContext,
  PosBreadCrumb,
  PosDrawer,
  PosNoDataFound,
  PosTab,
} from "../index";
import { ViewIcon } from "@chakra-ui/icons";

export const RenderderData = (props) => {
  return (
    <ListItem>
      <Stack spacing={"0.5rem"}>
        <Text
          fontSize={"0.94rem"}
          fontStyle={"normal"}
          fontWeight={"500"}
          lineHeight={"normal"}
          letterSpacing={"-0.01rem"}
          color={Colors.orderViewLabel}
        >
          {props.title}
        </Text>
        <Text
          fontSize={"0.94rem"}
          fontStyle={"normal"}
          fontWeight={"300"}
          lineHeight={"normal"}
          letterSpacing={"-0.01rem"}
          color={Colors.posTextInfo}
        >
          {props.value}
        </Text>
      </Stack>
    </ListItem>
  );
};
const SingleOrderView = (props) => {
  const [isScreenSize992] = useMediaQuery("(max-width: 992px)");
  const [isScreenSize480] = useMediaQuery("(max-width: 480px)");
  const myContext = useContext(AppContext);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const { error } = PosErrorHook();
  const [orderViewData, setOrderViewData] = useState({});
  const [rewardCardData, setRewardCardData] = useState([]);
  const [rewardCustomerData, setRewardCustomerData] = useState([]);
  const [orderTotal, setOrderTotal] = useState(0.0);
  const [paymentGateway, setPaymentGateway] = useState("");
  const [promotionAppliedFlag, setPromotionAppliedFlag] = useState(0);
  const [totalTipAmount, setTotalTipAmount] = useState(0.0);
  const [onlineOrderFlag, setOnlineOrderFlag] = useState(0);
  const [orderLineData, setOrderLineData] = useState([]);
  const [discountDetails, setDiscountDetails] = useState({});
  const [totalModifierPrice, setTotalModifierPrice] = useState(0.0);
  const [modifierSetData, setModifierSetData] = useState([]);
  const [modifierData, setModifierData] = useState([]);
  const [failedPaxResponseData, setFailedPaxResponseData] = useState([]);
  const [paxResponseData, setPaxResponseData] = useState({});
  const [paxResponseModelHeading, setPaxResponseModelHeading] = useState("");
  const [paymentData, setPaymentData] = useState([]);
  const [orderRefundData, setOrderRefundData] = useState([]);
  const [orderLineRefundData, setOrderLineRefundData] = useState([]);
  const [rewardData, setRewardData] = useState([]);
  const [curruntTab, setCurruntTab] = useState(0);
  const [viewFlag, setViewFlag] = useState(false);
  const [tabNames, setTabNames] = useState([
    "Basic Details",
    "Lines Details",
    "Payment Details",
    "Refund Details",
    "Reward Details",
  ]);
  const [onlineOrderData, setOnlineOrderData] = useState({});
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    description: false,
    for_here: false,
    tax_removed: false,
    extra_item: false,
    discount_reason: false,
    promotion_name: false,
    discount_details: false,
    modifier_details: false,
  });
  const [paymentColumnVisibilityModel, setPaymentColumnVisibilityModel] =
    React.useState({
      payment_gateway: false,
    });
  const [failedPaxColumnVisibilityModel, setFailedPaxColumnVisibilityModel] =
    React.useState({
      // id: false,
    });
  const [
    orderRefundColumnVisibilityModel,
    setOrderRefundColumnVisibilityModel,
  ] = React.useState({
    // id: false,
    external_reference: false,
    reason: false,
  });
  const [olRefundColumnVisibilityModel, setOlRefundColumnVisibilityModel] =
    React.useState({
      external_reference: false,
      reason: false,
      order_line_id: false,
    });
  const {
    isOpen: isDisDtlModelOpen,
    onOpen: onDisDtlModelOpen,
    onClose: onDisDtlModelClose,
  } = useDisclosure();
  const {
    isOpen: isModifierDtlModelOpen,
    onOpen: onModifierDtlModelOpen,
    onClose: onModifierDtlModelClose,
  } = useDisclosure();
  const {
    isOpen: isPaxResModelOpen,
    onOpen: onPaxResModelOpen,
    onClose: onPaxResModelClose,
  } = useDisclosure();
  const openDiscountDetailsPopUp = (params) => {
    var orderLineDetails = params.row;
    if (
      undefined != orderLineDetails.mktg_campaign_coupon_id &&
      null != orderLineDetails.mktg_campaign_coupon_id &&
      "" != orderLineDetails.mktg_campaign_coupon_id
    ) {
      var couponDetails = {
        disDtlHeading: orderLineDetails.campaign_coupon[0].campaign1.title,
        couponName: orderLineDetails.campaign_coupon[0].coupon1.title,
        productName: orderLineDetails.products[0].name,
        discType: orderLineDetails.campaign_coupon[0].coupon1.discount_type,
        discValue: orderLineDetails.campaign_coupon[0].coupon1.discount_value,
      };
      setDiscountDetails(couponDetails);
    } else if (
      undefined != orderLineDetails.reward_history_id &&
      null != orderLineDetails.reward_history_id &&
      "" != orderLineDetails.reward_history_id
    ) {
      var couponDetails = {
        disDtlHeading:
          "Reward : " +
          orderLineDetails.order_line_reward_history
            .order_line_reward_progress[0].order_line_reward.name,
        couponName:
          orderLineDetails.order_line_reward_history
            .order_line_reward_progress[0].order_line_reward
            .order_line_reward_coupon.title,
        productName: orderLineDetails.products[0].name,
        discType:
          orderLineDetails.order_line_reward_history
            .order_line_reward_progress[0].order_line_reward
            .order_line_reward_coupon.discount_type,
        discValue:
          orderLineDetails.order_line_reward_history
            .order_line_reward_progress[0].order_line_reward
            .order_line_reward_coupon.discount_value,
      };
      setDiscountDetails(couponDetails);
    } else {
      var couponDetails = {
        disDtlHeading:
          "Generic Coupon : " + orderLineDetails.generic_coupon.title,
        couponName: orderLineDetails.generic_coupon.title,
        productName: orderLineDetails.products[0].name,
        discType: orderLineDetails.generic_coupon.discount_type,
        discValue: orderLineDetails.generic_coupon.discount_value,
      };
      setDiscountDetails(couponDetails);
    }
    onDisDtlModelOpen();
  };

  const openFailedPaxResponsePopUp = (params) => {
    setPaxResponseData(JSON.parse(params.row.pax_response));
    setPaxResponseModelHeading("Failed Pax Response");
    onPaxResModelOpen();
  };
  const openPaxResponsePopUp = (params) => {
    setPaxResponseData(JSON.parse(params.row.pax_response));
    setPaxResponseModelHeading("Pax Response");
    onPaxResModelOpen();
  };

  const openModifierDetailsPopUp = (params) => {
    const modifierSetData = [];
    var totalModifierPrice = 0.0;
    params.row.order_line_modifiers.forEach((element) => {
      totalModifierPrice = totalModifierPrice + element.price;
      if (modifierSetData.length <= 0) {
        modifierSetData.push({
          id: element.order_line_modifiers_set.id,
          name: element.order_line_modifiers_set.name,
        });
      } else {
        modifierSetData.forEach((key, value) => {
          if (element.order_line_modifiers_set.id != key.id) {
            if (
              modifierSetData.findIndex(
                (x) => x.id === element.order_line_modifiers_set.id
              ) >= 0
            ) {
              modifierSetData.splice(
                modifierSetData.findIndex(
                  (x) => x.id === element.order_line_modifiers_set.id
                ),
                1
              );
            }
            modifierSetData.push({
              id: element.order_line_modifiers_set.id,
              name: element.order_line_modifiers_set.name,
            });
          }
        });
      }
    });
    setModifierData(params.row.order_line_modifiers);
    setModifierSetData(modifierSetData);
    setTotalModifierPrice(totalModifierPrice);
    onModifierDtlModelOpen();
  };
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 200 } : { flex: 1.5 };

  const columns = [
    { field: "id", headerName: "Sr No", sortable: false, width: 90 },
    {
      field: "product_name",
      headerName: "Product Name",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "primary_barcode",
      headerName: "Primary Barcode",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "for_here",
      headerName: "For Here",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "extra_item",
      headerName: "Extra Item",
      sortable: false,
      ...actionColumn,
    },
    // {
    //   field: "tax_removed",
    //   headerName: "Tax Exempt",
    //   sortable: false,
    //   ...actionColumn,
    // },
    {
      field: "quantity",
      headerName: "Quantity",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "free_quantity",
      headerName: "Free Quantity",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "sale_price",
      headerName: "Sale Price($)",
      sortable: false,
      ...actionColumn,
    },

    {
      field: "tax_collected",
      headerName: "Tax($)",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "ebt_tax_exemption",
      headerName: "Ebt Tax Exemption",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "sub_total",
      headerName: "Sub Total($)",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "discount",
      headerName: "Discount($)",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "total",
      headerName: "Total($)",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "discount_reason",
      headerName: "Discount Reason",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "promotion_name",
      headerName: "Promotion Name",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "discount_details",
      headerName: "Discount Details",
      filterable: false,
      sortable: false,
      resizable: false,
      ...actionColumn,
      renderCell: (params) =>
        (null != params.row.mktg_campaign_coupon_id &&
          "" != params.row.mktg_campaign_coupon_id) ||
        (null != params.row.reward_history_id &&
          "" != params.row.reward_history_id) ||
        (null != params.row.discount_coupon_id &&
          "" != params.row.discount_coupon_id) ? (
          <IconButton
            aria-label="view"
            onClick={() => openDiscountDetailsPopUp(params)}
            sx={{ color: Colors.posNavbarLink }}
          >
            <VisibilityIcon />
          </IconButton>
        ) : (
          "-"
        ),
    },
    {
      field: "modifier_details",
      headerName: "Modifier Details",
      filterable: false,
      sortable: false,
      resizable: false,
      ...actionColumn,
      renderCell: (params) =>
        params.row.order_line_modifiers?.length > 0 ? (
          <IconButton
            aria-label="view"
            onClick={() => openModifierDetailsPopUp(params)}
            sx={{ color: Colors.posNavbarLink }}
          >
            <VisibilityIcon />
          </IconButton>
        ) : (
          "-"
        ),
    },
  ];

  if (orderViewData.order_tax_exempt_flag !== 1) {
    columns.splice(10, 0, {
      field: "tax_removed",
      headerName: "Tax Exempt",
      sortable: false,
      ...actionColumn,
    });
  }

  const failedPaxResponseColumns = [
    { field: "id", headerName: "Sr No", sortable: false, flex: 1 },
    {
      field: "pax_response",
      headerName: "Pax Response",
      filterable: false,
      sortable: false,
      resizable: false,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          aria-label="view"
          onClick={() => openFailedPaxResponsePopUp(params)}
          sx={{ color: Colors.posNavbarLink }}
        >
          <VisibilityIcon />
        </IconButton>
      ),
    },
  ];

  const paymentColumns = [
    { field: "id", headerName: "Sr No", sortable: false, width: 90 },
    {
      field: "amount",
      headerName: "Amount ($)",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "external_reference",
      headerName: "External Reference",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "payment_gateway",
      headerName: "Payment Gateway",
      sortable: false,
      ...actionColumn,
    },
    { field: "type", headerName: "Type", sortable: false, ...actionColumn },
    { field: "aid", headerName: "Aid", sortable: false, ...actionColumn },
    {
      field: "aid_display_name",
      headerName: "Aid Display Name",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "tip_amount",
      headerName: "Tip Amount($)",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "pax_response",
      headerName: "Pax Response",
      filterable: false,
      sortable: false,
      resizable: false,
      width: 150,
      renderCell: (params) =>
        null != params.row.pax_response &&
        "" != params.row.pax_response &&
        "-" != params.row.pax_response ? (
          <IconButton
            aria-label="view"
            onClick={() => openPaxResponsePopUp(params)}
            sx={{ color: Colors.posNavbarLink }}
          >
            <VisibilityIcon />
          </IconButton>
        ) : "-" == params.row.pax_response ? (
          "-"
        ) : (
          "N.A."
        ),
    },
    {
      field: "payment_date",
      headerName: "Payment Date",
      sortable: false,
      ...actionColumnFlexDouble,
    },
  ];

  const orderRefundColumns = [
    { field: "id", headerName: "Sr No", sortable: false, width: 90 },
    {
      field: "pos_station_id",
      headerName: "Pos Station Id",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "amount",
      headerName: "Amount ($)",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "tax_amount",
      headerName: "Tax Amount($)",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "tip_amount",
      headerName: "Tip Amount($)",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "payment_type",
      headerName: "Payment Type",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "external_reference",
      headerName: "External Reference	",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    { field: "reason", headerName: "Reason", sortable: false, ...actionColumn },
    {
      field: "refund_date",
      headerName: "Refund Date",
      sortable: false,
      ...actionColumnFlexDouble,
    },
  ];

  const orderLineRefundColumns = [
    { field: "id", headerName: "Sr No", sortable: false, width: 90 },
    {
      field: "pos_station_id",
      headerName: "Pos Station Id",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "order_line_id",
      headerName: "Order Line Id",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "product_name",
      headerName: "Product Name	",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "amount",
      headerName: "Amount ($)",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "tax_amount",
      headerName: "Tax Amount($)",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "payment_type",
      headerName: "Payment Type",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "external_reference",
      headerName: "External Reference	",
      sortable: false,
      ...actionColumn,
    },
    { field: "reason", headerName: "Reason", sortable: false, ...actionColumn },
    {
      field: "refund_date",
      headerName: "Refund Date",
      sortable: false,
      ...actionColumnFlexDouble,
    },
  ];
  useEffect(() => {
    if (
      undefined !== props.orderId &&
      null != props.orderId &&
      "" != props.orderId
    ) {
      handleViewPage(props.orderId);
    }
  }, [props.orderId]);

  const handleViewPage = (id) => {
    myContext.handleLoading(true);
    try {
      getSingleOrder(id)
        .then((response) => {
          if (
            undefined !== response?.data?.flag &&
            null !== response?.data?.flag &&
            response?.data?.flag == true
          ) {
            if (
              response.data.data.reward_card != undefined &&
              response.data.data.reward_card != null &&
              response.data.data.reward_card != ""
            ) {
              setRewardCardData(response.data.data.reward_card);
            }
            setRewardCustomerData(response.data.data.customerDetail);
            let orderDetails = response.data.data;
            setOrderViewData(orderDetails);
            if (
              orderDetails.all_lines.find(
                (e) => null != e.promotion_id && "" != e.promotion_id
              )
            ) {
              setPromotionAppliedFlag(1);
            }
            const orderLineArray = [];
            var totalQty = 0.0;
            var totalFreeQty = 0.0;
            var totalSalePrice = 0.0;
            var totalTax = 0.0;
            var totalEbtTaxExemption = 0.0;
            var subTotal = 0.0;
            var totalDiscount = 0.0;
            var total = 0.0;
            orderDetails.all_lines.forEach((value, key) => {
              var orderLine = {
                id: key + 1,
                product_name:
                  value.extra_item === 1
                    ? value.extra_item_name
                    : value.products[0].name,
                primary_barcode: value.products[0].barcode,
                description:
                  null != value.description && "" != value.description
                    ? value.description
                    : "N.A.",
                for_here:
                  null != value.for_here && "" != value.for_here
                    ? value.for_here == 1
                      ? "Yes"
                      : "No"
                    : "N.A.",
                extra_item: value.extra_item == 1 ? "Yes" : "No",
                tax_removed: value.tax_removed == 1 ? "Yes" : "No",
                quantity:
                  value.products[0].sold_by_weight == 1 && value.tare_weight > 0
                    ? `${value.quantity} lb Net`
                    : value.products[0].sold_by_weight == 1
                    ? `${value.quantity} lb`
                    : parseInt(value.quantity, 10).toString(),
                free_quantity: value.free_quantity,
                sale_price: value.sale_price,
                tax_collected: value.tax_collected,
                ebt_tax_exemption: value.ebt_tax,
                sub_total: (
                  value.sale_price *
                    (value.quantity - value.free_quantity) *
                    1 +
                  value.tax_collected * 1 +
                  value.ebt_tax * 1
                ).toFixed(2),
                discount: value.total_discount,
                total: (
                  value.sale_price *
                    (value.quantity - value.free_quantity) *
                    1 +
                  value.tax_collected * 1 +
                  value.ebt_tax * 1 -
                  value.total_discount * 1
                ).toFixed(2),
                discount_reason:
                  null != value.discount_reason && "" != value.discount_reason
                    ? value.discount_reason
                    : "N.A.",
                promotion_name:
                  null != value.promotion && "" != value.promotion
                    ? value.promotion.name
                    : "N.A.",
                mktg_campaign_coupon_id: value.mktg_campaign_coupon_id,
                reward_history_id: value.reward_history_id,
                discount_coupon_id: value.discount_coupon_id,
                campaign_coupon: value.campaign_coupon,
                order_line_reward_history: value.order_line_reward_history,
                generic_coupon: value.generic_coupon,
                products: value.products,
                order_line_modifiers: value.order_line_modifiers,
              };
              orderLineArray.push(orderLine);
              totalQty += value.quantity * 1;
              totalFreeQty += orderLine.free_quantity * 1;
              totalSalePrice += orderLine.sale_price * 1;
              totalTax += orderLine.tax_collected * 1;
              totalEbtTaxExemption += orderLine.ebt_tax_exemption * 1;
              subTotal += orderLine.sub_total * 1;
              totalDiscount += orderLine.discount * 1;
              total += orderLine.total * 1;
            });
            var orderLineTotalPayload = {
              id: "Total",
              product_name: "-",
              primary_barcode: "-",
              description: "-",
              for_here: "-",
              extra_item: "-",
              tax_removed: "-",
              quantity: totalQty.toFixed(2),
              free_quantity: totalFreeQty.toFixed(2),
              sale_price: totalSalePrice.toFixed(2),
              tax_collected: totalTax.toFixed(2),
              ebt_tax_exemption: totalEbtTaxExemption.toFixed(2),
              sub_total: subTotal.toFixed(2),
              discount: totalDiscount.toFixed(2),
              total: total.toFixed(2),
              discount_reason: "-",
              promotion_name: "-",
              discount_details: "-",
              modifier_details: "-",
            };
            orderLineArray.push(orderLineTotalPayload);
            setOrderLineData(orderLineArray);
            if (orderDetails.payment.length > 0) {
              var giftCardCode = null;
              if (
                null != orderDetails.gift_card_audit_trail &&
                "" != orderDetails.gift_card_audit_trail
              ) {
                var giftCardCode =
                  orderDetails.gift_card_audit_trail.gift_card.code;
              }
              let lastPaymentObject =
                orderDetails.payment[orderDetails.payment.length - 1];
              setPaymentGateway(lastPaymentObject.payment_gateway);
              let sumOfOrderTotal = 0.0;
              let sumOfTip = 0.0;
              const paymentArray = [];
              orderDetails.payment.forEach((value, key) => {
                // Total payment amount
                sumOfOrderTotal = sumOfOrderTotal * 1 + value["amount"] * 1;
                // Total tip amount
                sumOfTip = sumOfTip * 1 + value["tips_amount"] * 1;
                var payment = {
                  id: key + 1,
                  amount: (value.amount * 1 + value.tips_amount * 1).toFixed(2),
                  external_reference:
                    null != value.external_reference &&
                    "" != value.external_reference
                      ? value.external_reference
                      : "N.A.",
                  payment_gateway:
                    null != value.payment_gateway && "" != value.payment_gateway
                      ? value.payment_gateway == "AUTHNET"
                        ? "Authorize.net"
                        : value.payment_gateway
                      : "N.A.",
                  type:
                    "OTH_CC" == value.type
                      ? "Other Credit Card"
                      : "OTH_CHECK" == value.type
                      ? "Other Check"
                      : "OTH_EBT" == value.type
                      ? "Other EBT"
                      : "GIFT_CARD_PAYMENT" == value.type
                      ? "GIFT CARD(" + giftCardCode + ")"
                      : value.type,
                  aid:
                    null != value.aid && "" != value.aid ? value.aid : "N.A.",
                  aid_display_name:
                    null != value.aid_display_name &&
                    "" != value.aid_display_name
                      ? value.aid_display_name
                      : "N.A.",
                  tip_amount: value.tips_amount,
                  pax_response: value.pax_response,
                  payment_date: commonDateFormate(value.created_at),
                };
                paymentArray.push(payment);
              });
              setOrderTotal(sumOfOrderTotal.toFixed(2));
              setTotalTipAmount(sumOfTip.toFixed(2));
              var paymentTotalPayload = {
                id: "Total",
                amount: (sumOfOrderTotal * 1 + sumOfTip * 1).toFixed(2),
                external_reference: "-",
                payment_gateway: "-",
                type: "-",
                aid: "-",
                aid_display_name: "-",
                tip_amount: sumOfTip.toFixed(2),
                pax_response: "-",
                payment_date: "-",
              };
              paymentArray.push(paymentTotalPayload);
              setPaymentData(paymentArray);
            }

            if (
              orderDetails.hasOwnProperty("online_order_detail") &&
              null != orderDetails.online_order_detail &&
              "" != orderDetails.online_order_detail
            ) {
              setTabNames([
                "Basic Details",
                "Lines Details",
                "Payment Details",
                "Refund Details",
                "Reward Details",
                "Online Order Details",
              ]);
              setOnlineOrderFlag(1);
              if (
                null !=
                  orderDetails.online_order_detail["dispatch_first_name"] &&
                "" != orderDetails.online_order_detail["dispatch_first_name"] &&
                null !=
                  orderDetails.online_order_detail["dispatch_last_name"] &&
                "" != orderDetails.online_order_detail["dispatch_last_name"]
              ) {
                var customerName =
                  orderDetails.online_order_detail["dispatch_first_name"] +
                  " " +
                  orderDetails.online_order_detail["dispatch_last_name"];
              } else if (
                null !=
                  orderDetails.online_order_detail["dispatch_first_name"] &&
                "" != orderDetails.online_order_detail["dispatch_first_name"] &&
                (null ==
                  orderDetails.online_order_detail["dispatch_last_name"] ||
                  "" == orderDetails.online_order_detail["dispatch_last_name"])
              ) {
                var customerName =
                  orderDetails.online_order_detail["dispatch_first_name"];
              } else if (
                null !=
                  orderDetails.online_order_detail["dispatch_last_name"] &&
                "" != orderDetails.online_order_detail["dispatch_last_name"] &&
                (null ==
                  orderDetails.online_order_detail["dispatch_first_name"] ||
                  "" == orderDetails.online_order_detail["dispatch_first_name"])
              ) {
                var customerName =
                  orderDetails.online_order_detail["dispatch_last_name"];
              } else {
                var customerName = "N.A.";
              }
              var onlineOrderPayload = {
                customer_name: customerName,
                customer_phone_number: orderDetails.online_order_detail[
                  "dispatch_user_phone"
                ]
                  ? orderDetails.online_order_detail["dispatch_user_phone"]
                  : "N.A.",
                customer_address:
                  orderDetails.online_order_detail["dispatch_address1"] +
                  ", " +
                  orderDetails.online_order_detail["dispatch_city"] +
                  ", " +
                  orderDetails.online_order_detail["dispatch_state"] +
                  ", " +
                  orderDetails.online_order_detail["dispatch_country"] +
                  ", " +
                  orderDetails.online_order_detail["dispatch_zip"],
                order_dispatch_type:
                  Constants.PICKUP_CODE ==
                  orderDetails.online_order_detail.order_dispatch_type
                    ? Constants.PICKUP
                    : Constants.DELIVERY,
                dispatch_instruction: orderDetails.online_order_detail[
                  "dispatch_instruction"
                ]
                  ? orderDetails.online_order_detail["dispatch_instruction"]
                  : "N.A.",
                dispatch_slot_start_time:
                  orderDetails.online_order_detail["dispatch_slot_start_time"],
                dispatch_slot_end_time:
                  orderDetails.online_order_detail["dispatch_slot_end_time"],
              };
              setOnlineOrderData(onlineOrderPayload);
            }
            if (orderDetails.failed_pax_response.length > 0) {
              const failedPaxResponseArray = [];
              orderDetails.failed_pax_response.forEach((value, key) => {
                var failedPaxResponsePayload = {
                  id: key + 1,
                  pax_response: value.pax_response,
                };
                failedPaxResponseArray.push(failedPaxResponsePayload);
              });
              setFailedPaxResponseData(failedPaxResponseArray);
            }
            var orderRefund = [];
            var orderRefundAmount = 0.0;
            var orderRefundTaxAmount = 0.0;
            var orderRefundTipAmount = 0.0;
            var orderLineRefund = [];
            var orderLineRefundAmount = 0.0;
            var orderLineRefundTaxAmount = 0.0;
            if (null != orderDetails.refund && "" != orderDetails.refund) {
              orderDetails.refund.forEach((value, key) => {
                if (
                  null != value["order_line_id"] &&
                  "" != value["order_line_id"]
                ) {
                  var orderLineRefundObj = {
                    id: orderLineRefund.length + 1,
                    pos_station_id: value.pos_station_id,
                    order_line_id: value.order_line_id,
                    product_name: value.order_line.products[0].name,
                    quantity: value.quantity,
                    amount: value.amount,
                    tax_amount: value.tax_amount,
                    payment_type: value.payment_type,
                    external_reference: value.external_reference ?? "N.A.",
                    reason: value.reason,
                    refund_date: commonDateFormate(value.created_at),
                  };
                  orderLineRefundAmount = (
                    orderLineRefundAmount * 1 +
                    value.amount * 1
                  ).toFixed(2);
                  orderLineRefundTaxAmount = (
                    orderLineRefundTaxAmount * 1 +
                    value.tax_amount * 1
                  ).toFixed(2);
                  orderLineRefund.push(orderLineRefundObj);
                } else {
                  var orderRefundObj = {
                    id: orderRefund.length + 1,
                    pos_station_id: value.pos_station_id,
                    amount: value.amount,
                    tax_amount: value.tax_amount,
                    tip_amount: value.tip_amount,
                    payment_type: value.payment_type,
                    external_reference: value.external_reference ?? "N.A.",
                    reason: value.reason,
                    refund_date: commonDateFormate(value.created_at),
                  };
                  orderRefundAmount = (
                    orderRefundAmount * 1 +
                    value.amount * 1
                  ).toFixed(2);
                  orderRefundTaxAmount = (
                    orderRefundTaxAmount * 1 +
                    value.tax_amount * 1
                  ).toFixed(2);
                  orderRefundTipAmount = (
                    orderRefundTipAmount * 1 +
                    value.tip_amount * 1
                  ).toFixed(2);
                  orderRefund.push(orderRefundObj);
                }
              });
              var orderRefundTotalObj = {
                id: "Total",
                pos_station_id: "-",
                amount: orderRefundAmount,
                tax_amount: orderRefundTaxAmount,
                tip_amount: orderRefundTipAmount,
                payment_type: "-",
                external_reference: "-",
                reason: "-",
                refund_date: "-",
              };
              orderRefund.push(orderRefundTotalObj);
              var orderLineRefundTotalObj = {
                id: "Total",
                pos_station_id: "-",
                order_line_id: "-",
                product_name: "-",
                quantity: "-",
                amount: orderLineRefundAmount,
                tax_amount: orderLineRefundTaxAmount,
                payment_type: "-",
                external_reference: "-",
                reason: "-",
                refund_date: "-",
              };
              orderLineRefund.push(orderLineRefundTotalObj);
              setOrderRefundData(orderRefund);
              setOrderLineRefundData(orderLineRefund);
            }
            if (orderDetails.allRewardsTotal.length > 0) {
              setRewardData(orderDetails.allRewardsTotal);
            }
            myContext.handleLoading(false);
            setViewFlag(true);
          } else {
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
            setViewFlag(false);
          }
        })
        .catch((err) => {
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
          setViewFlag(false);
        });
    } catch (error) {
      myContext.handleLoading(false);
      setViewFlag(false);
    }
  };
  const handleTabChange = (index) => {
    setCurruntTab(index);
  };
  const closeSidebar = () => {
    setOrderViewData({});
    setRewardCardData([]);
    setOrderTotal(0.0);
    setPaymentGateway("");
    setPromotionAppliedFlag(0);
    setTotalTipAmount(0.0);
    setOnlineOrderFlag(0);
    setOrderLineData([]);
    setDiscountDetails({});
    setTotalModifierPrice(0.0);
    setModifierSetData([]);
    setModifierData([]);
    setFailedPaxResponseData([]);
    setPaxResponseData({});
    setPaxResponseModelHeading("");
    setPaymentData([]);
    setOrderRefundData([]);
    setOrderLineRefundData([]);
    setRewardData([]);
    setTabNames([
      "Basic Details",
      "Lines Details",
      "Payment Details",
      "Refund Details",
      "Reward Details",
    ]);
    setOnlineOrderData({});
    setOlColumnVisibilityModel({
      id: false,
      description: false,
      for_here: false,
      tax_removed: false,
      extra_item: false,
      discount_reason: false,
      ebt_tax_exemption: false,
      promotion_name: false,
      discount_details: false,
      modifier_details: false,
    });
    setPaymentColumnVisibilityModel({
      id: false,
    });
    setOrderRefundColumnVisibilityModel({
      id: false,
    });
    setOlRefundColumnVisibilityModel({
      id: false,
    });
    props.onHide();
    setViewFlag(false);
  };
  const resetDrawerData = () => {
    props.onHide();
    setViewFlag(false);
    setOrderViewData({});
    setRewardCardData([]);
    setOrderTotal(0.0);
    setPaymentGateway("");
    setPromotionAppliedFlag(0);
    setTotalTipAmount(0.0);
    setOnlineOrderFlag(0);
    setOrderLineData([]);
    setDiscountDetails({});
    setTotalModifierPrice(0.0);
    setModifierSetData([]);
    setModifierData([]);
    setFailedPaxResponseData([]);
    setPaxResponseData({});
    setPaxResponseModelHeading("");
    setPaymentData([]);
    setOrderRefundData([]);
    setOrderLineRefundData([]);
    setRewardData([]);
    setTabNames([
      "Basic Details",
      "Lines Details",
      "Payment Details",
      "Refund Details",
      "Reward Details",
    ]);
    setOnlineOrderData({});
    setOlColumnVisibilityModel({
      id: false,
      description: false,
      for_here: false,
      tax_removed: false,
      extra_item: false,
      discount_reason: false,
      ebt_tax_exemption: false,
      promotion_name: false,
      discount_details: false,
      modifier_details: false,
    });
    setPaymentColumnVisibilityModel({
      id: false,
    });
    setOrderRefundColumnVisibilityModel({
      id: false,
    });
    setOlRefundColumnVisibilityModel({
      id: false,
    });
  };
  return (
    <PosDrawer
      visible={viewFlag}
      onHide={resetDrawerData}
      position="right"
      showCloseIcon={props.showCloseIcon}
    >
      {Object.keys(orderViewData).length > 0 && (
        <>
          <PosBreadCrumb
            handleClick={(i) => {
              props.handleClick(i);
              closeSidebar();
            }}
            breadCrumNames={props.breadCrumNames}
            breadCrumTitle={"View Orders"}
          />
          <Tabs
            onChange={(index) => handleTabChange(index)}
            mt={{ base: "2.94rem", md: "2.94rem" }}
          >
            <PosTab tabNames={tabNames} />
            <TabPanels>
              <TabPanel
                pl={{ base: "1rem", md: "2.94rem" }}
                pt={{ base: "1rem", md: "3rem" }}
                pr={{ base: "1rem", md: "4.86rem" }}
                pb={{ base: "1rem", md: "3.13rem" }}
              >
                <Grid
                  gap={6}
                  t={{ base: "2rem", md: "2rem" }}
                  templateColumns={
                    isScreenSize992 && isScreenSize480
                      ? "repeat(1, 1fr)"
                      : isScreenSize992 && !isScreenSize480
                      ? "repeat(2, 1fr)"
                      : "repeat(4, 1fr)"
                  }
                >
                  <GridItem>
                    <List spacing={"2rem"}>
                      <RenderderData
                        title={"Order Date"}
                        value={commonDateFormate(orderViewData.created_at)}
                      />
                      <RenderderData
                        title={"Order ID"}
                        value={orderViewData.id}
                      />
                      <RenderderData
                        title={"Local ID/Order No"}
                        value={orderViewData.local_id}
                      />
                      <RenderderData
                        title={"Order name"}
                        value={
                          null != orderViewData.order_user_name &&
                          "" != orderViewData.order_user_name
                            ? orderViewData.order_user_name
                            : "N.A"
                        }
                      />
                      <RenderderData
                        title={"Amount Paid"}
                        value={orderViewData.balance}
                      />
                      {onlineOrderFlag == 1 && (
                        <RenderderData
                          title={"Online Order Type"}
                          value={
                            Constants.PICKUP_CODE ==
                            orderViewData?.online_order_detail
                              ?.order_dispatch_type
                              ? Constants.PICKUP
                              : Constants.DELIVERY
                          }
                        />
                      )}
                    </List>
                  </GridItem>
                  <GridItem>
                    <List spacing={"2rem"}>
                      <RenderderData
                        title={"Created by"}
                        value={
                          null == orderViewData.order_user ||
                          null == orderViewData.order_user ||
                          undefined == orderViewData.order_user
                            ? "N.A."
                            : orderViewData.order_user.name
                        }
                      />
                      <RenderderData
                        title={"Order Total"}
                        value={
                          orderViewData.paidStatus === "Unpaid"
                            ? "0.00"
                            : orderTotal
                        }
                      />
                      <RenderderData
                        title={"Payment Status"}
                        value={orderViewData.paidStatus}
                      />
                      <RenderderData
                        title={" Location name"}
                        value={orderViewData.station[0]?.["location"]?.name}
                      />
                      <RenderderData
                        title={" Cash Discount"}
                        value={
                          null != orderViewData.dual_pricing_discount &&
                          "" != orderViewData.dual_pricing_discount
                            ? orderViewData.dual_pricing_discount
                            : "N.A"
                        }
                      />
                    </List>
                  </GridItem>
                  <GridItem>
                    <List spacing={"2rem"}>
                      <RenderderData
                        title={"EBT Tax Exemption"}
                        value={orderViewData.ebt_flag == 1 ? "Yes" : "No"}
                      />
                      <RenderderData
                        title={"Ebt Tax Saved"}
                        value={orderViewData.ebt_saved_tax}
                      />
                      <RenderderData
                        title={"Payment Gateway"}
                        value={
                          null == paymentGateway || "" == paymentGateway
                            ? "N.A."
                            : paymentGateway == Constants.AUTHORIZE_DOT_NET_CODE
                            ? Constants.PAYMENT_GATEWAY_AUTHORIZE_DOT_NET
                            : paymentGateway
                        }
                      />
                      <RenderderData
                        title={"Order Discount"}
                        value={
                          null != orderViewData.discount_amount &&
                          "" != orderViewData.discount_amount
                            ? orderViewData.discount_amount
                            : "N.A"
                        }
                      />
                      <RenderderData
                        title={"Discount Reason"}
                        value={
                          null != orderViewData.discount_reason &&
                          "" != orderViewData.discount_reason
                            ? orderViewData.discount_reason
                            : "N.A"
                        }
                      />
                    </List>
                  </GridItem>
                  <GridItem>
                    <List spacing={"2rem"}>
                      <RenderderData
                        title={"Total Tips Amount"}
                        value={
                          orderViewData.paidStatus === "Unpaid"
                            ? "0.00"
                            : totalTipAmount
                        }
                      />
                      <RenderderData
                        title={"Reward Customer Id"}
                        value={
                          null != orderViewData.reward_card_id &&
                          "" != orderViewData.reward_card_id
                            ? orderViewData.reward_card_id
                            : "N.A"
                        }
                      />
                      <RenderderData
                        title={"Promotion Applied"}
                        value={promotionAppliedFlag == 1 ? "Yes" : "No"}
                      />
                      <RenderderData
                        title={"POS Station ID"}
                        value={orderViewData.pos_station_id}
                      />
                      <RenderderData
                        title={"Tax Exempt"}
                        value={
                          orderViewData.order_tax_exempt_flag == 1
                            ? "Yes"
                            : "No"
                        }
                      />
                    </List>
                  </GridItem>
                </Grid>
              </TabPanel>
              <TabPanel
                pl={{ base: "1rem", md: "2.94rem" }}
                pt={{ base: "1rem", md: "3rem" }}
                pr={{ base: "1rem", md: "4.86rem" }}
                pb={{ base: "1rem", md: "3.13rem" }}
              >
                <ThemeProvider theme={Mtheme}>
                  {curruntTab === 1 ? (
                    <Box>
                      <Box maxHeight={"31rem"} overflow={"auto"}>
                        <PosDataGridTable
                          columns={columns}
                          rows={orderLineData.filter(
                            (data) => data.id != "Total"
                          )}
                          columnVisibilityModel={olColumnVisibilityModel}
                          onColumnVisibilityModelChange={(newModel) =>
                            setOlColumnVisibilityModel(newModel)
                          }
                          hideFooter={true}
                          slots={{
                            toolbar: GridToolbar,
                          }}
                          fileName={Constants.ORDER_LINE_CSV}
                        />
                      </Box>
                      <Box>
                        <PosDataGridTable
                          columns={columns.map((column) => ({
                            ...column,
                            renderCell: (params) => (
                              <div style={{ fontWeight: 500 }}>
                                {params.value}
                              </div>
                            ),
                          }))}
                          columnVisibilityModel={olColumnVisibilityModel}
                          onColumnVisibilityModelChange={(newModel) =>
                            setOlColumnVisibilityModel(newModel)
                          }
                          rows={orderLineData.filter(
                            (data) => data.id === "Total"
                          )}
                          columnHeaderHeight={0}
                          hideFooter={true}
                        />
                      </Box>
                    </Box>
                  ) : null}
                </ThemeProvider>
              </TabPanel>
              <TabPanel
                pl={{ base: "1rem", md: "2.94rem" }}
                pt={{ base: "1rem", md: "3rem" }}
                pr={{ base: "1rem", md: "4.86rem" }}
                pb={{ base: "1rem", md: "3.13rem" }}
              >
                <Stack direction={["column"]} spacing="0.5rem">
                  {paymentData.length > 1 && curruntTab === 2 ? (
                    <Card>
                      <CardHeader>
                        <Heading size="md">Payment</Heading>
                      </CardHeader>
                      <CardBody>
                        <ThemeProvider theme={Mtheme}>
                          <Box maxHeight={"31rem"} overflow={"auto"}>
                            <PosDataGridTable
                              columns={paymentColumns}
                              rows={paymentData}
                              columnVisibilityModel={
                                paymentColumnVisibilityModel
                              }
                              onColumnVisibilityModelChange={(newModel) =>
                                setPaymentColumnVisibilityModel(newModel)
                              }
                              hideFooter={true}
                              slots={{
                                toolbar: GridToolbar,
                              }}
                              fileName={Constants.ORDER_PAYMENT_CSV}
                            />
                          </Box>
                          {/* <Box>
                            <PosDataGridTable
                              columns={paymentColumns.map((column) => ({
                                ...column,
                                renderCell: (params) => (
                                  <div style={{ fontWeight: 500 }}>
                                    {params.value}
                                  </div>
                                ),
                              }))}
                              columnVisibilityModel={
                                paymentColumnVisibilityModel
                              }
                              onColumnVisibilityModelChange={(newModel) =>
                                setPaymentColumnVisibilityModel(newModel)
                              }
                              rows={paymentData.filter(
                                (data) => data.id === "Total"
                              )}
                              columnHeaderHeight={0}
                              hideFooter={true}
                            />
                          </Box> */}
                        </ThemeProvider>
                      </CardBody>
                    </Card>
                  ) : (
                    <PosNoDataFound title={Constants.ORDER_PAYMENT_NOT_FOUND} />
                  )}
                  {failedPaxResponseData.length > 0 && curruntTab === 2 ? (
                    <Card>
                      <CardHeader>
                        <Heading size="md">Failed Pax Transaction</Heading>
                      </CardHeader>
                      <CardBody>
                        <ThemeProvider theme={Mtheme}>
                          <Box maxHeight={"31rem"} overflow={"auto"}>
                            <PosDataGridTable
                              columns={failedPaxResponseColumns}
                              rows={failedPaxResponseData}
                              columnVisibilityModel={
                                failedPaxColumnVisibilityModel
                              }
                              onColumnVisibilityModelChange={(newModel) =>
                                setFailedPaxColumnVisibilityModel(newModel)
                              }
                              hideFooter={true}
                              slots={{
                                toolbar: GridToolbar,
                              }}
                              fileName={Constants.ORDER_FAILED_PAX_CSV}
                            />
                          </Box>
                        </ThemeProvider>
                      </CardBody>
                    </Card>
                  ) : null}
                </Stack>
              </TabPanel>
              <TabPanel
                pl={{ base: "1rem", md: "2.94rem" }}
                pt={{ base: "1rem", md: "3rem" }}
                pr={{ base: "1rem", md: "4.86rem" }}
                pb={{ base: "1rem", md: "3.13rem" }}
              >
                {orderRefundData.length > 0 &&
                orderLineRefundData.length > 0 &&
                curruntTab === 3 ? (
                  <Stack direction={["column"]} spacing="0.5rem">
                    {orderRefundData.length > 1 && (
                      <Card>
                        <CardHeader>
                          <Heading size="md">Order Refund</Heading>
                        </CardHeader>
                        <CardBody>
                          <ThemeProvider theme={Mtheme}>
                            <Box maxHeight={"31rem"} overflow={"auto"}>
                              <PosDataGridTable
                                columns={orderRefundColumns}
                                rows={orderRefundData}
                                columnVisibilityModel={
                                  orderRefundColumnVisibilityModel
                                }
                                onColumnVisibilityModelChange={(newModel) =>
                                  setOrderRefundColumnVisibilityModel(newModel)
                                }
                                hideFooter={true}
                                slots={{
                                  toolbar: GridToolbar,
                                }}
                                fileName={Constants.ORDER_REFUNDS_CSV}
                              />
                            </Box>
                            {/* <Box>
                              <PosDataGridTable
                                columns={orderRefundColumns.map((column) => ({
                                  ...column,
                                  renderCell: (params) => (
                                    <div style={{ fontWeight: 500 }}>
                                      {params.value}
                                    </div>
                                  ),
                                }))}
                                columnVisibilityModel={
                                  orderRefundColumnVisibilityModel
                                }
                                onColumnVisibilityModelChange={(newModel) =>
                                  setOrderRefundColumnVisibilityModel(newModel)
                                }
                                rows={orderRefundData.filter(
                                  (data) => data.id === "Total"
                                )}
                                columnHeaderHeight={0}
                                hideFooter={true}
                              />
                            </Box> */}
                          </ThemeProvider>
                        </CardBody>
                      </Card>
                    )}
                    {orderLineRefundData.length > 1 && curruntTab === 3 ? (
                      <Card>
                        <CardHeader>
                          <Heading size="md">Item Wise Refund</Heading>
                        </CardHeader>
                        <CardBody>
                          <ThemeProvider theme={Mtheme}>
                            <Box maxHeight={"500px"} overflow={"auto"}>
                              <PosDataGridTable
                                columns={orderLineRefundColumns}
                                rows={orderLineRefundData}
                                columnVisibilityModel={
                                  olRefundColumnVisibilityModel
                                }
                                onColumnVisibilityModelChange={(newModel) =>
                                  setOlRefundColumnVisibilityModel(newModel)
                                }
                                hideFooter={true}
                                slots={{
                                  toolbar: GridToolbar,
                                }}
                                fileName={Constants.ORDER_ITEM_WISE_REFUND_CSV}
                                slotProps={{
                                  panel: {
                                    sx: {
                                      "& .MuiPaper-root": {
                                        maxHeight:
                                          orderLineRefundData.length <= 10
                                            ? "250px"
                                            : "auto",
                                      },
                                    },
                                  },
                                }}
                              />
                            </Box>
                          </ThemeProvider>
                        </CardBody>
                      </Card>
                    ) : null}
                  </Stack>
                ) : (
                  <PosNoDataFound title={Constants.ORDER_REFUND_NOT_FOUND} />
                )}
              </TabPanel>
              <TabPanel
                pl={{ base: "1rem", md: "2.94rem" }}
                pt={{ base: "1rem", md: "3rem" }}
                pr={{ base: "1rem", md: "4.86rem" }}
                pb={{ base: "1rem", md: "3.13rem" }}
              >
                {Object.keys(rewardCustomerData).length > 0 ? (
                  <Box>
                    <List spacing={"1rem"}>
                      <ListItem>
                        <Stack direction={["row"]} spacing={"1rem"}>
                          <Text fontWeight={"600"}>Business Name :</Text>
                          <Text>{rewardCustomerData.name}</Text>
                        </Stack>
                      </ListItem>
                      <ListItem>
                        <Stack direction={["row"]} spacing={"1rem"}>
                          <Text fontWeight={"600"}>Phone Number :</Text>
                          <Text>{rewardCustomerData.phone}</Text>
                        </Stack>
                      </ListItem>
                      <ListItem>
                        <Stack direction={["row"]} spacing={"1rem"}>
                          <Text fontWeight={"600"}>Email :</Text>
                          <Text>{rewardCustomerData.email}</Text>
                        </Stack>
                      </ListItem>
                      <ListItem>
                        <Stack direction={["row"]} spacing={"1rem"}>
                          <Text fontWeight={"600"}>Address :</Text>
                          <Text>{rewardCustomerData.address}.</Text>
                        </Stack>
                      </ListItem>
                    </List>
                    {rewardData.length > 0 ? (
                      <List spacing={"1rem"} mt={"1rem"}>
                        {Object.keys(rewardData).map((key, index) => {
                          const value = rewardData[key];
                          return (
                            <ListItem key={index}>
                              <Stack direction={["row"]} spacing={"1rem"}>
                                <Text fontWeight={"600"}>
                                  {value.reward_type_name} :
                                </Text>
                                <Text>{value.total_point}</Text>
                              </Stack>
                            </ListItem>
                          );
                        })}
                      </List>
                    ) : null}
                  </Box>
                ) :
                Object.keys(rewardCardData).length > 0 &&
                  orderViewData.reward_card_id != null &&
                  orderViewData.reward_card_id != "" ? (
                  <Box>
                    <List spacing={"1rem"}>
                      <ListItem>
                        <Stack direction={["row"]} spacing={"1rem"}>
                          <Text fontWeight={"600"}>Reward Customer ID :</Text>
                          <Text>{rewardCardData.id}</Text>
                        </Stack>
                      </ListItem>
                      <ListItem>
                        <Stack direction={["row"]} spacing={"1rem"}>
                          <Text fontWeight={"600"}>Phone Number :</Text>
                          <Text>{rewardCardData.phone}</Text>
                        </Stack>
                      </ListItem>
                      <ListItem>
                        <Stack direction={["row"]} spacing={"1rem"}>
                          <Text fontWeight={"600"}>Full Name :</Text>
                          <Text>
                            {(rewardCardData.first_name == null ||
                              rewardCardData.first_name == "") &&
                            (rewardCardData.last_name == null ||
                              rewardCardData.last_name == "")
                              ? "N.A."
                              : (rewardCardData.first_name !== null ||
                                  rewardCardData.first_name !== "") &&
                                (rewardCardData.last_name == null ||
                                  rewardCardData.last_name == "")
                              ? rewardCardData.first_name
                              : (rewardCardData.first_name == null ||
                                  rewardCardData.first_name == "") &&
                                (rewardCardData.last_name !== null ||
                                  rewardCardData.last_name !== "")
                              ? rewardCardData.last_name
                              : rewardCardData.first_name +
                                " " +
                                rewardCardData.last_name}
                          </Text>
                        </Stack>
                      </ListItem>
                      <ListItem>
                        <Stack direction={["row"]} spacing={"1rem"}>
                          <Text fontWeight={"600"}>Email :</Text>
                          <Text>
                            {rewardCardData.email !== null &&
                            rewardCardData.email != ""
                              ? rewardCardData.email
                              : "N.A."}
                          </Text>
                        </Stack>
                      </ListItem>
                    </List>
                    {rewardData.length > 0 ? (
                      <List spacing={"1rem"} mt={"1rem"}>
                        {Object.keys(rewardData).map((key, index) => {
                          const value = rewardData[key];
                          return (
                            <ListItem key={index}>
                              <Stack direction={["row"]} spacing={"1rem"}>
                                <Text fontWeight={"600"}>
                                  {value.reward_type_name} :
                                </Text>
                                <Text>{value.total_point}</Text>
                              </Stack>
                            </ListItem>
                          );
                        })}
                      </List>
                    ) : null}
                  </Box>
                ) : (
                  <PosNoDataFound title={Constants.ORDER_REWARD_NOT_FOUND} />
                )}
              </TabPanel>
              <TabPanel
                pl={{ base: "1rem", md: "2.94rem" }}
                pt={{ base: "1rem", md: "3rem" }}
                pr={{ base: "1rem", md: "4.86rem" }}
                pb={{ base: "1srem", md: "3.13rem" }}
              >
                <List spacing={"1rem"}>
                  <ListItem>
                    <Stack direction={["row"]} spacing={"1rem"}>
                      <Text fontWeight={"600"}>Customer Name:</Text>
                      <Text>{onlineOrderData.customer_name}</Text>
                    </Stack>
                  </ListItem>
                  <ListItem>
                    <Stack direction={["row"]} spacing={"1rem"}>
                      <Text fontWeight={"600"}>Customer Phone Number:</Text>
                      <Text>{onlineOrderData.customer_phone_number}</Text>
                    </Stack>
                  </ListItem>
                  <ListItem>
                    <Stack direction={["row"]} spacing={"1rem"}>
                      <Text fontWeight={"600"}>
                        {onlineOrderData.order_dispatch_type} Address:
                      </Text>
                      <Text>{onlineOrderData.customer_address}</Text>
                    </Stack>
                  </ListItem>
                  <ListItem>
                    <Stack direction={["row"]} spacing={"1rem"}>
                      <Text fontWeight={"600"}>
                        {onlineOrderData.order_dispatch_type} Time Slot:
                      </Text>
                      <Text>
                        {moment(
                          onlineOrderData.dispatch_slot_start_time
                        ).format("hh:mm a")}{" "}
                        -{" "}
                        {moment(onlineOrderData.dispatch_slot_end_time).format(
                          "hh:mm a  "
                        )}
                      </Text>
                    </Stack>
                  </ListItem>
                  <ListItem>
                    <Stack direction={["row"]} spacing={"1rem"}>
                      <Text fontWeight={"600"}>
                        {onlineOrderData.order_dispatch_type} Instruction:
                      </Text>
                      <Text>{onlineOrderData.dispatch_instruction}</Text>
                    </Stack>
                  </ListItem>
                </List>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Modal
            isOpen={isDisDtlModelOpen}
            onClose={onDisDtlModelClose}
            size={"4xl"}
            closeOnOverlayClick={false}
            isCentered
            scrollBehavior={"inside"}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{discountDetails.disDtlHeading}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <TableContainer>
                  <Table variant="simple" colorScheme="gray">
                    <Thead>
                      <Tr>
                        <Th>Coupon Name </Th>
                        <Th>Product Name</Th>
                        <Th>Discount Type</Th>
                        <Th>Discount Value</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td whiteSpace={"nowrap"}>
                          {discountDetails.couponName}
                        </Td>
                        <Td>{discountDetails.productName}</Td>
                        <Td>
                          {discountDetails.discType ==
                          Constants.DISCOUNT_TYPE_AMOUNT
                            ? Constants.AMOUNT
                            : Constants.PERCENTAGE}
                        </Td>
                        <Td>{discountDetails.discValue}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </ModalBody>
            </ModalContent>
          </Modal>
          <Modal
            isOpen={isModifierDtlModelOpen}
            onClose={onModifierDtlModelClose}
            closeOnOverlayClick={false}
            isCentered
            scrollBehavior={"inside"}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Product Modifiers</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <TableContainer>
                  <Table variant="simple" colorScheme="gray">
                    {modifierSetData.map((modifierSet, i) => (
                      <>
                        <Thead>
                          <React.Fragment key={modifierSet.id}>
                            <Tr>
                              <Th>{modifierSet.name}</Th>
                              {i === 0 ? (
                                <Th>
                                  Total Price($) :{" "}
                                  {totalModifierPrice.toFixed(2)}
                                </Th>
                              ) : (
                                <Th></Th>
                              )}
                            </Tr>
                            <Tr bg={Colors.loginAuthBackground}>
                              <Th>Modifier Name</Th>
                              <Th>Modifier Price($)</Th>
                            </Tr>
                          </React.Fragment>
                        </Thead>
                        <Tbody>
                          <React.Fragment key={modifierSet.id}>
                            {modifierData.map(
                              (modifiers) =>
                                modifiers.modifier_set_id ===
                                  modifierSet.id && (
                                  <Tr key={modifiers.id}>
                                    <Td>{modifiers.name}</Td>
                                    <Td>
                                      {modifiers.price == null
                                        ? "0.00"
                                        : modifiers.price.toFixed(2)}
                                    </Td>
                                  </Tr>
                                )
                            )}
                            {modifierSetData.length === i + 1 && (
                              <Tr>
                                <Td>Total Price($)</Td>
                                <Td>{totalModifierPrice.toFixed(2)}</Td>
                              </Tr>
                            )}
                          </React.Fragment>
                        </Tbody>
                      </>
                    ))}
                  </Table>
                </TableContainer>
              </ModalBody>
            </ModalContent>
          </Modal>
          <Modal
            isOpen={isPaxResModelOpen}
            onClose={onPaxResModelClose}
            size={"lg"}
            scrollBehavior={"inside"}
            isCentered
            closeOnOverlayClick={false}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{paxResponseModelHeading}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <pre>{JSON.stringify(paxResponseData, null, 2)}</pre>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </PosDrawer>
  );
};

export default WithRouter(SingleOrderView);
