import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  Spacer,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Wrap,
  WrapItem,
  useMediaQuery,
  SimpleGrid
} from "@chakra-ui/react";
import CloseIcon from "@mui/icons-material/Close";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import React, { useContext, useEffect, useRef, useState } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { FaFileExcel } from "react-icons/fa";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosSpliteButton,
  PosTab,
  PosTostMessage,
  PosIconButton,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { endOfTodaysDay, startOfTodaysDay } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  getAllPurchaseOrder,
  getDeactivePurchaseOrder,
  getPdfPurchaseOrder,
} from "./PurchaseOrderService";

const PurchaseOrderList = (props) => {
  const stateData = useLocation();
  const [showTable, setShowTable] = useState(true);
  const submit = useSubmit();
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const effectRun = useRef(true);
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const [curruntTab, setCurruntTab] = useState(0);
  const tabNames = ["Active", "Inactive"];
  const [purchaseOrderData, setPurchaseOrderData] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [deactivePurchaseOrderData, setdeactivePurchaseOrderData] = useState(
    []
  );
  const [totalDeactivatecount, setDeactiveTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [buttonDisables, setButtonDisables] = useState(false);
  const [vendors, setVendors] = useState([]);
  // const [locations, setlocations] = useState([]);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRunListDropDown = useRef(true);
  const loaderResponse = useLoaderData();
  const [loading, setLoading] = useState(true);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState(
    {}
  );
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const searchInputHideRef = useRef(false);
  const deactiveSearchHideRef = useRef(false);
  const [paginationModel, setPaginationModel] = useState({
    page:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);
  const [paginationModelDeactive, setPaginationModelDeactive] = useState({
    page:
      stateData.state && null !== stateData.state && "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModelDeactive, setSortModelDeactive] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);

  const sortRef = useRef(sortModel);
  const sortRefDeactive = useRef(sortModelDeactive);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
        setButtonDisable(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (actionResponse.data.message.trim() === Constants.DEACTIVE_SUCCESS) {
          setLoading(true);
          if(modifiedData.length === 1 && paginationModel?.page !== 0){
            effectRun.current = true
              setPaginationModel((prevPaginationMod) => {
                return {
                  page:prevPaginationMod?.page - 1,
                  pageSize:prevPaginationMod?.pageSize,
                }
              })
          } else {
            fetchData();
          }
          // const ven = loaderResponse?.data?.data?.vendors;
          // let vendorsDropDownData;
          // if (null != ven && "" != ven) {
          //   vendorsDropDownData = ven?.map((vendor) => ({
          //     label: vendor.name,
          //     value: { name: vendor.name, id: vendor.id },
          //   }));
          // } else {
          //   vendorsDropDownData = [];
          // }
          // setVendors(vendorsDropDownData);
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.PURCHASING_ORDER_MODULE,
            alertDescription: Constants.PURCHASING_ORDER_DEACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisables(false);
        } else if (
          actionResponse.data.message.trim() === Constants.REACTIVES_SUCCESS
        ) {
          setLoading(true);
          if(modifiedDeactiveData.length === 1 && modifiedDeactiveData?.page !== 0){
            effectRun.current = true
              setPaginationModel((prevPaginationMod) => {
                return {
                  page:prevPaginationMod?.page - 1,
                  pageSize:prevPaginationMod?.pageSize,
                }
              })
          } else {
            fetchDeActivePurchaseOrderData();
          }
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.PURCHASING_ORDER_MODULE,
            alertDescription: Constants.PURCHASING_ORDER_REACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          // setButtonDisable(false);
          setButtonDisables(false);
        } else if (
          actionResponse.data?.message ===
          "Purchase Order exported successfully."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(Constants.DOWNLOAD, "PurchaseOrder.xlsx");
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.PURCHASING_ORDER_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
          setButtonDisables(false);
        }
      }
      // else {
      //   let actionData = actionResponse;
      //   error({ actionData });
      //   setTableData([]);
      //   setTotalCount(0);
      //   setLoading(false);
      //   setButtonDisables(false);
      // }
    }
  }, [actionResponse]);

  useEffect(() => {
    if (effectRunListDropDown.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
          if (
            loaderResponse.data.data.location === "" ||
            loaderResponse.data.data.location === null
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.PURCHASING_ORDER_MODULE,
              alertDescription: Constants.LOCATION_NOT_FOUND,
            });
            props.navigate(Constants.PURCHASING_ORDER_PATH);
          }
          const ven = loaderResponse?.data?.data?.vendor;
          // const len = loaderResponse?.data?.data?.location;
          let vendorsDropDownData;
          // let locationsDropDownData;
          if (Array.isArray(ven) && ven.length > 0) {
            vendorsDropDownData = ven.map((vendor) => ({
              label: vendor.name,
              value: { name: vendor.name, id: vendor.id },
            }));
            // locationsDropDownData = len.map((location) => ({
            //   label: location.name,
            //   value: { name: location.name, id: location.id },
            // }));
          } else {
            vendorsDropDownData = [];
            // locationsDropDownData = [];
          }
          setVendors(vendorsDropDownData);
          // setlocations(locationsDropDownData);
        }
      }
      return () => {
        effectRunListDropDown.current = false;
      };
    }
  }, [loaderResponse]);

  const handleOptionSelect = (e) => {
    formik.setFieldValue("selectedVendors", e.value);
  };

  // const handlelocationOptionSelect = (e) => {
  //   formik.setFieldValue("selectedlocations", e.value);
  // };

  const handlePdfReport = async (item) => {
    myContext.handleLoading(true);
    let data = {
      id: item.id,
    };
    try {
      getPdfPurchaseOrder(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.PURCHASING_ORDER_MODULE,
              alertDescription: response.data.message,
            });
            let url = `${Constants.REACT_APP_API_URL}${response.data.data}`;
            window.open(url, "_blank");
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const handleSearchList = () => {
    props.navigate(Constants.PURCHASING_ORDER_PATH);
    paginationModel.page = 0;
    // search time get active and deactive list
    if (curruntTab == 0) {
      fetchData();
    } else {
      fetchDeActivePurchaseOrderData();
    }
  };

  const handleClear = () => {
    // clear input value and get all data
    effectRun.current = true;
    setSearchQuery("");
    props.navigate(Constants.PURCHASING_ORDER_PATH);
  };

  const formik = useFormik({
    initialValues: {
      selectedVendors:
        undefined !== loaderResponse?.data?.data?.vendors &&
        null !== loaderResponse?.data?.data?.vendors
          ? loaderResponse?.data?.data?.vendors
          : [],
          // selectedlocations: undefined !== loaderResponse?.data?.data?.locations &&
          // null !== loaderResponse?.data?.data?.locations
          //   ? loaderResponse?.data?.data?.locations
          //   : [],
      dateFrom: "",
      dateTo: "",
      from_filter: "",
      to_filter: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      selectedVendors: Yup.array().min(
        1,
        Constants.PURCHASING_ORDER_SELECT_VENDOR
      ),
      dateFrom: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      dateTo: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });

  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.dateFrom &&
      null != formik.values.dateFrom &&
      "" !== formik.values.dateFrom
    ) {
      if (moment(formik.values.dateFrom).isBefore(e.value)) {
        formik.setFieldValue("dateFrom", e.value);
        formik.setFieldValue("dateTo", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("dateFrom", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("dateFrom", e.value);
      setFromDateView(e.value);
    }
  };

  const fetchData = async () => {
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
      // van_filter: formik.values.selectedVendors,
      zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (
      formik.values.selectedVendors &&
      formik.values.dateFrom &&
      formik.values.dateTo
    ) {
      data.van_filter = formik.values.selectedVendors;
      // data.len_filter = formik.values.selectedlocations;
      data.from_filter = formik.values.dateFrom;
      data.to_filter = formik.values.dateTo;
    }
    setLoading(true);
    setShowTable(false);
    try {
      getAllPurchaseOrder(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setPurchaseOrderData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setPurchaseOrderData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (
            !searchInputHideRef.current &&
            err?.response?.data?.data?.totalCount === 0
          ) {
            searchInputHideRef.current = true;
          }
          setPurchaseOrderData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setPurchaseOrderData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const fetchDeActivePurchaseOrderData = async () => {
    let data = {
      limit: paginationModelDeactive.pageSize,
      page: paginationModelDeactive.page + 1,
      order:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].field
          : sortRefDeactive.current[0].field,
      order_type:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].sort
          : sortRefDeactive.current[0].sort,
      filter: searchQuery,
    };
    setLoading(true);
    setShowTable(false);
    try {
      getDeactivePurchaseOrder(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setdeactivePurchaseOrderData(response.data.data.data);
            setDeactiveTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setdeactivePurchaseOrderData([]);
            setDeactiveTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (
            !deactiveSearchHideRef.current &&
            err?.response?.data?.data?.totalCount === 0
          ) {
            deactiveSearchHideRef.current = true;
          }
          setdeactivePurchaseOrderData([]);
          setDeactiveTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setdeactivePurchaseOrderData([]);
      setDeactiveTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const apply = () => {
    effectRun.current = true;
    setLoading(true);
    setButtonDisable(true);
  };

  const handleResetAllData = () => {
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setTableData([]);
    setShowTable(true);
    setTotalCount(0);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    effectRun.current = true;
    setLoading(true);
    formik.resetForm();
    setButtonDisable(false);
  };

  const handleTabChange = (index) => {
    setCurruntTab(index);
    if (index == 0) {
      deactiveSearchHideRef.current = false;
      setSearchQuery("");
      setSortModel([
        {
          field: "id",
          sort: "desc",
        },
      ]);
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
      effectRun.current = true;
      setLoading(true);
    } else {
      searchInputHideRef.current = false;
      setSearchQuery("");
      setPaginationModelDeactive({
        page: 0,
        pageSize: 25,
      });
      setSortModelDeactive([
        {
          field: "id",
          sort: "desc",
        },
      ]);
      effectRun.current = true;
      setLoading(true);
    }
  };


  const handleExportReport = (item) => {
    myContext.handleLoading(true);
    if (item?.id) {
      let data = {
        exportReport: true,
        id: item?.id,
      };
      submit(data, {
        method: Constants.POST,
        path: Constants.PURCHASING_ORDER_PATH,
      });
    }
  };

  useEffect(() => {
    // page change, rowperPage change,tab change ,serchdata call
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData();
      } else {
        fetchDeActivePurchaseOrderData();
      }
      effectRun.current = false;
    }
  }, [
    searchQuery,
    paginationModel,
    sortModel,
    loading,
    curruntTab,
    paginationModelDeactive,
    sortModelDeactive,
  ]);

  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const columnNames = [
    { field: "id", headerName: "Id", sortable: true, width: 90 },
    {
      field: "vendor_name",
      headerName: "Vendor",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "user_name",
      headerName: "User",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "location_name",
      headerName: "Location",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleEditPage = (item) => {
          myContext.handleLoading(true);
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
            filter: searchQuery,
          };
          // pass backtraking relate all data
          props.navigate(`${Constants.PURCHASING_ORDER_EDIT + item.id}`, {
            state: data,
          });
        };

        const handleDeactive = (item) => {
          setButtonDisables(true);
          if (modifiedData.length == 1 && paginationModel.page != 0) {
            paginationModel.page = paginationModel.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: item.id,
            type: Constants.DEACTIVE,
          };
          submit(data, {
            method: Constants.DELETE,
            path: Constants.PURCHASING_ORDER_PATH,
          });
        };
        const options = {
          buttonName: "Edit",
          buttonIcon: <CreateOutlinedIcon />,
          handleClick: (item) => handleEditPage(item),
          subButtons: [
            {
              name: "View Pdf",
              icon: (
                <PictureAsPdfIcon style={{ color: Colors.posViewTextColor }} />
              ),
              onClick: (item) => handlePdfReport(item),
            },
            {
              name: "View Excel",
              icon: <FaFileExcel style={{ color: Colors.posViewTextColor }} />,
              onClick: (item) => handleExportReport(item),
            },
            {
              name: "Deactive",
              disabledButton: buttonDisables,
              icon: (
                <CloseIcon style={{ color: Colors.posCancleButtonMuiRed }} />
              ),
              onClick: (item) => handleDeactive(item),
            },
          ],
        };
        return (
          <PosSpliteButton
            spliteOptions={options}
            item={rowData}
            id={rowData.id}
          />
        );
      },
      pinned: true
    },
  ];

  const deactiveColumnNames = [
    { field: "id", headerName: "Id", sortable: true, width: 90 },
    {
      field: "vendor_name",
      headerName: "Vendor",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "user_name",
      headerName: "User",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "location_name",
      headerName: "Location",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "reactive",
      headerName: "Reactive",
      disableExport: true,
      resizable: false,
      filterable: false,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleReactive = () => {
          setButtonDisables(true);
          if (
            modifiedDeactiveData.length == 1 &&
            paginationModelDeactive.page != 0
          ) {
            paginationModelDeactive.page = paginationModelDeactive.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: rowData.id,
            type: Constants.REACTIVE,
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.PURCHASING_ORDER_PATH,
          });
        };
        return (
          <Text
            onClick={handleReactive}
            cursor={buttonDisables ? "not-allowed" : "pointer"}
            color={buttonDisables ? Colors.posNavbarLink : Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            textDecoration={buttonDisables ? "none" : "underline"}
            fontWeight={600}
            style={{ pointerEvents: buttonDisables ? "none" : "auto" }} // Ensures that click is disabled
          >
            Activate
          </Text>
        );
      },
    },
  ];

  const modifiedData = purchaseOrderData.map((data) => ({
    id: data.id,
    vendor_name: data.vendors.name,
    user_name: data.users.name,
    location_name: data.locations.name,
    status:
      data.status === "GEN"
        ? "Generated"
        : data.status === "ORD"
        ? "Ordered"
        : data.status === "SHIP"
        ? "Shipped"
        : data.status === "RECE"
        ? "Received"
        : data.status,
  }));

  const modifiedDeactiveData = deactivePurchaseOrderData.map((data) => ({
    id: data.id,
    vendor_name: data.vendors.name,
    user_name: data.users.name,
    location_name: data.locations.name,
  }));

  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        mb={8}
      >
        <PosListHeader
          header={"Purchase Orders"}
          discription={"Manage and create purchase order on OctoPos"}
        />
        <Spacer />
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosIconButton
            name="Create"
            display={"flex"}
            width={"8.5rem"}
            height={"2.81rem"}
            padding={"0.63rem"}
            justify-content={"center"}
            align-items={"center"}
            gap={"0.31rem"}
            flex-shrink={"0"}
            onClick={() => {
              myContext.handleLoading(true);
              // backtracking
              let data = {
                limit: paginationModel.pageSize,
                page: paginationModel.page,
                order:
                  sortModel.length > 0
                    ? sortModel[0].field
                    : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0
                    ? sortModel[0].sort
                    : sortRef.current[0].sort,
                filter: curruntTab == 1 ? "" : searchQuery,
              };
              // pass all backtraking ralated all in create modifier set route
              props.navigate(Constants.PURCHASING_ORDER_CREATE, {
                state: data,
              });
            }}
          />
        </ButtonGroup>
      </Flex>
      <Divider h={"0.05rem"} borderColor={Colors.listShadow} />
      <Box
        mt={8}
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          {curruntTab == 0 && !searchInputHideRef.current ? (
            <>
              <Flex
                direction={{ base: "column", md: "row" }}
                alignItems={{ base: "stretch", md: "center" }}
                gap={{ base: 2, md: 0 }}
                mt={6}
                mb={3}
                ml={4}
                mr={4}
              >
                <PosSearch
                  value={searchQuery}
                  onChange={(e) => {
                    let value =
                      searchQuery.length > 0
                        ? e.target.value
                        : e.target.value.trim();
                    setSearchQuery(value);
                  }}
                  onSearch={handleSearchList}
                  handleClear={handleClear}
                />
                <Spacer />
              </Flex>

              {/* <Flex
                direction={{ base: "column", md: "row" }}
                justifyContent={"space-between"}
              > */}
                  <SimpleGrid
                    columns={{ base: 1, sm: 1, md: 1, lg: 4, xl: 4 }}
                    // minChildWidth='150px'
                    // spacing='20px'
                    ml={4}
                    mr={4}
                    spacing={{
                      base: "1rem",
                      sm: "1rem",
                      md: "1rem",
                      lg: "1rem",
                      xl: "1rem",
                    }}
                  >
                    <Box>
                    <PosDropDown
                      options={vendors}
                      value={formik.values.selectedVendors}
                      onChange={handleOptionSelect}
                      onBlur={(e) => {
                        formik.setFieldTouched("selectedVendors");
                        formik.handleBlur(e);
                      }}
                      multiSelect={true}
                      optionLabel="label"
                      placeholder="Select Vendors*"
                      resetFilterOnHide={true}
                      lableAvailable={true}
                      width={"100%"}
                      height={"2.5rem"}
                      className="w-full md:w-20rem"
                      display={"chip"}
                      maxSelectedLabels={2}
                    />
                    <Text color={Colors.errorColor}>
                      {formik.touched.selectedVendors &&
                      formik.errors.selectedVendors ? (
                        <span>{formik.errors.selectedVendors}</span>
                      ) : null}
                    </Text>
                    </Box>
                    {/* <Box>
                    <PosDropDown
                      options={locations}
                      value={formik.values.selectedlocations}
                      onChange={handlelocationOptionSelect}
                      onBlur={(e) => {
                        formik.setFieldTouched("selectedlocations");
                        formik.handleBlur(e);
                      }}
                      multiSelect={true}
                      optionLabel="label"
                      placeholder="Select locations*"
                      resetFilterOnHide={true}
                      lableAvailable={true}
                      width={"100%"}
                      height={"2.5rem"}
                      className="w-full md:w-20rem"
                      display={"chip"}
                      maxSelectedLabels={2}
                    />
                    <Text color={Colors.errorColor}>
                      {formik.touched.selectedlocations &&
                      formik.errors.selectedlocations ? (
                        <span>{formik.errors.selectedlocations}</span>
                      ) : null}
                    </Text>
                    </Box> */}
                    <Box>
                    <PosDatePicker
                        id={"dateFrom"}
                        ref={calendarRef}
                        value={formik.values.dateFrom}
                        showTime={true}
                        readOnlyInput={true}
                        label={"From Date*"}
                        onFocus={() => {
                          formik.setFieldTouched("dateFrom", true);
                        }}
                        width={"100%"}
                        handleSelectData={hadleFromDateSelect}
                        yearRange={"2000:2030"}
                        maxDate={dateTimeValue}
                        viewDate={fromDateView}
                        showButtonBar={true}
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.dateFrom && formik.errors.dateFrom ? (
                          <span>{formik.errors.dateFrom}</span>
                        ) : null}
                      </Text>
                    </Box>
                    <Box>
                    <PosDatePicker
                        id={"dateTo"}
                        ref={toDateCalendarRef}
                        showTime={true}
                        readOnlyInput={true}
                        onFocus={() => {
                          formik.setFieldTouched("dateTo", true);
                        }}
                        value={formik.values.dateTo}
                        label={"To Date*"}
                        handleSelectData={(e) => {
                          formik.setFieldValue("dateTo", e.value);
                          setToDateView(e.value);
                        }}
                        width={"100%"}
                        yearRange={"2000:2030"}
                        minDate={
                          null !== formik.values.dateFrom &&
                          "" !== formik.values.dateFrom
                            ? formik.values.dateFrom
                            : dateTimeValue
                        }
                        maxDate={dateTimeValue}
                        viewDate={toDateView}
                        showButtonBar={true}
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.dateTo && formik.errors.dateTo ? (
                          <span>{formik.errors.dateTo}</span>
                        ) : null}
                      </Text>
                    </Box>
                    <Box alignContent={"end"} mt={{ base: 2, md: 0 }}>
                    <ButtonGroup>
                      <PosFormButton
                        buttonsubmit={"Apply"}
                        SubmitButton={true}
                        isDisabled={
                          formik.values.selectedVendors?.length === 0 ||
                          formik.values.dateFrom === "" ||
                          formik.values.dateTo === "" ||
                          formik.errors.selectedVendors ||
                          formik.errors.dateFrom ||
                          formik.errors.dateTo
                            ? // ||
                              // buttonDisable
                              true
                            : false
                        }
                        onClick={() => {
                          apply();
                        }}
                      />
                      {buttonDisable && (
                        <PosFormButton
                        isDisabled={buttonDisable ? false : true}
                        onClick={handleResetAllData}
                        buttonText={"Clear"}
                        CancelButton={true}
                      />
                      )}
                    </ButtonGroup>
                    </Box>
                  </SimpleGrid>
                  <Box
                    style={{ flex: 1 }}
                    direction={"row"}
                    alignSelf={"flex-start"}
                    ml={{ base: "0", md: "1.56em" }}
                  >
                    
                  </Box>
              {/* </Flex> */}
            </>
          ) : curruntTab === 1 && !deactiveSearchHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : null}
          <TabPanels>
            <TabPanel>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : totalcount > 0 && curruntTab === 0 ? (
                <PosDataGridTable
                  columns={columnNames}
                  rows={modifiedData}
                  totalcount={totalcount}
                  columnVisibilityModel={olColumnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) =>
                    setOlColumnVisibilityModel(newModel)
                  }
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  paginationModel={paginationModel}
                  paginationMode="server"
                  sortingMode="server"
                  onPaginationModelChange={(newPaginationModel) => {
                    effectRun.current = true;
                    setPaginationModel(newPaginationModel);
                  }}
                  sortModel={sortModel}
                  onSortModelChange={(newSortModel) => {
                    sortRef.current = sortModel;
                    effectRun.current = true;
                    setSortModel(newSortModel);
                  }}
                  fileName={Constants.ACTIVE_PURCHASE_ORDER_EXPORT_FILENAME}
                />
              ) : (
                <PosNoDataFound title={Constants.PURCHASE_ORDER_NOT_FOUND} />
              )}
            </TabPanel>
            <TabPanel>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : totalDeactivatecount > 0 && curruntTab === 1 ? (
                <PosDataGridTable
                  columns={deactiveColumnNames}
                  rows={modifiedDeactiveData}
                  totalcount={totalDeactivatecount}
                  columnVisibilityModel={olColumnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) =>
                    setOlColumnVisibilityModel(newModel)
                  }
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  paginationModel={paginationModelDeactive}
                  paginationMode="server"
                  sortingMode="server"
                  onPaginationModelChange={(newPaginationModel) => {
                    effectRun.current = true;
                    setPaginationModel(newPaginationModel);
                  }}
                  sortModel={sortModelDeactive}
                  onSortModelChange={(newSortModelDeactive) => {
                    sortRefDeactive.current = sortModelDeactive;
                    effectRun.current = true;
                    setSortModelDeactive(newSortModelDeactive);
                  }}
                  // sortModel={sortModel}
                  // onSortModelChange={(newSortModelDeactive) => {
                  //   sortRefDeactive.current = sortModelDeactive;
                  //   effectRun.current = true;
                  //   setSortModel(newSortModelDeactive);
                  // }}
                  fileName={Constants.DEACTIVE_PURCHASE_ORDER_EXPORT_FILENAME}
                />
              ) : (
                <PosNoDataFound
                  title={Constants.DEACTIVE_PURCHASE_ORDER_NOT_FOUND}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
export default WithRouter(PurchaseOrderList);
