import axios from "axios";
import * as Constants from "../../constants/Constants";

export function userSignIn({ request, params }) {
  return new Promise(async (resolve, reject) => {
    axios.defaults.timeout = 60000;
    let formData = await request.formData();
    let payload = JSON.stringify({
      email: Object.fromEntries(formData).email,
      password: Object.fromEntries(formData).password,
      user: Object.fromEntries(formData).user,
    });
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.LOGIN_API_ROUTE,
        payload,
        {
          headers: {
            "Content-Type": Constants.CONTANT_TYPE,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getUserAllData({ request, params }) {
  return new Promise(async (resolve, reject) => {
    axios.defaults.timeout = 60000;
    let token = localStorage.getItem("token");
    if (
      undefined !== localStorage.getItem("currentUser") &&
      null !== localStorage.getItem("currentUser") &&
      "" !== localStorage.getItem("currentUser") &&
      undefined !== token &&
      null !== token
    ) {
      let user = JSON.parse(localStorage.getItem("currentUser"));
      axios
        .get(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            `${Constants.GET_IMAGE_API_ROUTE + "/" + user?.id}`,
          {
            headers: {
              "Content-Type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    } else {
      let errorData = {
        error: true,
        response: Constants.LOGOUT_PAGE,
      };
      resolve(errorData);
    }
  });
}

export function forgotPassword({ request, params }) {
  return new Promise(async (resolve, reject) => {
    axios.defaults.timeout = 60000;
    let formData = await request.formData();
    let email = formData.get("email");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.FORGOT_PASSWORD_API_ROUTE +
          `/${email}`,
        {},
        {
          headers: {
            "Content-Type": Constants.CONTANT_TYPE,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function resetPassword({ request, params }) {
  return new Promise(async (resolve, reject) => {
    axios.defaults.timeout = 60000;
    let formData = await request.formData();
    let payload = JSON.stringify({
      confirmPassword: Object.fromEntries(formData).confirmPassword,
      password: Object.fromEntries(formData).password,
      token: Object.fromEntries(formData).token,
    });
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.RESET_PASSWORD_API_ROUTE,
        payload,
        {
          headers: {
            "Content-Type": Constants.CONTANT_TYPE,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function masterAdminLoginIn({ request, params }) {
  return new Promise(async (resolve, reject) => {
    axios.defaults.timeout = 60000;
    let formData = await request.formData();
    let payload = JSON.stringify({
      email: Object.fromEntries(formData).email,
      password: Object.fromEntries(formData).password,
    });
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE,
        payload,
        {
          headers: {
            "Content-Type": Constants.CONTANT_TYPE,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getGiftCardData(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GIFT_CARD_PUBLIC_DATA,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCampaignPublicPageData(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.CAMPAIGN_PUBLIC_PAGE_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getUserToken({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let flag =
      undefined !== localStorage.getItem("token") &&
      null !== localStorage.getItem("token") &&
      "" !== localStorage.getItem("token");
      /* &&window.location.pathname === "/"; */
    let payload = {
      data: flag,
    };
    resolve(payload);
  });
}

export function getMasterAdminToken({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let flag =
      undefined !== localStorage.getItem("master-token") &&
      null !== localStorage.getItem("master-token") &&
      "" !== localStorage.getItem("master-token");
      /* &&window.location.pathname === "/"; */
    let payload = {
      data: flag,
    };
    resolve(payload);
  });
}

export function publicRewardCardCreateData(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PUBLIC_GET_REWARD_CARD_CREATE_PAGE_DATA_API,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function publicCreateRewardCardData({request,params}) {
  return new Promise(async (resolve, reject) => {
    let formData = await request.formData();
    let payload = JSON.stringify({
      api_key: Object.fromEntries(formData).api_key,
      email: Object.fromEntries(formData).email,
      phone: Object.fromEntries(formData).reward_card_number,
      first_name:Object.fromEntries(formData).first_name,
      birth_day:Object.fromEntries(formData).birth_day,
    });
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PUBLIC_REWARD_CARD_CREATE_PAGE_PATH_CREATE_API,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function publicRewardCardUpdateData(payload) {
  return new Promise(async (resolve, reject) => {
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PUBLIC_GET_REWARD_CARD_UPDATE_PAGE_DATA_API,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function publicUpdateRewardCardData({request,params}) {
  return new Promise(async (resolve, reject) => {
    let formData = await request.formData();
    let payload = {
      reward_card_id:Object.fromEntries(formData).reward_card_id,
      first_name: Object.fromEntries(formData).first_name,
      email: Object.fromEntries(formData).email,
      phone: Object.fromEntries(formData).phone,
    };
    payload.custom_fields = JSON.parse(
      Object.fromEntries(formData).custom_fields
    );;
    axios
      .put(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PUBLIC_REWARD_CARD_UPDATE_PAGE_PATH_UPDATE_API+`/${payload.reward_card_id}`,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

export function getSmsDataApi(payload) {
  return new Promise(async (resolve, reject) => {
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PUBLIC_SMS_RECEIPT_DATA_API,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function downloadDailyLowInventoryProducts(payload) {
  return new Promise(async (resolve, reject) => {
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PUBLIC_DOWNLOAD_DAILY_LOW_INVENTORY_PRODUCTS_API,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function downloadLowInventoryProducts(payload) {
  return new Promise(async (resolve, reject) => {
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PUBLIC_DOWNLOAD_LOW_INVENTORY_PRODUCTS_API,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function checkUnsubKey(payload) {
  return new Promise(async (resolve, reject) => {
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.CHECK_CAMPAIGN_UNSUB_KEY_API,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function publicUnsubEmail(email) {
  return new Promise(async (resolve, reject) => {
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PUBLIC_CAMPAIGN_UNSUB_API+`/${email}`,
        '',
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDountimeDetector() {
  return new Promise(async (resolve, reject) => {
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PUBLIC_DOUNTIME_DETECTOR_URL,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
