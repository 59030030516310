import axios from "axios";
import * as Constants from "../../../../../constants/Constants";

export function getPayOutList(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PAYOUTLIST_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.BILLING_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // let errorData = {
        //   error: true,
        //   response: error.response,
        // };
        // resolve(errorData);
        reject(error);
      });
  });
}

export function getDectivePayOutList(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PAYOUT_DEACTIVE_LIST_API_ROUTE,
        payload,

        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.BILLING_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // let errorData = {
        //   error: true,
        //   response: error.response,
        // };
        // resolve(errorData);
        reject(error);
      });
  });
}

// GETTTING ALL POS STATION

export function getDataAllpos() {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PAYOUT_GET_POSDATA_API_ROUTE,

        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.BILLING_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

// view Payout

export function showPayoutById({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PAYOUT_SHOW_ROUTE +
          `${id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.MODIFIER_SET_EDIT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // let errorData = {
        //   error: true,
        //   response: error.response,
        //   code: error.code,
        // };

        // resolve(errorData);
        reject(error);
      });
  });
}

export function createPayout({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    const payload = {
      // user_id: Object.fromEntries(formData).user_id,
      user_id: parseInt(Object.fromEntries(formData).user_id),

      user_id_dropdown: JSON.parse(
        Object.fromEntries(formData).user_id_dropdown
      ),
      pos_station_id: parseInt(Object.fromEntries(formData).pos_station_id),
      pos_station_id_posStation: JSON.parse(
        Object.fromEntries(formData).pos_station_id_posStation
      ),
      amount: parseFloat(Object.fromEntries(formData).amount).toFixed(2),
      reason: Object.fromEntries(formData).reason,
    };
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PAYOUT_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CUSTOMER_CREATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getPayoutById({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PAY_OUT_UPDATE_EDIT_ROUTE +
          `${id}` +
          Constants.PAY_OUT_EDIT_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.MODIFIER_SET_EDIT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function payoutEdit({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    const id = params.id;
    let formData = await request.formData();
    const payload = JSON.stringify({
      users: JSON.parse(Object.fromEntries(formData).users),
      station: JSON.parse(Object.fromEntries(formData).station),
      pos_station_id: parseInt(Object.fromEntries(formData).pos_station_id),
      id: parseInt(Object.fromEntries(formData).id),
      amount: Object.fromEntries(formData).amount,
      reason: Object.fromEntries(formData).reason,
      user_id: parseInt(Object.fromEntries(formData).user_id),
    });
    axios
      .put(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.PAY_OUT_UPDATE_EDIT_ROUTE + id}`,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PAYOUT_UPDATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function payoutDeactiveReactive({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).type == Constants.DEACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .delete(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.PAYOUT_DEACTIVE_PAYOUT_API_ROUTE +
            id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.MODIFIER_SET_DEACTIVE_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    } else if (Object.fromEntries(formData).type == Constants.REACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .get(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.PAYOUT_REACTIVE_PAYOUT_API_ROUTE +
            id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.MODIFIER_SET_REACTIVE_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    } else {
      let token = localStorage.getItem("token");
      let formData = await request.formData();
      if (Object.fromEntries(formData).getreport === "true") {
        const payload = JSON.stringify({
          filter: Object.fromEntries(formData).filter,
          location: JSON.parse(Object.fromEntries(formData).location),
          dateFrom: Object.fromEntries(formData).dateFrom,
          dateTo: Object.fromEntries(formData).dateTo,
          zone: Object.fromEntries(formData).zone,
          query: JSON.parse(Object.fromEntries(formData).query),
        });
        axios
          .post(
            Constants.REACT_APP_API_URL +
              process.env.REACT_APP_API_VERSION +
              Constants.PAYOUT_EDIT_ALL_LIST_ROUTE,
            payload,
            {
              headers: {
                "content-type": Constants.CONTANT_TYPE,
                Authorization: "Bearer" + token,
                Permission: Constants.TOTAL_CATEGORY_SALE_PERMISSION,
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            let errorData = {
              error: true,
              response: error.response,
              code: error.code,
            };
            resolve(errorData);
          });
      }
    }
  });
}

//export data

export function exportPayoutReportData(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");

    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PAY_OUT_EXPORT_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CUSTOMER_CREATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // let errorData = {
        //   error: true,
        //   response: error.response,
        //   code: error.code,
        // };
        // resolve(errorData);
        reject(error);
      });
  });
}

//  getting all location

export function getdatapos() {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PAYOUT_GETDATA_API_ROUTE,

        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.BILLING_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}
